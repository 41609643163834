.feature-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    padding-bottom: 24px;
    min-height: 100px;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
}

.feature-icon {
    float: left;
    width: 100%;
    color: #141f72;
    font-size: 50px;
}

.feature-text {
    line-height: 1.5rem;
    margin-bottom: 0 !important;
}
