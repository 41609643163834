$color_1: #666666;
$font_family_1: 'Frank Ruhl Libre', serif;

.align-custom-left {
	text-align: left;
}
.align-custom-right {
	text-align: right;
}
.align-custom-center {
	text-align: center;
}
.align-custom-wrap-left {
	padding-right: 4%;
	float: left;
}
.align-custom-wrap-right {
	padding-left: 4%;
	float: right;
}
.align-custom-full-width {
	width: 100%;
	object-fit: cover;
}
.align-custom-half-width-left {
	float: left;
	width: 46%;
	margin-right: 4%;
	object-fit: cover;
}
.align-custom-half-width-right {
	float: right;
	width: 46%;
	margin-left: 4%;
	object-fit: cover;
}
img.cms-picture-img {
	padding-top: 10px;
	padding-bottom: 20px;
	border-radius: unset !important;
}
.pb__0 {
	padding-bottom: 0 !important;
}
.pb__10 {
	padding-bottom: 10px !important;
}
figcaption.caption {
	font-size: 14px;
	font-style: italic;
	color: $color_1;
	padding-top: 4px;
	font-family: $font_family_1;
	padding-bottom: 20px;
}
@media (max-width: 500px) {
	.img-div-wrapper {
		width: 100% !important;
	}
	.img-figure-wrapper {
		width: 100% !important;
	}
	img.cms-picture-img {
		width: 100%;
		height: auto;
		float: unset;
		padding-left: 0;
		padding-right: 0;
	}
	figcaption.caption {
		padding-left: 0 !important;
		padding-bottom: 25px;
		width: 100% !important;
	}
	.align-custom-wrap-left {
		padding-right: 0;
	}
	.align-custom-wrap-right {
		padding-left: 0;
	}
}
