.inner-header {
    &.wform {
        .job-search-sec {
            padding-left: 15px;
            padding-right: 15px;
            @media(min-width: 992px) {
                padding-right: 30px;
                padding-left: 30px;
            }
        }
    }
}

.filters {
    &.filter-section-grey-background { // class added to aside flter wrapper under 981px width
        background: rgb(250,250,250);
        padding-top: 0px;
        padding-bottom: 50px;
        @media(min-width: 992px) {
            padding-right: 30px;
            padding-left: 30px;
        }
        margin-left: -15px;
        margin-right: -15px;
        width: 100vw;
    }
    form {
        label.category-title {
            pointer-events: none;
        }
        label.category-title::before{
            pointer-events: all;
        }

        .subcategories {
            background: #f4f5fa;
            width:  90%;
            display: none;

            p {
                font-size: 11px;
                background: #f4f5fa;
                margin-bottom: 0;

                label {
                    margin-left: 10px;
                }

                label:before {
                    background-color: white;
                }
            }

            p:first-child {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
            }

            p:last-child {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                padding-bottom: 5px;
            }
        }

        button.filter {
            background-color: #141f72;
            transition: all 0.4s ease 0s;
            float: left;
            width: 100%;
            height: 60px;
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0.3px;
            margin-top: 20px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px;
        }

        button.filter:hover {
            background: #FA5B05;
        }

        button.clean-filters {
            float: left;
            background: #ffffff;
            border: 2px solid #FA5B05;
            color: #202020;
            font-family: Open Sans;
            font-size: 15px;
            width: 100%;
            padding: 0;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px;
            margin-top: 10px;

            -webkit-transition: all 0.4s ease 0s;
            -moz-transition: all 0.4s ease 0s;
            -ms-transition: all 0.4s ease 0s;
            -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;

            a {
                display: block;
                padding: 11px;
            }
        }

        button.clean-filters:hover {
            background: #FA5B05;
            color: #ffffff;
        }

        @media(max-width: 991px) {
            button.clean-filters {
                background: rgb(250,250,250);
            }
        }
    }
}

button.applied-only {
    background: #8b91dd;

    border: 2px solid #8b91dd;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    color: #ffffff;
    float: left;
    width: 100%;
    text-align: center;
    padding: 0;
    height: 48px;
    font-size: 13px;
    font-family: Open Sans;
    font-weight: bold;
    margin-top: 30px;

    a {
        display: block;
        height: inherit;
        padding-top: 10px;
    }
}

button.applied-only:hover {
    background: transparent;
    color: #8b91dd;
    border-color: #8b91dd;
}

#id_ordering_chosen {
    .chosen-single {
        width: 155px;
    }

    .ordering-options {
        padding: 0 !important;

        li {
            padding: 0;

            a {
                background: inherit !important;
                display: block;
                padding: 10px 10px 10px 15px;
                color: #888888;
            }
        }

        li:hover {
            padding-left: 10px;
        }
    }
}

.filterbar span.emlthis {
    padding: 13px 30px;
}

.sorting-and-ads-list {
    @media(min-width: 992px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .jobs-list-title {
        display: none;
    }
    .job-list-modern {
        span.search-results {
            padding-left: 2px;
            display: inline-block;
            color: #232323;
            font-family: Quicksand;
            font-size: 20px;
        }
        a.clean-search-params {
            display: block;
            width: fit-content;
            padding: 3px 16px;
            margin-top: 5px;
            margin-bottom: 15px;
            font-size: 15px;
            background-color: transparent;
            border-radius: 8px;
            border: 2px solid #FA5B05;
            font-family: Open Sans;

            -webkit-transition: all 0.4s ease 0s;
            -moz-transition: all 0.4s ease 0s;
            -ms-transition: all 0.4s ease 0s;
            -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;
        }
        a.clean-search-params:hover {
            background: #FA5B05;
            color: white;
        }
    }
}





@media(max-width: 991px) {
    .sorting-and-ads-list {
        margin-top: 40px;
    }
    #id_ordering_chosen {
        margin-left: 0;
    }

    .button.clean-filters {
        background: #f4f5fa;
    }

    .jobs-list-title {
        display: block;
    }
}

// apply line breaks in pagination (after li.prev and before li.next) if
// width is less than 700 px
@media(max-width: 700px) {
    .line-break {
        display: block !important;
    }
    li.prev-max700px {
        display: inline-block !important;
        margin-top: 10px;
    }
    li.prev-min701px {
        display: none;
    }
    li.next {
        margin-top: 10px !important;
    }
}

// set width of ad count and soring dropdown to 100 %
@media(max-width: 550px ) {
    .sortby-sec {
        margin-left: 0;
        float: left;
        width: 100%;
    }

    .active-ads-count {
        text-align: center;

        span {
            width: 100%;
        }
    }

    #id_ordering_chosen {
        width: 100% !important;
        // padding-left: 5px;
        // padding-right: 25px;


        .chosen-single {
            width: 100% !important;
        }

        .chosen-drop {
            text-align: center;
            // float: unset;
        }
    }
}



