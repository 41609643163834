$color_1: white;

.job-listing {
	padding-left: 20px !important;
}
.closed-ad {
	opacity: 0.5;
}
.closed-message {
	font-weight: bold;
	color: $color_1;
	background: rgb(127, 193, 78);
	width: fit-content;
	padding: 0 10px 0 10px;
	margin: 10px 0 0 0;
	border-radius: 6px;
}
.ad-image {
	margin-bottom: 1.5rem;
	padding-top: 5px;
}
.closed-image {
	margin-bottom: 50%;
	padding-top: 5px;
}
.job-style-bx {
	min-width: 280px;
}
@media (max-width: 767px ) {
	.closed-message-center-on-small {
		margin-left: auto !important;
		margin-right: auto !important;
	}
	.job-style-bx {
		margin-top: -40px;
	}
	.ad-image {
		margin-bottom: 10px;
	}
	.closed-image {
		margin-bottom: 10px;
	}
	.job-style-bx.type-and-published {
		padding: 0;
		padding: 0;
	}
	h3.ad-title {
		margin: 0 0 4px 0;
	}
	.job-listing {
		.job-lctn {
			margin-top: 0;
			i {
				top: 1px;
			}
		}
		padding-left: 0 !important;
	}
}
