$color_1: white;
$color_2: gray;
$color_3: #141f72;
$color_4: #ffffff;
$color_5: #555555;
$background_color_1: #f1f1f1;
$background_color_2: transparent;
$background_color_3: #141f72;
$background_color_4: #e7e7e7;
$background_color_5: #eee;
$background_color_6: #ccc;
$border_color_1: #ffffff;
$border_color_2: #141f72;


.cookie-modal {
	label {
		color: $color_2;
	}
}
.toggle-check-input {
	width: 1px;
	height: 1px;
	position: absolute;
	visibility: hidden;
	&:checked {
		&~.toggle-check-text {
			&:before {
				content: 'On';
			}
			background: LightGray;
			padding-left: 6px;
			padding-right: 0;
			&:after {
				left: 100%;
				margin-left: -1.4em;
			}
		}
	}
}
.toggle-check-text {
	cursor: pointer;
	display: inline-block;
	position: relative;
	text-transform: uppercase;
	color: $color_1;
	padding: 5px;
	width: 55px;
	border-radius: 10px;
	background: #ccc;
	transition: background-color 0.15s;
	padding-left: 23px;
	padding-right: 0;
	&:after {
		content: ' ';
		display: block;
		background: white;
		width: 17px;
		height: 17px;
		border-radius: 100%;
		position: absolute;
		left: 2px;
		top: 3px;
		transition: left 0.15s, margin-left 0.15s;
	}
	&:before {
		content: 'Off';
	}
}
#privacy-banner {
	background-color: $background_color_1;
	font-weight: 400;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100000000000;
	padding: 20px 0;
	.btn {
		margin-top: 5px;
	}
	.banner-settings-button {
		float: right;
		color: $color_3;
		background-color: $background_color_2;
		border: 2px solid #141f72;
		padding: 10px 20px;
		border-radius: 8px;
		margin-left: 10px;
	}
	.banner-accept-button {
		float: right;
		color: $color_4;
		background-color: $background_color_3;
		border: 2px solid #141f72;
		padding: 10px 20px;
		border-radius: 8px;
		margin-right: 10px;
		&:hover {
			color: $color_4;
		}
	}
	.banner-buttons {
		margin: auto 0;
		padding-left: 0;
	}
	.close-banner {
		float: right;
		cursor: pointer;
	}
	.toggle-check {
		float: right;
		&::before {
			width: 30px !important;
			height: 30px !important;
		}
		&::after {
			width: 30px !important;
			height: 30px !important;
		}
	}
	.banner-text {
		padding-right: 0;
	}
}
#privacysettings {
	z-index: 10000000001;
	.alert-info {
		color: $color_5;
		background-color: $background_color_4;
		border-color: $border_color_1;
	}
	.modal-title {
		display: inline-block;
	}
	.category {
		width: 100%;
		padding-bottom: 20px;
		h5 {
			width: 100%;
			color: $color_2;
			display: block;
			position: relative;
			margin-bottom: 12px;
			font-size: 22px;
			font-weight: normal;
		}
	}
	.statistics-item {
		padding-top: 10px;
	}
	button.js-modal-accept {
		background-color: $background_color_3;
		color: $color_4;
		border-color: $border_color_2;
		&:hover {
			background-color: $background_color_3;
			border-color: $border_color_2;
		}
	}
	label {
		height: unset;
		margin-left: 20px;
		&::before {
			border: none;
		}
	}
	.customcheck {
		display: inline-block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		font-weight: normal;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-transition: all 0.4s ease 0s;
		-moz-transition: all 0.4s ease 0s;
		-ms-transition: all 0.4s ease 0s;
		-o-transition: all 0.4s ease 0s;
		transition: all 0.4s ease 0s;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			&:checked {
				&~.checkmark {
					background-color: $background_color_3;
					border-radius: 5px;
					&:after {
						display: block;
					}
				}
			}
		}
		&:hover {
			input {
				&~.checkmark {
					background-color: $background_color_6;
				}
			}
		}
		.checkmark {
			&:after {
				left: 9px;
				top: 4px;
				width: 7px;
				height: 14px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
	.checkmark {
		position: absolute;
		top: -8px;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: $background_color_5;
		border-radius: 5px;
		-webkit-transition: all 0.4s ease 0s;
		-moz-transition: all 0.4s ease 0s;
		-ms-transition: all 0.4s ease 0s;
		-o-transition: all 0.4s ease 0s;
		transition: all 0.4s ease 0s;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}
}
.no-items {
	padding-top: 10px;
}
@media (max-width: 1199px) {
	#privacy-banner {
		.banner-container {
			font-size: 13px;
		}
		.banner-settings-button {
			padding: 8px 16px;
		}
		.banner-accept-button {
			padding: 8px 16px;
		}
	}
}
@media (max-width: 991px) {
	#privacy-banner {
		.banner-settings-button {
			padding: 8px 16px;
		}
		.banner-accept-button {
			padding: 8px 16px;
		}
		.banner-text {
			display: block;
			width: 100%;
		}
		.text-break {
			display: none;
		}
	}
}
@media (max-width: 767px) {
	#privacy-banner {
		.banner-text {
			font-size: 12px;
			line-height: 1.4;
			padding-bottom: 20px;
			padding-right: 15px;
		}
		.banner-settings-button {
			float: left;
			margin-right: 10px;
		}
		.banner-accept-button {
			float: left;
			margin-left: 10px;
		}
	}
}
