$color_1: brown;
$link_color: #334562;

#allauth_account {
	min-height: 50vh;
	padding: 60px 0;
	@media(max-width: 400px) {
		min-height: 80vh;
	}
	.account-popup-area {
		.no-account-yet {
			font-size: 15px;
			margin-top: 15px !important;
			margin-bottom: 30px !important;
		}
		&.static {
			.account-popup {
				float: none;
				left: unset;
				margin: 0 auto;
				text-align: center;
				width: 100%;
				.loginBtn--facebook {
					width: 100%;
				}
				&.account {
					&__signup {
						@media(min-width: 460px) {
							width: 430px !important;
						}
					}
					&__signup_closed {
						h1 {
							margin-bottom: 30px;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__login {
						@media(min-width: 460px) {
							width: 430px !important;
						}
					}
					&__inactive {
						h1 {
							margin-bottom: 30px;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__email_verification_sent {
						h1 {
							margin-bottom: 30px;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__confirm_email {
						h1 {
							margin-bottom: 30px;
						}
						.verification-request-link {
							font-weight: bold;
							color: $link_color;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
						@media(min-width: 460px) {
							form {
								button {
									width: 430px !important;
									float: unset;
								}
							}
						}
					}
					&__verified_email_required {
						a {
							color: $link_color;
							font-weight: bold;
						}
						h1 {
							margin-bottom: 30px;
						}
						p {
							margin-bottom: 0;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__password_reset {
						max-width: 430px;
					}
					&__password_reset_done {
						h1 {
							margin-bottom: 30px;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__password_reset_from_key {
						h1 {
							margin-bottom: 30px;
						}
						form {
							padding-top: 30px;
							float: none;
							max-width: 430px;
							margin: 0 auto;
						}
					}
					&__password_rest_from_key_done {
						h1 {
							margin-bottom: 30px;
						}
					}
					&__social_login_cancelled {
						h1 {
							margin-bottom: 30px;
						}
						a {
							color: $link_color;
							font-weight: bold;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
					&__social_login_error {
						h1 {
							margin-bottom: 30px;
						}
						@media(min-width: 992px) {
							padding-top: 30px;
						}
					}
				}
			}
		}
	}
}
