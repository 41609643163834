$color_1: #FFF;
$background_color_1: #4C69BA;
$background_color_2: #5B7BD5;


.loginBtn {
	box-sizing: border-box;
	position: relative;
	float: none;
	padding: 0 15px 0 46px;
	border: none;
	text-align: center;
	line-height: 34px;
	white-space: nowrap;
	color: $color_1;
	height: 40px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 100%;
	}
	&:focus {
		outline: none;
	}
	&:active {
		box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
	}
}
.loginBtn-size-desktop {
	font-size: 20px;
	width: 340px;
}
.loginBtn-size-mobile {
	font-size: 16px;
	width: 300px;
}
.loginBtn--facebook {
	background-color: $background_color_1;
	background-image: linear-gradient(#4C69BA, #3B55A0);
	text-shadow: 0 -1px 0 #354C8C;
	&:before {
		border-right: #364e92 1px solid;
		background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 13px 8px no-repeat;
	}
	&:hover {
		background-color: $background_color_2;
		background-image: linear-gradient(#5B7BD5, #4864B1);
	}
	&:focus {
		background-color: $background_color_2;
		background-image: linear-gradient(#5B7BD5, #4864B1);
	}
}
.loginBtn--google {
	background: #DD4B39;
	&:before {
		border-right: #BB3F30 1px solid;
		background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
	}
	&:hover {
		background: #E74B37;
	}
	&:focus {
		background: #E74B37;
	}
}
.margin-top-big {
	margin-top: 40px;
}
