select {
  -webkit-appearance: none; }

input {
  -webkit-appearance: none; }

textarea {
  -webkit-appearance: none; }

.dropdown-field i {
  position: relative;
  right: 20px;
  bottom: 40px;
  font-size: 1.25rem;
  color: #8b91dd;
  float: right; }

.wiz-color {
  color: #FA5B05 !important; }

.mt-5px {
  margin-top: 5px; }

.no-hover {
  pointer-events: none; }

.transition-effect {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.border-shadow {
  -webkit-box-shadow: 0px 1px 20px #97909938;
  box-shadow: 0px 1px 20px #97909938; }

.inner-header {
  padding: 170px 0 150px 0; }
  @media (max-width: 575.98px) {
    .inner-header {
      padding: 70px 0; } }
  .inner-header.no-image {
    padding: 100px 0 0 0;
    padding-bottom: 0; }
    @media (max-width: 1250px) {
      .inner-header.no-image {
        padding-top: 0; } }
  @media (max-width: 575.98px) {
    .inner-header.wform {
      padding: 40px 0 0 0; } }
  .inner-header h3 {
    font-size: 2.5rem;
    margin-bottom: 0; }
    @media (max-width: 575.98px) {
      .inner-header h3 {
        font-size: 1.875rem; } }

nav .account-dropdown li a {
  overflow-wrap: break-word; }

.create-account-button {
  float: left;
  width: 100%;
  letter-spacing: 0.01875rem;
  margin-top: 20px;
  background-color: #141f7282;
  border-radius: 8px;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s; }
  .create-account-button:hover {
    background: #fa5b0578; }

.signin-popup-alternate {
  cursor: pointer; }

.account-msg {
  font-size: 0.625rem;
  margin-top: 10px; }

div.account-popup-area.wizard-popup-box > div {
  width: 600px;
  margin-left: -300px; }

div.account-popup-area.wizard-popup-box .ftchek label {
  font-size: 0.75rem; }
  div.account-popup-area.wizard-popup-box .ftchek label::before {
    margin-top: -1px; }

div.account-popup-area.wizard-popup-box input[type="number"] {
  border: medium none; }

.latest-ad-image {
  margin-bottom: 1.5rem;
  padding-top: 5px; }

.latest-closed-image {
  margin-bottom: 50%;
  padding-top: 5px; }

.wizardFieldWrapper {
  text-align: left;
  display: flow-root; }
  .wizardFieldWrapper > .wizard-form-label {
    font-size: 0.75rem;
    padding-left: 0px;
    color: grey;
    margin-bottom: 0px; }

.account-popup a.term-check-link {
  width: 100%;
  text-align: left;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: bold;
  color: #141f72; }
  .account-popup a.term-check-link:hover {
    color: #FA5B05; }

.account-popup-area.wizard-popup-box > div > form > div.row > div.col-4.pr-0 > div > div.cfield.mr-3 {
  margin-bottom: 10px; }

.account-popup-area.wizard-popup-box > div > form > div.row > div.col-8 > div > div.cfield {
  margin-bottom: 10px; }

.account-popup-area.wizard-popup-box > div > form > div:nth-child(7) > div.col-8 > button > a:hover {
  -webkit-text-decoration-line: unset;
          text-decoration-line: unset; }

.account-popup-area.wizard-popup-box form button {
  padding: 0px; }
  .account-popup-area.wizard-popup-box form button a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    color: white;
    text-decoration: none; }

.wizard-form-label::before {
  content: none; }

button.wizard-back-button {
  background: white !important;
  border: 3px solid #141f72;
  border-radius: 8px; }
  button.wizard-back-button a {
    color: #141f72 !important;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
    button.wizard-back-button a:hover {
      color: #FA5B05 !important; }
  button.wizard-back-button:hover {
    border-color: #FA5B05;
    border-radius: 8px; }

.chosen-container-active.chosen-with-drop .chosen-single {
  border: unset;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset; }

.chosen-container-single .chosen-search input[type="text"] {
  border: unset; }

.who-am > p {
  overflow-wrap: break-word; }

section:nth-child(6) > div > div > div > div:nth-child(1) > div.heading > p {
  padding-top: 10px;
  margin-top: 10px; }

#cms-top > div.cms-toolbar > div.cms-toolbar-left > form > label::before {
  content: none; }

#cms-top > div.cms-toolbar > div.cms-toolbar-left > form > label:nth-child(2) > input[type=text] {
  float: none; }

#cms-top > div.cms-toolbar > div.cms-toolbar-left > form > label:nth-child(3) > input[type=password] {
  float: none; }

#cms-top > div.cms-modal.cms-modal-markup.cms-modal-open > div.cms-modal-body > div.cms-modal-frame > div > div.cms-quicksearch > label::before {
  content: none; }

#content-placeholder > p {
  overflow-wrap: break-word; }

h2 {
  overflow-wrap: break-word; }

#id_mother_tongue_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_language_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_understanding_listening_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_understanding_listening_chosen > div > ul > li.active-result.highlighted {
  padding-left: 12px; }

#id_understanding_reading_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_understanding_reading_chosen > div > ul > li.active-result.highlighted {
  padding-left: 12px; }

#id_speaking_interaction_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_speaking_interaction_chosen > div > ul > li.active-result.highlighted {
  padding-left: 12px; }

#id_speaking_production_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_speaking_production_chosen > div > ul > li.active-result.highlighted {
  padding-left: 12px; }

#id_writing_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_writing_chosen > div > ul > li.active-result.highlighted {
  padding-left: 12px; }

#id_qualification_chosen > a {
  padding: 8px 45px 8px 15px; }

#id_qualification_chosen span {
  line-height: 2.25rem; }

.multicheckbox p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.75rem;
  margin-bottom: 0;
  width: 100%; }
  .multicheckbox p label {
    padding-left: 25px;
    margin-right: 0;
    margin-bottom: 0; }

.sectors-column {
  padding-right: 0; }

.countries_to_work-column {
  padding-right: 0; }

.checkbox-disabled {
  opacity: 50%; }

#id_description {
  background-color: #fff;
  font-size: 0.8125rem;
  color: #474747;
  padding-left: 15px; }

#profile-picture-current-wrapper {
  height: 150px;
  margin: 0 auto; }
  #profile-picture-current-wrapper img {
    width: 150px;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

#profile-picture-preview-wrapper {
  height: 150px;
  margin: 0 auto; }
  #profile-picture-preview-wrapper img {
    width: 150px;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }

.c-vitae-form.file-label {
  width: 100%;
  margin-right: 0; }

span.success-message {
  color: lightseagreen;
  font-size: 0.75rem; }

span.warning-message {
  color: brown;
  font-size: 0.75rem; }

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.file-label::before {
  content: none; }

form.email_list button[name="action_primary"] {
  margin-right: 15px; }

form.email_list > fieldset {
  width: 100%;
  margin-bottom: 20px; }

form.email_list button {
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  form.email_list button:hover {
    background: #FA5B05;
    color: #ffffff; }

form.email_list button[name="action_remove"] {
  border: 2px solid crimson;
  float: right !important; }
  form.email_list button[name="action_remove"]:hover {
    background: crimson;
    color: #ffffff; }

.email-management {
  padding: 30px 0 0 30px; }

form.add_email input {
  border-radius: 8px; }

form.add_email button {
  margin-bottom: 20px;
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  form.add_email button:hover {
    background: #FA5B05;
    color: #ffffff; }

form.add_email > label {
  padding: 0;
  font-size: 0.875rem;
  color: gray; }
  form.add_email > label::before {
    content: none; }

p.subtitle {
  margin-bottom: 20px; }

button.confirm-remove {
  float: left;
  width: 100%;
  height: 60px;
  font-size: 1.0625rem;
  font-weight: bold;
  letter-spacing: 0.01875rem;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #141f72;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  button.confirm-remove:hover {
    background: #FA5B05; }

.radio-label {
  margin: 2px 0px 0px 0px; }

.connections-management {
  padding: 30px 0 0 30px; }

form.connections button[name="action_remove"] {
  border: 2px solid crimson;
  float: left;
  background: #ffffff;
  border: 2px solid crimson;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  form.connections button[name="action_remove"]:hover {
    background: crimson;
    color: #ffffff; }

form.connections > fieldset {
  width: 100%;
  margin-bottom: 20px; }

a.socialaccount_provider {
  color: #666666;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  a.socialaccount_provider:hover {
    color: #FA5B05; }

.pasword-reset-popup-box p {
  font-size: small;
  line-height: 1.25rem; }

.pasword-reset-done-popup-box p {
  font-size: small;
  line-height: 1.25rem; }

form.password_reset label {
  float: left;
  padding-left: 0; }
  form.password_reset label::before {
    content: none; }

form.password_reset input {
  border-radius: 8px; }

form.password_reset_from_key label {
  float: left;
  padding-left: 0; }
  form.password_reset_from_key label::before {
    content: none; }

form.password_reset_from_key input {
  border-radius: 8px; }

.password-management {
  padding: 30px 0 0 30px; }
  .password-management form input:last-child {
    margin-bottom: 20px; }
  .password-management form button {
    margin-top: 30px; }

form.password_change label {
  float: left;
  padding-left: 0;
  font-size: 0.875rem;
  color: gray; }
  form.password_change label::before {
    content: none; }

form.password_change button {
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  form.password_change button:hover {
    background: #FA5B05;
    color: #ffffff; }

form.password_change input {
  border-radius: 8px; }

form.password_set label {
  float: left;
  padding-left: 0; }
  form.password_set label::before {
    content: none; }

form.password_set button {
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  form.password_set button:hover {
    background: #FA5B05;
    color: #ffffff; }

form.password_set input {
  border-radius: 8px; }

.messages-popup-box {
  z-index: 100; }
  .messages-popup-box .account-popup {
    top: 100px; }

div.p-category > a {
  min-height: 235px; }

.simple-text.faq {
  padding: 28px; }
  .simple-text.faq p {
    color: white; }

.open-register-popup {
  color: #626262;
  font-weight: bold; }
  .open-register-popup:hover {
    color: #FA5B05;
    cursor: pointer; }

.goto-register-popup {
  color: #626262;
  font-weight: bold; }
  .goto-register-popup:hover {
    color: #FA5B05;
    cursor: pointer; }

.term-check-field {
  margin: 0 0 15px 0;
  font-family: Open Sans;
  font-size: 0.8125rem;
  color: #202020;
  width: 100%;
  text-align: left; }
  .term-check-field input {
    -webkit-appearance: auto; }

section.old-user-password-reset span {
  text-align: left; }

p.gdpr-label {
  text-align: left;
  margin: 40px 0 10px; }

.la.la-mail-forward {
  font-weight: bold; }

.consent-description {
  text-align: left;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 10px;
  padding-top: 10px; }

label.subscribe-unsubscribe {
  font-size: 1rem; }
  label.subscribe-unsubscribe::before {
    top: 2px; }

.menu-sec nav > ul > li > a {
  padding: 14px 14px; }

.grecaptcha-badge {
  z-index: 10; }

#user_attachments > p {
  text-align: left;
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-top: 15px; }

#user_attachments #add_form {
  margin-top: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 20px 3px 20px;
  background-color: #7dc246;
  font-size: 1.5rem;
  display: block;
  line-height: 1;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  border: none; }

#user_attachments > #add_form::before {
  content: none; }

#user_attachments label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 0.875rem; }

#user_attachments table td {
  padding: 0 0 0 0; }
  #user_attachments table td:nth-child(2) {
    width: 100%;
    text-align: left;
    padding-right: 10px;
    word-break: break-word; }

#user_attachments #remove_form {
  display: none; }
  #user_attachments #remove_form a {
    font-size: 1.25rem;
    height: 18px; }

#user_attachments #remove_form.show-icon {
  display: block; }

#user_attachments .filelink {
  color: #5b79a8; }
  #user_attachments .filelink:hover {
    color: #334562; }

.user-data-management-form #user_attachments h3 {
  margin-bottom: 23px; }

.user-data-management-form #user_attachments label.delete_check::before {
  content: " ";
  border: 2px solid #e6e7ef;
  border-radius: 3px;
  left: -30px; }

.user-data-management-form #user_attachments label.delete_check::after {
  left: -30px; }

@media (max-width: 767px) {
  .latest-ad-image {
    margin-bottom: 10px; }
  .latest-closed-image {
    margin-bottom: 10px; } }

@media (max-width: 630px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 530px;
    margin-left: -265px; } }

@media (max-width: 535px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 460px;
    margin-left: -230px; } }

@media (max-width: 495px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 420px;
    margin-left: -210px; } }

@media (max-width: 395px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 380px;
    margin-left: -190px; } }

@media (max-width: 360px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 360px;
    margin-left: -180px; } }

@media (max-width: 330px) {
  div.account-popup-area.wizard-popup-box > div {
    width: 330px;
    margin-left: -165px; } }

@media (max-width: 991px) {
  .email-management {
    padding-left: 0 !important; } }

@media (max-width: 730px) {
  button.primary {
    width: 48%;
    margin-right: 4% !important; }
  button.send {
    width: 48%; } }

@media (max-width: 635px) {
  form.email_list button {
    margin-top: 10px;
    width: 100%; }
  form.email_list .form-group {
    width: 100%; }
  form.add_email button {
    margin-top: 10px;
    width: 100%; }
  p.email-wrap {
    margin-bottom: 10px !important; }
  span.email-address {
    display: block;
    margin-top: -3px; }
  span.verified {
    margin-left: 0 !important;
    margin-bottom: 10px !important; }
  span.unverified {
    margin-left: 0 !important;
    margin-bottom: 10px !important; } }

@media (max-width: 991px) {
  .connections-management {
    padding: 30px 0 0 0; }
  .password-management {
    padding: 30px 0 0 0; } }

@media (min-width: 768px) and (max-width: 981px) {
  .job-search form button {
    height: 61px; } }

@media (max-width: 490px) {
  select {
    text-indent: 25px;
    padding-left: 0;
    height: 56px; }
  select.location-search-mobile {
    text-indent: 25px !important; } }

@media (max-width: 766px) {
  section.testimonials .heading {
    margin-bottom: 0; } }

.errorlist {
  margin-bottom: 0px; }
  .errorlist > li {
    position: unset;
    font-size: 0.625rem;
    color: brown;
    margin-bottom: 0px;
    text-align: left; }

.popup-title {
  text-align: center;
  line-height: normal !important; }

.theme-layout {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  .theme-layout section {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .theme-layout footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }

@media (max-width: 400px) {
  input[type="color"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="text"],
  select,
  select:focus,
  select option,
  .chosen-container-single .chosen-single span,
  textarea {
    font-size: 1rem !important; } }

@media (max-width: 499px) {
  #parallax_teasers .row > div .who-am {
    margin: 50px 0 50px;
    padding: 0 15px; } }

@media (min-width: 1251px) and (max-width: 1440px) {
  #parallax_teasers .row > div .who-am {
    padding-left: 300px;
    padding-right: 50px; }
    #parallax_teasers .row > div .who-am.flip {
      padding-right: 300px;
      padding-left: 50px; } }

@media (max-width: 991.98px) {
  .job-listing.touched {
    border-left-color: #8b91dd;
    -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative; } }

@media (min-width: 992px) {
  .job-listing:hover {
    border-left-color: #8b91dd;
    -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative; } }

.post_widget {
  word-break: break-all; }

.account-popup-area .no-account-yet {
  font-size: 0.875rem;
  margin-top: 15px !important;
  margin-bottom: 30px !important; }

.loginBtn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  float: none;
  padding: 0 15px 0 46px;
  border: none;
  text-align: center;
  line-height: 2.125rem;
  white-space: nowrap;
  color: #FFF;
  height: 40px;
  border-radius: 8px; }
  .loginBtn:before {
    content: "";
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%; }
  .loginBtn:focus {
    outline: none; }
  .loginBtn:active {
    -webkit-box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1); }

.loginBtn-size-desktop {
  font-size: 1.25rem;
  width: 340px; }

.loginBtn-size-mobile {
  font-size: 1rem;
  width: 300px; }

.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#4C69BA), to(#3B55A0));
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C; }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png") 13px 8px no-repeat; }
  .loginBtn--facebook:hover {
    background-color: #5B7BD5;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5B7BD5), to(#4864B1));
    background-image: linear-gradient(#5B7BD5, #4864B1); }
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5B7BD5), to(#4864B1));
    background-image: linear-gradient(#5B7BD5, #4864B1); }

.loginBtn--google {
  background: #DD4B39; }
  .loginBtn--google:before {
    border-right: #BB3F30 1px solid;
    background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png") 6px 6px no-repeat; }
  .loginBtn--google:hover {
    background: #E74B37; }
  .loginBtn--google:focus {
    background: #E74B37; }

.margin-top-big {
  margin-top: 40px; }

@media (max-width: 575.98px) {
  .responsive-header .responsive-menubar {
    padding: 10px 0; } }

.responsive-header .responsive-opensec .responseve-account-and-language {
  border-top: 1px solid #38425f;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.responsive-header .responsive-opensec #id_responsivemenu_account_dropdown ul > li:not(:last-child) {
  margin: 10px 0; }

.responsive-header .responsive-opensec .responsivemenu.languages {
  margin: 0;
  text-align: right; }

.responsive-header .responsive-opensec .responsivemenu.menu {
  margin: 15px 0; }
  .responsive-header .responsive-opensec .responsivemenu.menu ul > li {
    margin-top: 0;
    margin-bottom: 15px;
    display: inline-block; }
    .responsive-header .responsive-opensec .responsivemenu.menu ul > li a {
      font-size: 1.125rem;
      width: auto;
      padding: 0;
      color: #97a1bc;
      -webkit-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
      vertical-align: middle; }
      @media (min-width: 992px) {
        .responsive-header .responsive-opensec .responsivemenu.menu ul > li a:hover {
          color: white; } }
      @media (max-width: 991.98px) {
        .responsive-header .responsive-opensec .responsivemenu.menu ul > li a.touched {
          color: white; } }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li a::before {
        content: none; }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li a::after {
        content: none; }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li a.sub {
        font-size: 1rem; }
    .responsive-header .responsive-opensec .responsivemenu.menu ul > li .cross-sign-trigger {
      width: 30px;
      height: 20px;
      padding: 5px 30px 0 50px;
      background: transparent;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li .cross-sign-trigger:hover {
        cursor: pointer; }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li .cross-sign-trigger .cross-line {
        position: absolute;
        background: #fff;
        -ms-flex-item-align: center;
            align-self: center; }
        .responsive-header .responsive-opensec .responsivemenu.menu ul > li .cross-sign-trigger .cross-line__h {
          width: 20px;
          height: 1px; }
        .responsive-header .responsive-opensec .responsivemenu.menu ul > li .cross-sign-trigger .cross-line__v {
          width: 1px;
          height: 20px; }
    .responsive-header .responsive-opensec .responsivemenu.menu ul > li > ul {
      margin: 12px 0; }
      .responsive-header .responsive-opensec .responsivemenu.menu ul > li > ul > li {
        margin-bottom: 12px; }
        .responsive-header .responsive-opensec .responsivemenu.menu ul > li > ul > li:last-child {
          margin-bottom: 0; }

header .menu-sec .menu-item ul li:first-child {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important; }

header .language-dropdown > li > ul {
  margin-left: 25% !important;
  width: 120px !important; }
  header .language-dropdown > li > ul > li:first-child {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important; }
  header .language-dropdown > li > ul::before {
    left: 23px !important; }

header .language-dropdown > li > a::before {
  content: none !important; }

ul.account-dropdown > li > ul {
  width: 190px !important; }
  ul.account-dropdown > li > ul::before {
    left: 23px !important; }

ul.account-dropdown > li > a::before {
  content: none !important; }

ul.responsive-account-dropdown > li > ul {
  width: 190px !important;
  z-index: 1;
  left: 23px !important; }

ul.responsive-account-dropdown > li > a::before {
  content: none !important; }

#id_responsivemenu_account_dropdown {
  margin: 0;
  text-align: right;
  padding-right: 35px; }

#id_bottom_border {
  border-bottom: 1px solid #38425f; }

#id_language_drop {
  color: white;
  padding-right: 15px; }

#id_account_drop {
  color: white;
  padding-right: 15px; }

#available_translations a {
  font-size: 0.875rem; }

#responsive_account_dropdown a {
  font-size: 0.875rem; }

.lang-drop-res-anonymous.menu-sec {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 90px;
          flex: 0 0 90px;
  padding: 0px;
  margin: auto 14px auto 0; }

.contact-butt-res-anonymous {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
          flex: 0 0 120px; }

.account-btns-mobile {
  padding-top: 0 !important;
  margin-left: -15px; }

@media (max-width: 991px) {
  .footer-title {
    margin-top: 20px !important;
    margin-bottom: 20px !important; } }

@media (max-width: 980px) {
  div.footer-links-titled {
    margin-top: 20px; } }

.job-listing {
  padding-left: 20px !important; }

.closed-ad {
  opacity: 0.5; }

.closed-message {
  font-weight: bold;
  color: white;
  background: #7fc14e;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px 0 10px;
  margin: 10px 0 0 0;
  border-radius: 6px; }

.ad-image {
  margin-bottom: 1.5rem;
  padding-top: 5px; }

.closed-image {
  margin-bottom: 50%;
  padding-top: 5px; }

.job-style-bx {
  min-width: 280px; }

@media (max-width: 767px) {
  .closed-message-center-on-small {
    margin-left: auto !important;
    margin-right: auto !important; }
  .job-style-bx {
    margin-top: -40px; }
  .ad-image {
    margin-bottom: 10px; }
  .closed-image {
    margin-bottom: 10px; }
  .job-style-bx.type-and-published {
    padding: 0;
    padding: 0; }
  h3.ad-title {
    margin: 0 0 4px 0; }
  .job-listing {
    padding-left: 0 !important; }
    .job-listing .job-lctn {
      margin-top: 0; }
      .job-listing .job-lctn i {
        top: 1px; } }

@media (min-width: 981px) and (max-width: 1250px) {
  div.container {
    max-width: 960px; } }

@media (max-width: 991px) {
  .sidebar {
    background: #fafafa;
    margin-bottom: -40px;
    padding-bottom: 40px;
    padding-top: 40px;
    margin-top: 30px; }
  div.block {
    padding-bottom: 40px; } }

.text--styling h1 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling h2 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling h3 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling h4 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling h5 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling h6 {
  margin-bottom: 1.5rem;
  line-height: 1.3; }

.text--styling p {
  margin-bottom: 1.5rem;
  font-size: 1rem !important;
  color: #666666; }

.text--styling ul {
  margin-bottom: 1.5rem;
  font-size: 1rem !important;
  color: #666666;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-left: 14px; }
  .text--styling ul li {
    max-width: 90%;
    position: relative;
    line-height: 1.8125rem;
    font-size: 1rem !important;
    margin-bottom: 0;
    color: #666666;
    padding-left: 25px; }
    .text--styling ul li::before {
      position: absolute;
      left: 0;
      top: 12px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #14A0E3;
      content: ""; }

.text--styling ol {
  margin-bottom: 1.5rem;
  font-size: 1rem !important;
  color: #666666;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-left: 29px; }
  .text--styling ol li {
    max-width: 90%;
    position: relative;
    line-height: 1.8125rem;
    font-size: 1rem !important;
    margin-bottom: 0;
    color: #666666; }

.text--styling a {
  color: #5b79a8;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s; }
  .text--styling a:hover {
    color: #334562;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s; }

.text--styling .f-l-big::first-letter {
  font-size: 3.6em;
  color: #8b91dd;
  font-weight: 800;
  float: left;
  line-height: 1;
  margin-right: 10px; }

.text--styling span.l-big {
  font-size: 3.6em;
  font-weight: 800;
  float: left;
  line-height: 1;
  margin-right: 10px; }

.text--styling .pill-marker {
  background-color: #D6EDFC;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .3em .5em;
  border-radius: 1.5em; }

.text--styling .pill-header {
  background-color: #D6EDFC;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: .3em .5em;
  border-radius: 1.5em; }

.text--styling .blue-box {
  font-family: 'Quicksand', serif !important;
  color: #fff;
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 1.3;
  padding: 1em 1.5em;
  border-radius: .3em;
  background-color: #78bff0; }

.text--styling .violet-box {
  font-family: "Quicksand", serif !important;
  color: #ffffff;
  font-size: 1.75rem !important;
  font-weight: 500;
  line-height: 1.3;
  padding: 1em 1.5em;
  border-radius: .3em;
  background-color: #8b91dd; }

.blog-post-list-outer-wrap {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  .blog-post-list-outer-wrap:hover {
    -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    z-index: 1; }

p.post-date {
  color: #888888; }

.blog-post-list-inner-wrap {
  padding: 30px; }

.image-container.small-image {
  max-height: 200px;
  width: 100%; }

.image-container img.small-image {
  height: 200px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

.image-container img.image-detail {
  max-height: 430px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top; }

h2.post-title {
  font-size: 2rem;
  margin: 0 0 20px 0; }

.image-container.image-detail {
  max-height: 100%;
  width: 100%; }

@media (max-width: 991px) {
  .blog-post-list-inner-wrap {
    padding: 0 0 30px 0; }
  .image-container img.image-detail {
    max-height: 350px; } }

@media (min-width: 576px) and (max-width: 768px) {
  .image-container.small-image {
    max-height: 300px;
    width: 100%; }
  .image-container img.small-image {
    height: 300px;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover; } }

@media (max-width: 750px) {
  .image-container img.image-detail {
    max-height: 300px; } }

@media (max-width: 650px) {
  .image-container img.image-detail {
    max-height: 250px; } }

.inner-header.wform .job-search-sec {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    .inner-header.wform .job-search-sec {
      padding-right: 30px;
      padding-left: 30px; } }

.filters.filter-section-grey-background {
  background: #fafafa;
  padding-top: 0px;
  padding-bottom: 50px;
  margin-left: -15px;
  margin-right: -15px;
  width: 100vw; }
  @media (min-width: 992px) {
    .filters.filter-section-grey-background {
      padding-right: 30px;
      padding-left: 30px; } }

.filters form label.category-title {
  pointer-events: none; }

.filters form label.category-title::before {
  pointer-events: all; }

.filters form .subcategories {
  background: #f4f5fa;
  width: 90%;
  display: none; }
  .filters form .subcategories p {
    font-size: 0.6875rem;
    background: #f4f5fa;
    margin-bottom: 0; }
    .filters form .subcategories p label {
      margin-left: 10px; }
    .filters form .subcategories p label:before {
      background-color: white; }
  .filters form .subcategories p:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px; }
  .filters form .subcategories p:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-bottom: 5px; }

.filters form button.filter {
  background-color: #141f72;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  float: left;
  width: 100%;
  height: 60px;
  font-size: 1.0625rem;
  font-weight: bold;
  letter-spacing: 0.01875rem;
  margin-top: 20px;
  border-radius: 8px; }

.filters form button.filter:hover {
  background: #FA5B05; }

.filters form button.clean-filters {
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  width: 100%;
  padding: 0;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  .filters form button.clean-filters a {
    display: block;
    padding: 11px; }

.filters form button.clean-filters:hover {
  background: #FA5B05;
  color: #ffffff; }

@media (max-width: 991px) {
  .filters form button.clean-filters {
    background: #fafafa; } }

button.applied-only {
  background: #8b91dd;
  border: 2px solid #8b91dd;
  border-radius: 8px;
  color: #ffffff;
  float: left;
  width: 100%;
  text-align: center;
  padding: 0;
  height: 48px;
  font-size: 0.8125rem;
  font-family: Open Sans;
  font-weight: bold;
  margin-top: 30px; }
  button.applied-only a {
    display: block;
    height: inherit;
    padding-top: 10px; }

button.applied-only:hover {
  background: transparent;
  color: #8b91dd;
  border-color: #8b91dd; }

#id_ordering_chosen .chosen-single {
  width: 155px; }

#id_ordering_chosen .ordering-options {
  padding: 0 !important; }
  #id_ordering_chosen .ordering-options li {
    padding: 0; }
    #id_ordering_chosen .ordering-options li a {
      background: inherit !important;
      display: block;
      padding: 10px 10px 10px 15px;
      color: #888888; }
  #id_ordering_chosen .ordering-options li:hover {
    padding-left: 10px; }

.filterbar span.emlthis {
  padding: 13px 30px; }

@media (min-width: 992px) {
  .sorting-and-ads-list {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

.sorting-and-ads-list .jobs-list-title {
  display: none; }

.sorting-and-ads-list .job-list-modern span.search-results {
  padding-left: 2px;
  display: inline-block;
  color: #232323;
  font-family: Quicksand;
  font-size: 1.25rem; }

.sorting-and-ads-list .job-list-modern a.clean-search-params {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 0.9375rem;
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid #FA5B05;
  font-family: Open Sans;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.sorting-and-ads-list .job-list-modern a.clean-search-params:hover {
  background: #FA5B05;
  color: white; }

@media (max-width: 991px) {
  .sorting-and-ads-list {
    margin-top: 40px; }
  #id_ordering_chosen {
    margin-left: 0; }
  .button.clean-filters {
    background: #f4f5fa; }
  .jobs-list-title {
    display: block; } }

@media (max-width: 700px) {
  .line-break {
    display: block !important; }
  li.prev-max700px {
    display: inline-block !important;
    margin-top: 10px; }
  li.prev-min701px {
    display: none; }
  li.next {
    margin-top: 10px !important; } }

@media (max-width: 550px) {
  .sortby-sec {
    margin-left: 0;
    float: left;
    width: 100%; }
  .active-ads-count {
    text-align: center; }
    .active-ads-count span {
      width: 100%; }
  #id_ordering_chosen {
    width: 100% !important; }
    #id_ordering_chosen .chosen-single {
      width: 100% !important; }
    #id_ordering_chosen .chosen-drop {
      text-align: center; } }

.apply-thisjob-closed {
  background: #7fc14e;
  border: 2px solid #7fc14e;
  border-radius: 50px;
  color: #ffffff;
  float: left;
  width: 100%;
  text-align: center;
  padding: 20px 20px;
  font-size: 0.9375rem;
  font-family: Open Sans;
  font-weight: bold;
  pointer-events: none;
  opacity: 0.6; }

.apply-thisjob-closed:hover {
  background: transparent;
  border-color: #7fc14e;
  color: #7fc14e; }

.recent-jobs .recent-jobs-title {
  width: 100%;
  font-size: 1.5rem;
  color: #202020;
  font-weight: bold;
  padding: 40px 0 20px 0;
  border-top: 1px solid #e8ecec; }

.ad-edit-link {
  vertical-align: middle; }
  .ad-edit-link i {
    font-size: 2.1875rem;
    line-height: 0.625rem; }

.job-single-head3 .job-thumb {
  padding-right: 25px; }
  .job-single-head3 .job-thumb img {
    min-width: 182px;
    min-height: 95px; }

.job-single-head3 .job-single-info3 {
  padding-left: 0; }

.stick-to-footer {
  margin-bottom: -40px !important;
  padding-bottom: 50px !important; }

@media (max-width: 991px) {
  .job-head-wide {
    border-bottom: none !important; }
  .job-overview-mobile {
    display: block !important; }
    .job-overview-mobile h3.job-overview-title,
    .job-overview-mobile h3.agent-overview-title {
      width: 100%;
      font-size: 1.5rem;
      color: #202020;
      font-weight: bold;
      padding: 40px 0 20px 0;
      border-top: 1px solid #e8ecec; }
    .job-overview-mobile .job-overview ul {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      border: none;
      margin-bottom: 0 !important; }
      .job-overview-mobile .job-overview ul li {
        width: 33.33% !important; }
  .job-overview-desktop {
    display: none; } }

@media (max-width: 980px) {
  .recent-jobs {
    padding-top: 40px; }
    .recent-jobs .recent-jobs-title {
      border-top: none;
      padding: 0 0 20px 0;
      margin-bottom: 0; }
  .job-overview-mobile .job-overview {
    margin-top: 35px;
    background: #fafafa;
    width: 100vw;
    margin-left: -15px;
    padding: 0 30px 20px 30px; }
    .job-overview-mobile .job-overview h3.job-overview-title {
      border-top: none;
      padding: 30px 0;
      margin-bottom: 0;
      margin-left: -15px; }
    .job-overview-mobile .job-overview ul {
      padding: 0 !important;
      margin-left: -38px; }
      .job-overview-mobile .job-overview ul li {
        min-height: 60px;
        margin-top: 0; }
  .job-overview-mobile .agent-overview {
    background: #fafafa;
    width: 100vw;
    margin-left: -15px;
    padding: 0 15px 20px 15px; }
    .job-overview-mobile .agent-overview .row.agent-data {
      border-color: #fafafa;
      border-radius: 0; }
    .job-overview-mobile .agent-overview .agent-image img {
      border-radius: 0; }
    .job-overview-mobile .agent-overview h3.agent-overview-title {
      border-top: none;
      padding: 0 0 30px 0;
      margin-bottom: 0; } }

@media (max-width: 700px) {
  .job-overview-mobile .job-overview li {
    min-width: 50%; } }

@media (max-width: 520px) {
  .job-overview-mobile .job-overview li {
    margin: 10px 0 !important; } }

.agent-overview {
  float: left;
  width: 100%;
  margin: 0; }
  .agent-overview h3 {
    float: left;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 15px; }
  .agent-overview .row.agent-data {
    height: 200px;
    margin: 0;
    border: 2px solid #e8ecec;
    border-radius: 8px; }

.agent-image {
  height: 100%;
  padding-left: 0; }
  .agent-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 6px 0 0 6px;
    border-radius: 6px 0 0 6px; }

.agent-info {
  height: 100%;
  padding: 20px 0; }
  .agent-info .ico {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: 30px;
    margin-right: 2px; }

.agent-info.no-picture {
  height: 100%;
  padding: 20px 0 20px 40px; }

.agent-name {
  font-size: 1.25rem; }

.agent-contact {
  color: #666666;
  font-size: 0.9375rem;
  margin: 0 0 5px 0; }
  .agent-contact a {
    color: #1155cc; }

@media (min-width: 992px) and (max-width: 1199px) {
  .agent-contact {
    font-size: 0.6875rem; } }

@media (max-width: 980px) {
  .agent-info.no-picture {
    padding-left: 0; } }

@media (max-width: 370px) {
  .agent-contact {
    font-size: 0.75rem; } }

@media (max-width: 980px) {
  .category-list > h2 {
    margin-top: 60px; } }

@media (max-width: 991.98px) {
  .category-list .p-category.touched {
    background: #fff;
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 104%;
    margin-left: -2%;
    height: 102%;
    z-index: 10; }
    .category-list .p-category.touched a {
      border-color: #fff; }
    .category-list .p-category.touched i {
      color: #FA5B05; } }

@media (min-width: 992px) {
  .category-list .p-category:hover {
    background: #fff;
    -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 104%;
    margin-left: -2%;
    height: 102%;
    z-index: 10; }
    .category-list .p-category:hover a {
      border-color: #fff; }
    .category-list .p-category:hover i {
      color: #FA5B05; } }

.contact-textinfo > ul > li > span p {
  display: table-cell;
  vertical-align: middle;
  font-family: Open Sans;
  font-size: 0.8125rem;
  color: #888888;
  line-height: 1.5rem; }

.inner-title2 {
  padding: 40px 0; }
  .inner-title2 p {
    display: table-cell;
    vertical-align: middle;
    font-family: Open Sans;
    font-size: 0.8125rem;
    color: #888888;
    line-height: 1.5rem; }

.contact-form .errorlist li {
  width: 100%; }

.pf-title {
  margin-bottom: 5px; }

.pf-field textarea {
  color: #101010;
  padding: 14px 45px 14px 15px; }

.sc_facebook {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important; }

.sc_linkedin {
  background-color: #0e76a8 !important;
  border-color: #0e76a8 !important; }

.sc_twitter {
  background-color: #00acee !important;
  border-color: #00acee !important; }

.sc_youtube {
  background-color: #c4302b !important;
  border-color: #c4302b !important; }

.sc_instagram {
  background-color: #027ba5 !important;
  border-color: #027ba5 !important; }

#social_icons {
  list-style: none;
  font-size: 0; }
  #social_icons li {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: inline-block;
    padding: 5px 5px 5px 0;
    margin-right: 10px; }
    #social_icons li a {
      font-size: 1rem;
      height: 35px;
      width: 35px;
      line-height: 2.25rem;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      border-radius: 5px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
    #social_icons li:last-child a {
      margin-right: 0px; }
  #social_icons [class*="sc_"] {
    color: #fff !important; }
    #social_icons [class*="sc_"]:hover {
      opacity: 0.8; }

@media (max-width: 520px) {
  .inner-title2 p {
    display: block;
    margin-bottom: 0;
    text-align: center; } }

.reviews {
  height: 307px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  .reviews:hover {
    background-color: rgba(255, 255, 255, 0.9); }
  .reviews .tmonial-text {
    height: 96px;
    overflow-y: hidden; }
  .reviews .tmonial-read-more {
    color: #141f72;
    font-family: "Quicksand", serif !important;
    font-size: 0.9375rem;
    font-weight: bold; }

.testimonial-popup .reviews {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 0;
  cursor: unset; }
  .testimonial-popup .reviews .tmonial-text {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }

h3.tmonial-name {
  margin-bottom: 5px; }

p.tmonial-job {
  font-family: Open Sans;
  font-size: 0.9375rem;
  color: #888888;
  font-weight: bold; }

.account-popup.testimonial-popup {
  padding: 10px; }

#profile__section [data-target*="datetimepicker"] {
  display: none; }

#profile__section .pf-field, #profile__section .pf-field .input-group {
  margin-bottom: 0; }
  #profile__section .pf-field i, #profile__section .pf-field .input-group i {
    z-index: 3; }

#profile__section .pf-title {
  font-size: 0.875rem;
  color: gray; }
  @media (max-width: 575.98px) {
    #profile__section .pf-title {
      margin-top: 10px; } }

#profile__section .chosen-container {
  margin-bottom: 20px; }

#profile__section .dropdown-field {
  margin-bottom: -5px; }

#profile__section select {
  color: #474747; }

#profile__section textarea {
  color: #474747; }
  @media (min-width: 500px) {
    #profile__section textarea {
      height: 150px; } }
  @media (min-width: 768px) {
    #profile__section textarea {
      height: 100px; } }

#profile__section form:not(.password_change):not(.add_email) input {
  color: #474747;
  margin-bottom: 0; }
  @media (max-width: 575.98px) {
    #profile__section form:not(.password_change):not(.add_email) input {
      margin-bottom: 15px; } }

#profile__section .chosen-single span {
  color: #474747; }

#profile__section .profile-title > h3 {
  font-size: 1.875rem; }

#profile__section .profile-menu-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  width: 100%;
  padding: 20px 0 20px 30px;
  border-bottom: 1px solid #edeff7; }
  #profile__section .profile-menu-header * {
    -ms-flex-item-align: center;
        align-self: center;
    margin-right: 20px; }
  #profile__section .profile-menu-header img {
    height: 100%;
    border-radius: 50%; }
  #profile__section .profile-menu-header > h3 {
    font-family: 'Open Sans' !important;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
    line-height: 1;
    font-size: 1.25rem; }
  #profile__section .profile-menu-header .profile-menu-button {
    padding: 0; }
    #profile__section .profile-menu-header .profile-menu-button .animated-icon {
      width: 30px;
      height: 20px;
      position: relative;
      margin: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon span {
        display: block;
        position: absolute;
        background: #5d5d5d;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        -webkit-transform-origin: left center;
                transform-origin: left center; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
                transform-origin: left center; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon span:nth-child(2) {
        top: 10px;
        -webkit-transform-origin: left center;
                transform-origin: left center; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon span:nth-child(3) {
        top: 20px;
        -webkit-transform-origin: left center;
                transform-origin: left center; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        top: 0px;
        left: 8px; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon.open span:nth-child(2) {
        width: 0%;
        opacity: 0; }
      #profile__section .profile-menu-header .profile-menu-button .animated-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        top: 21px;
        left: 8px; }
  @media (max-width: 991.98px) {
    #profile__section .profile-menu-header {
      padding-left: 0; }
      #profile__section .profile-menu-header__desktop {
        display: none; } }
  @media (min-width: 992px) {
    #profile__section .profile-menu-header__mobile {
      display: none; } }

#profile__section .button-disabled {
  border: 2px solid #fa5b057d !important;
  color: #2020204f !important;
  pointer-events: none; }

#profile__section .button-disabled:hover {
  background: #ffffff !important; }

#profile__section .delete-button-disabled {
  border: 2px solid #e1143d73 !important;
  color: #2020204f !important;
  pointer-events: none; }

#profile__section .delete-button-disabled:hover {
  background: #ffffff !important; }

#profile__section button.add-first-language {
  float: left;
  background: #ffffff;
  border: 2px solid #FA5B05;
  color: #202020;
  font-family: Open Sans;
  font-size: 0.9375rem;
  padding: 11px 40px;
  border-radius: 8px;
  margin-top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

#profile__section button.add-first-language:hover {
  background: #FA5B05;
  color: #ffffff; }

#profile__section .no-languages {
  padding-left: 30px;
  margin-top: 30px; }

#profile__section h3 {
  margin-top: 50px; }

#profile__section form.user-data-management-form label:not(.radio-choice) {
  padding-left: unset; }
  #profile__section form.user-data-management-form label:not(.radio-choice)::before {
    content: unset; }

#profile__section form.user-data-management-form .chosen-single div::before {
  color: #8b91dd; }

#profile__section form.user-data-management-form .field-errors {
  padding: 0 15px; }
  @media (min-width: 768px) {
    #profile__section form.user-data-management-form .field-errors {
      min-height: 15px;
      max-height: 15px; } }

#profile__section form.user-data-management-form .green-border {
  border: 2px solid #28a745; }

#profile__section form.user-data-management-form .green-border:hover {
  background: #28a745; }

#profile__section form.user-data-management-form button.delete {
  border: 2px solid crimson; }

#profile__section form.user-data-management-form button.delete:hover {
  background: crimson; }

#profile__section form.user-data-management-form .personal-data .pf-title {
  margin-bottom: 0; }

#profile__section form.user-data-management-form .personal-data .radio-choices {
  width: 90%; }
  @media (max-width: 575.98px) {
    #profile__section form.user-data-management-form .personal-data .radio-choices .pf-title {
      margin-bottom: 15px; } }
  #profile__section form.user-data-management-form .personal-data .radio-choices ul {
    margin-bottom: 0; }
    #profile__section form.user-data-management-form .personal-data .radio-choices ul li:nth-child(1) {
      margin-right: 20%; }
  @media (max-width: 575.98px) {
    #profile__section form.user-data-management-form .personal-data .radio-choices {
      margin-bottom: 10px; } }

#profile__section form.user-data-management-form .personal-data button {
  float: left; }

#profile__section form.user-data-management-form .personal-data .birth-date input[name="birth_date"] {
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e8ecec;
  padding: 14px 45px 14px 15px;
  line-height: 1.5rem; }

#profile__section form.user-data-management-form .personal-data .postal-code input[name="postal_code"] {
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e8ecec; }

#profile__section form.user-data-management-form .qualification button {
  float: left; }

#profile__section form.user-data-management-form .qualification .pf-title {
  margin-bottom: 0; }

#profile__section form.user-data-management-form .qualification .simple-checkbox p label {
  padding-left: 25px; }

#profile__section form.user-data-management-form .qualification .simple-checkbox p label::before {
  content: " ";
  border: 2px solid #e6e7ef;
  border-radius: 3px; }

#profile__section form.user-data-management-form .qualification .simple-checkbox p input[type="checkbox"]:checked + label::before {
  background: #2c7dfa;
  border-color: #2c7dfa; }

#profile__section form.user-data-management-form .c-vitae:last-child {
  margin-bottom: 40px; }

#profile__section form.user-data-management-form .c-vitae label.file-label {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #636ab9;
  border: none; }

#profile__section form.user-data-management-form .c-vitae label.file-label:hover {
  background-color: #444aa0; }

@media (max-width: 575.98px) {
  #profile__section form.user-data-management-form .c-vitae .file-upload span {
    display: block; } }

#profile__section form.user-data-management-form .c-vitae button {
  float: left; }

#profile__section form.user-data-management-form .c-vitae .filelink, #profile__section form.user-data-management-form .c-vitae [class*="filelink-"] {
  color: #5b79a8; }
  #profile__section form.user-data-management-form .c-vitae .filelink:hover, #profile__section form.user-data-management-form .c-vitae [class*="filelink-"]:hover {
    color: #334562; }

#profile__section form.user-data-management-form .c-vitae #profile-picture-current-wrapper,
#profile__section form.user-data-management-form .c-vitae #profile-picture-preview-wrapper {
  margin: 0; }

#profile__section form.user-data-management-form .c-vitae .clear-selection > i {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
  border: 1px solid #80808057;
  padding: 0 5px 2px 5px;
  border-radius: 10px;
  color: #404040;
  cursor: pointer; }

@media (max-width: 560px) {
  #profile__section form.user-data-management-form .work-experience button.previous, #profile__section form.user-data-management-form .work-experience button.toggle-form, #profile__section form.user-data-management-form .work-experience button.update {
    width: 47%;
    float: left; }
  #profile__section form.user-data-management-form .work-experience button.next, #profile__section form.user-data-management-form .work-experience button.add-experience, #profile__section form.user-data-management-form .work-experience button.delete-work-experience {
    width: 47%;
    float: right; } }

@media (max-width: 450px) {
  #profile__section form.user-data-management-form .work-experience button.toggle-form, #profile__section form.user-data-management-form .work-experience button.add-experience {
    width: 100%; } }

#profile__section form.user-data-management-form .work-experience input[name="from_date"], #profile__section form.user-data-management-form .work-experience input[name="to_date"] {
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #e8ecec;
  padding: 14px 45px 14px 15px;
  line-height: 1.5rem; }

#profile__section form.user-data-management-form .work-experience input {
  margin-bottom: 0px; }
  @media (max-width: 575.98px) {
    #profile__section form.user-data-management-form .work-experience input {
      margin-bottom: 15px; } }

#profile__section form.user-data-management-form .work-experience button {
  float: left; }

#profile__section form.user-data-management-form .work-experience .chosen-single {
  background-color: unset; }

#profile__section form.user-data-management-form .work-experience .pf-title {
  margin-bottom: 0; }

@media (max-width: 560px) {
  #profile__section form.user-data-management-form .language button.previous, #profile__section form.user-data-management-form .language button.toggle-form, #profile__section form.user-data-management-form .language button.update, #profile__section form.user-data-management-form .mother-tongue button.previous, #profile__section form.user-data-management-form .mother-tongue button.toggle-form, #profile__section form.user-data-management-form .mother-tongue button.update {
    width: 47%;
    float: left; }
  #profile__section form.user-data-management-form .language button.next, #profile__section form.user-data-management-form .language button.add-language, #profile__section form.user-data-management-form .language button.delete-language, #profile__section form.user-data-management-form .mother-tongue button.next, #profile__section form.user-data-management-form .mother-tongue button.add-language, #profile__section form.user-data-management-form .mother-tongue button.delete-language {
    width: 47%;
    float: right !important; } }

@media (max-width: 450px) {
  #profile__section form.user-data-management-form .language button.toggle-form, #profile__section form.user-data-management-form .language button.add-language, #profile__section form.user-data-management-form .mother-tongue button.toggle-form, #profile__section form.user-data-management-form .mother-tongue button.add-language {
    width: 100%; } }

#profile__section form.user-data-management-form .language p.language-name, #profile__section form.user-data-management-form .mother-tongue p.language-name {
  margin-bottom: 0;
  padding-left: 15px; }

#profile__section form.user-data-management-form .language button, #profile__section form.user-data-management-form .mother-tongue button {
  float: left; }

#profile__section form.user-data-management-form .language div a.chosen-single, #profile__section form.user-data-management-form .language .chosen-with-drop, #profile__section form.user-data-management-form .mother-tongue div a.chosen-single, #profile__section form.user-data-management-form .mother-tongue .chosen-with-drop {
  background-color: unset !important; }

#profile__section form.user-data-management-form .language span.update-form-sublabel, #profile__section form.user-data-management-form .mother-tongue span.update-form-sublabel {
  position: unset;
  font-size: 0.75rem;
  color: #888888;
  margin-bottom: 0px;
  text-align: left;
  width: 110%;
  font-weight: 600; }

@media (min-width: 981px) and (max-width: 1250px) {
  #profile__section .container {
    max-width: 960px; } }

@media (max-width: 991px) {
  #profile__section .column {
    margin-top: 0; }
  #profile__section aside.column {
    display: none;
    background: #d3d3d354;
    width: 100vw;
    padding-right: 30px;
    margin: 0 -15px;
    -webkit-box-shadow: 0px 10px 10px 0px #80808057;
            box-shadow: 0px 10px 10px 0px #80808057; }
  #profile__section .profile-title.profile-title-mobile {
    display: block !important;
    background: #d3d3d354;
    margin: 0 -15px;
    width: 100vw; }
    #profile__section .profile-title.profile-title-mobile h3 {
      padding-left: 0; }
  #profile__section .profile-title {
    display: none; } }

.cookie-modal label {
  color: gray; }

.toggle-check-input {
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden; }
  .toggle-check-input:checked ~ .toggle-check-text {
    background: LightGray;
    padding-left: 6px;
    padding-right: 0; }
    .toggle-check-input:checked ~ .toggle-check-text:before {
      content: 'On'; }
    .toggle-check-input:checked ~ .toggle-check-text:after {
      left: 100%;
      margin-left: -1.4em; }

.toggle-check-text {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  color: white;
  padding: 5px;
  width: 55px;
  border-radius: 10px;
  background: #ccc;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
  padding-left: 23px;
  padding-right: 0; }
  .toggle-check-text:after {
    content: ' ';
    display: block;
    background: white;
    width: 17px;
    height: 17px;
    border-radius: 100%;
    position: absolute;
    left: 2px;
    top: 3px;
    -webkit-transition: left 0.15s, margin-left 0.15s;
    transition: left 0.15s, margin-left 0.15s; }
  .toggle-check-text:before {
    content: 'Off'; }

#privacy-banner {
  background-color: #f1f1f1;
  font-weight: 400;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000000;
  padding: 20px 0; }
  #privacy-banner .btn {
    margin-top: 5px; }
  #privacy-banner .banner-settings-button {
    float: right;
    color: #141f72;
    background-color: transparent;
    border: 2px solid #141f72;
    padding: 10px 20px;
    border-radius: 8px;
    margin-left: 10px; }
  #privacy-banner .banner-accept-button {
    float: right;
    color: #ffffff;
    background-color: #141f72;
    border: 2px solid #141f72;
    padding: 10px 20px;
    border-radius: 8px;
    margin-right: 10px; }
    #privacy-banner .banner-accept-button:hover {
      color: #ffffff; }
  #privacy-banner .banner-buttons {
    margin: auto 0;
    padding-left: 0; }
  #privacy-banner .close-banner {
    float: right;
    cursor: pointer; }
  #privacy-banner .toggle-check {
    float: right; }
    #privacy-banner .toggle-check::before {
      width: 30px !important;
      height: 30px !important; }
    #privacy-banner .toggle-check::after {
      width: 30px !important;
      height: 30px !important; }
  #privacy-banner .banner-text {
    padding-right: 0; }

#privacysettings {
  z-index: 10000000001; }
  #privacysettings .alert-info {
    color: #555555;
    background-color: #e7e7e7;
    border-color: #ffffff; }
  #privacysettings .modal-title {
    display: inline-block; }
  #privacysettings .category {
    width: 100%;
    padding-bottom: 20px; }
    #privacysettings .category h5 {
      width: 100%;
      color: gray;
      display: block;
      position: relative;
      margin-bottom: 12px;
      font-size: 1.375rem;
      font-weight: normal; }
  #privacysettings .statistics-item {
    padding-top: 10px; }
  #privacysettings button.js-modal-accept {
    background-color: #141f72;
    color: #ffffff;
    border-color: #141f72; }
    #privacysettings button.js-modal-accept:hover {
      background-color: #141f72;
      border-color: #141f72; }
  #privacysettings label {
    height: unset;
    margin-left: 20px; }
    #privacysettings label::before {
      border: none; }
  #privacysettings .customcheck {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.375rem;
    font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
    #privacysettings .customcheck input {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      #privacysettings .customcheck input:checked ~ .checkmark {
        background-color: #141f72;
        border-radius: 5px; }
        #privacysettings .customcheck input:checked ~ .checkmark:after {
          display: block; }
    #privacysettings .customcheck:hover input ~ .checkmark {
      background-color: #ccc; }
    #privacysettings .customcheck .checkmark:after {
      left: 9px;
      top: 4px;
      width: 7px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
  #privacysettings .checkmark {
    position: absolute;
    top: -8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 5px;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
    #privacysettings .checkmark:after {
      content: "";
      position: absolute;
      display: none; }

.no-items {
  padding-top: 10px; }

@media (max-width: 1199px) {
  #privacy-banner .banner-container {
    font-size: 0.8125rem; }
  #privacy-banner .banner-settings-button {
    padding: 8px 16px; }
  #privacy-banner .banner-accept-button {
    padding: 8px 16px; } }

@media (max-width: 991px) {
  #privacy-banner .banner-settings-button {
    padding: 8px 16px; }
  #privacy-banner .banner-accept-button {
    padding: 8px 16px; }
  #privacy-banner .banner-text {
    display: block;
    width: 100%; }
  #privacy-banner .text-break {
    display: none; } }

@media (max-width: 767px) {
  #privacy-banner .banner-text {
    font-size: 0.75rem;
    line-height: 1.4;
    padding-bottom: 20px;
    padding-right: 15px; }
  #privacy-banner .banner-settings-button {
    float: left;
    margin-right: 10px; }
  #privacy-banner .banner-accept-button {
    float: left;
    margin-left: 10px; } }

#allauth_account {
  min-height: 50vh;
  padding: 60px 0; }
  @media (max-width: 400px) {
    #allauth_account {
      min-height: 80vh; } }
  #allauth_account .account-popup-area .no-account-yet {
    font-size: 0.9375rem;
    margin-top: 15px !important;
    margin-bottom: 30px !important; }
  #allauth_account .account-popup-area.static .account-popup {
    float: none;
    left: unset;
    margin: 0 auto;
    text-align: center;
    width: 100%; }
    #allauth_account .account-popup-area.static .account-popup .loginBtn--facebook {
      width: 100%; }
    @media (min-width: 460px) {
      #allauth_account .account-popup-area.static .account-popup.account__signup {
        width: 430px !important; } }
    #allauth_account .account-popup-area.static .account-popup.account__signup_closed h1 {
      margin-bottom: 30px; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__signup_closed {
        padding-top: 30px; } }
    @media (min-width: 460px) {
      #allauth_account .account-popup-area.static .account-popup.account__login {
        width: 430px !important; } }
    #allauth_account .account-popup-area.static .account-popup.account__inactive h1 {
      margin-bottom: 30px; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__inactive {
        padding-top: 30px; } }
    #allauth_account .account-popup-area.static .account-popup.account__email_verification_sent h1 {
      margin-bottom: 30px; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__email_verification_sent {
        padding-top: 30px; } }
    #allauth_account .account-popup-area.static .account-popup.account__confirm_email h1 {
      margin-bottom: 30px; }
    #allauth_account .account-popup-area.static .account-popup.account__confirm_email .verification-request-link {
      font-weight: bold;
      color: #334562; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__confirm_email {
        padding-top: 30px; } }
    @media (min-width: 460px) {
      #allauth_account .account-popup-area.static .account-popup.account__confirm_email form button {
        width: 430px !important;
        float: unset; } }
    #allauth_account .account-popup-area.static .account-popup.account__verified_email_required a {
      color: #334562;
      font-weight: bold; }
    #allauth_account .account-popup-area.static .account-popup.account__verified_email_required h1 {
      margin-bottom: 30px; }
    #allauth_account .account-popup-area.static .account-popup.account__verified_email_required p {
      margin-bottom: 0; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__verified_email_required {
        padding-top: 30px; } }
    #allauth_account .account-popup-area.static .account-popup.account__password_reset {
      max-width: 430px; }
    #allauth_account .account-popup-area.static .account-popup.account__password_reset_done h1 {
      margin-bottom: 30px; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__password_reset_done {
        padding-top: 30px; } }
    #allauth_account .account-popup-area.static .account-popup.account__password_reset_from_key h1 {
      margin-bottom: 30px; }
    #allauth_account .account-popup-area.static .account-popup.account__password_reset_from_key form {
      padding-top: 30px;
      float: none;
      max-width: 430px;
      margin: 0 auto; }
    #allauth_account .account-popup-area.static .account-popup.account__password_rest_from_key_done h1 {
      margin-bottom: 30px; }
    #allauth_account .account-popup-area.static .account-popup.account__social_login_cancelled h1 {
      margin-bottom: 30px; }
    #allauth_account .account-popup-area.static .account-popup.account__social_login_cancelled a {
      color: #334562;
      font-weight: bold; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__social_login_cancelled {
        padding-top: 30px; } }
    #allauth_account .account-popup-area.static .account-popup.account__social_login_error h1 {
      margin-bottom: 30px; }
    @media (min-width: 992px) {
      #allauth_account .account-popup-area.static .account-popup.account__social_login_error {
        padding-top: 30px; } }

#user_data_wizard {
  min-height: 50vh;
  padding: 60px 0; }
  @media (max-width: 400px) {
    #user_data_wizard {
      min-height: 80vh; } }
  #user_data_wizard .account-popup-area.static .account-popup {
    float: none;
    left: unset;
    margin: 0 auto;
    text-align: left;
    width: 100%; }
    #user_data_wizard .account-popup-area.static .account-popup h1 {
      margin-bottom: 30px; }
    #user_data_wizard .account-popup-area.static .account-popup form > .fields-wrap {
      margin-left: 0;
      margin-right: 0; }
    #user_data_wizard .account-popup-area.static .account-popup form .cfield {
      margin-bottom: 0; }
    #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper {
      width: 100%;
      text-align: left; }
      @media (min-width: 768px) {
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .field-errors {
          min-height: 15px; } }
      @media (min-width: 768px) {
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper.half {
          width: 50%; }
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper.half:nth-child(odd) {
            padding-right: 10px; }
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper.half:nth-child(even) {
            padding-left: 10px; } }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper:not(:last-child) {
        margin-bottom: 20px; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .label {
        font-size: 0.875rem;
        padding-left: 0;
        color: grey;
        margin-bottom: 0px; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .label::before {
          content: none; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .consent-description {
        font-size: 1rem; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .consent-description a {
          font-size: 1rem; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .term-check-field {
        font-family: Open Sans;
        font-size: 1rem;
        color: #202020;
        width: 100%;
        text-align: left; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .term-check-field label::after {
          color: #fff;
          background: #636ab9;
          border-radius: 3px;
          width: 24px !important;
          height: 24px !important; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .term-check-field label::before {
          width: 24px !important;
          height: 24px !important; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .term-check-field input {
          -webkit-appearance: auto; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices {
        margin: auto;
        padding-top: 12px; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices > li {
          margin-bottom: 0; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices input {
          -webkit-appearance: auto; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices .choice-radio-button {
          font-size: 1.25rem;
          color: #5a5a5a; }
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices .choice-radio-button:before {
            width: 30px !important;
            height: 30px !important; }
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper .choices .choice-radio-button:after {
            left: 4px;
            border-width: 11px;
            top: 4px; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper input {
        height: 50px; }
      #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea {
        background-color: #fff;
        font-size: 0.8125rem;
        color: #474747;
        padding: 16px 45px 16px 15px;
        border-radius: 8px;
        min-height: unset;
        height: 200px; }
        @media (min-width: 500px) {
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea {
            height: 150px; } }
        @media (min-width: 768px) {
          #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea {
            height: 100px; } }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea::-webkit-input-placeholder {
          color: #80808082; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea::-moz-placeholder {
          color: #80808082; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea:-ms-input-placeholder {
          color: #80808082; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea::-ms-input-placeholder {
          color: #80808082; }
        #user_data_wizard .account-popup-area.static .account-popup form .wizard-field-wrapper textarea::placeholder {
          color: #80808082; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .data-importance-info {
      line-height: 1.8;
      text-align: left;
      padding-top: 30px; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data hr {
      margin: 30px 0 30px 0; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields > p {
      margin-bottom: 30px; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae h3 {
      text-align: left; }
    @media (max-width: 575.98px) {
      #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .file-upload span {
        display: block; } }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .file-label {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      padding-left: 15px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background-color: #636ab9;
      border: none; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .file-label:hover {
      background-color: #444aa0; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae button {
      float: left; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .filelink, #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae [class*="filelink-"] {
      color: #5b79a8; }
      #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .filelink:hover, #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae [class*="filelink-"]:hover {
        color: #334562; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae #profile-picture-current-wrapper,
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae #profile-picture-preview-wrapper {
      margin: 0; }
    #user_data_wizard .account-popup-area.static .account-popup.wizard__personal_data .optional-fields .c-vitae .clear-selection > i {
      font-size: 1.5rem;
      font-weight: bold;
      margin-left: 1rem;
      border: 1px solid #80808057;
      padding: 0 5px 2px 5px;
      border-radius: 10px;
      color: #404040;
      cursor: pointer; }

.align-custom-left {
  text-align: left; }

.align-custom-right {
  text-align: right; }

.align-custom-center {
  text-align: center; }

.align-custom-wrap-left {
  padding-right: 4%;
  float: left; }

.align-custom-wrap-right {
  padding-left: 4%;
  float: right; }

.align-custom-full-width {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

.align-custom-half-width-left {
  float: left;
  width: 46%;
  margin-right: 4%;
  -o-object-fit: cover;
     object-fit: cover; }

.align-custom-half-width-right {
  float: right;
  width: 46%;
  margin-left: 4%;
  -o-object-fit: cover;
     object-fit: cover; }

img.cms-picture-img {
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: unset !important; }

.pb__0 {
  padding-bottom: 0 !important; }

.pb__10 {
  padding-bottom: 10px !important; }

figcaption.caption {
  font-size: 0.875rem;
  font-style: italic;
  color: #666666;
  padding-top: 4px;
  font-family: "Frank Ruhl Libre", serif;
  padding-bottom: 20px; }

@media (max-width: 500px) {
  .img-div-wrapper {
    width: 100% !important; }
  .img-figure-wrapper {
    width: 100% !important; }
  img.cms-picture-img {
    width: 100%;
    height: auto;
    float: unset;
    padding-left: 0;
    padding-right: 0; }
  figcaption.caption {
    padding-left: 0 !important;
    padding-bottom: 25px;
    width: 100% !important; }
  .align-custom-wrap-left {
    padding-right: 0; }
  .align-custom-wrap-right {
    padding-left: 0; } }

.feature-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding-bottom: 24px;
  min-height: 100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 0;
  margin-right: 0; }

.feature-icon {
  float: left;
  width: 100%;
  color: #141f72;
  font-size: 3.125rem; }

.feature-text {
  line-height: 1.5rem;
  margin-bottom: 0 !important; }

.messages-popup-box .account-popup {
  position: absolute;
  float: none;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  @media (max-width: 499.98px) {
    .messages-popup-box .account-popup {
      top: 50%; } }
  @media (min-width: 500px) {
    .messages-popup-box .account-popup {
      top: 33%; } }
  .messages-popup-box .account-popup .mgs-wrapper h4.new-apply {
    color: #000; }
  .messages-popup-box .account-popup .mgs-wrapper p {
    line-height: 1.6;
    margin-top: 30px; }
    .messages-popup-box .account-popup .mgs-wrapper p a {
      color: #141f72;
      font-weight: 600; }
  .messages-popup-box .account-popup .mgs-wrapper .msg-links > .btn-popup {
    float: none;
    padding: 0;
    width: 100%;
    height: 60px;
    font-size: 1.0625rem;
    font-weight: 700;
    letter-spacing: 0.01875rem;
    margin-top: 20px;
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    border-radius: 8px; }
    .messages-popup-box .account-popup .mgs-wrapper .msg-links > .btn-popup:not(.inverted) {
      background: #141f72;
      color: #fff; }
    .messages-popup-box .account-popup .mgs-wrapper .msg-links > .btn-popup.inverted {
      background: #fff;
      color: #141f72;
      border: 2px solid #141f72; }
    .messages-popup-box .account-popup .mgs-wrapper .msg-links > .btn-popup a {
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: 3.75rem; }
