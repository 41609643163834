.theme-layout {
	min-height: 100vh;
    display: flex;
    flex-direction: column;
	section {
		flex: 1 0 auto;
	}
	footer {
		flex-shrink: 0;
	}
}

// With this, we prevent auto zooming into input fields on iphones.
// If font-size is less than 16px, iphone will zoom into it automatically.
// So we set font-size to 16px for small screens.
@media(max-width: 400px) {
	input[type="color"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	input[type="text"],
	select,
	select:focus,
	select option,
	.chosen-container-single .chosen-single span,
	textarea {
	font-size: 16px !important;
	}
}

#parallax_teasers {
	.row {
		> div {
			.parallax {
				@media(max-width: 499px) {
					// background-size: initial !important;
				}
			}
			.who-am {
				@media(max-width: 499px) {
					margin: 50px 0 50px;
					padding: 0 15px;
				}
				@media(min-width: 1251px) and (max-width: 1440px) {
					padding-left: 300px;
					padding-right: 50px;
					&.flip {
						padding-right: 300px;
    					padding-left: 50px;
					}
				}
			}
		}
	}
}

.job-listing {
	@mixin touch-or-hover {
		border-left-color: #8b91dd;

		-webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
		-ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
		-o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

		z-index: 1;
		position: relative;
	}
	@media(max-width:991.98px) {
		&.touched {
			@include touch-or-hover;
		}
	}
	@media(min-width:992px) {
		&:hover {
			@include touch-or-hover;
		}
	}
}

.post_widget {
	word-break: break-all;
}

.account-popup-area {
	.no-account-yet {
		font-size: 14px;
		margin-top: 15px !important;
		margin-bottom: 30px !important;
	}
}
