@mixin blue-box {
    font-family: 'Quicksand', serif !important;
    color: #fff;
    font-size: 28px !important;
    font-weight: 500;
    line-height: 1.3;
    padding: 1em 1.5em;
    border-radius: .3em;
    background-color: #78bff0;
}
