 /*------------------------------------------------------------------
[Master Stylesheet]

Project:        JOBHUNT
Version:        Initial
Last change:    30/12/2017
Primary use:    Job, Portal
Author:         Creative Layer
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
1. Fonts
2. Theme Layout
3. Chosen, a Select Box Enhancer for jQuery and Prototype
4. Header
5. Header Menus
6. Main Featured Area
7. Theme btn
8. Filter Radius
9. Notification List
10. Search
11. Header Account
12. Heading
13. Country Section
14. Simple text Block
15. Popular Listing
16. Slider
17. Arrows
18. Reviews Sec
19. Blog Sec
20. Subscribe Bar
21. Widget
22. Links Widget
23. Custom Tooltip
24. How it works
25. Client Sec
26. Listing Circle
27. Price table
28. Featured Listings
29. Search Wide
30. Explore Box
31. App Download Sec
32. Breadcrumbs
33. Lisitng Search
34. Side Result
35. Sidebar Widgets
36. Style Star Rating Widget
37. All Listing Details
38. Add Listing Sec
39. Add Hours
40. Code
41. Pagination
42. Heading 2
43. Quick Office Contact
44. Coming Soon Sec
45. Gallery Sec
46. Followers Filter
47. Form Profile Update
48. Menu Action
49. Cross Browser Compatibility
-------------------------------------------------------------------*/

/*=============== General Styling ===================*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License:none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
    margin:0;
    padding:0;
    border:0;
    font-size:100%;
    font:inherit;
    vertical-align:baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section{display:block}
body{line-height:1.2}
blockquote, q{quotes:none}
blockquote:before, blockquote:after,
q:before, q:after{
    content:'';
    content:none;
}
table{
    border-collapse:collapse;
    border-spacing:0;
}
html{overflow-x:hidden}
body{
    color:#555555;
    letter-spacing:0;
    font-family:'Open Sans', sans-serif;;
    overflow-x:hidden;
    position:relative;

}
button,a,input[type="submit"], input[type="button"]{
    cursor: pointer;
}
*{outline:none!important}
h1, h2, h3, h4, h5, h6{
    margin-bottom:20px;
    font-family:'Quicksand', serif !important;
}
h1{font-size:36px}
h2{font-size:32px}
h3{font-size:28px}
h4{font-size:24px}
h5{font-size:20px}
h6{font-size:16px}
p{
    color:#666666;
    font-size:16px;
    margin-bottom:30px;
    line-height:29px;
}



a, a:hover, a:focus {
    color: inherit;
}
a:hover,
a:focus{
    text-decoration:none;
    outline:none;
}
input:hover,input:focus{outline:none}
html.no-scoll {
    overflow: hidden;
}
header {
    z-index: 3;
    position: relative;
}


/* Theme Layout */
.theme-layout{
    float:left;
    overflow:hidden;
    position:relative;
    width:100%;
}
.theme-layout.boxed{
    background:none repeat scroll 0 0 #FFFFFF;
    float:none;
    margin:0 auto;
    overflow:hidden;
    position:relative;
    z-index:1;
    width:1300px;

    -webkit-box-shadow:0 0 6px #747474;
    -moz-box-shadow:0 0 6px #747474;
    -ms-box-shadow:0 0 6px #747474;
    -o-box-shadow:0 0 6px #747474;
    box-shadow:0 0 6px #747474;
}
.block {
    float: left;
    padding: 60px 0;
    position: relative;
    width: 100%;
    z-index: 1;
}
section{
    float:left;
    position:relative;
    width:100%;
}
.double-gap-top {
    padding-top: 120px;
}
.double-gap-bottom {
    padding-bottom: 120px;
}
.less-top {
    padding-top: 30px;
}
.less-bottom {
    padding-bottom: 30px;
}
.row.no-gape{
  margin: 0;
}
.row.no-gape > div{
  padding: 0;
}
.container.fluid{ max-width: 100%; width: 100%; }
.block .container{padding:0}
.container{padding:0}
.block.remove-top{padding-top:0}
.block.no-padding{padding-top:0; padding-bottom:0; }
.block.dark{background:#111111}
.gray{background:#f4f5fa}
.block.remove-bottom{padding-bottom:0}
.parallax,.fixed-bg{
    height:100%;
    width:100%;
    margin:0;
    position:absolute;
    left:0;
    top:0;
    z-index:-1;
    background-size: cover !important;
}
.layer.color::before {
    opacity: 0.8;
}
.fixed-bg{
    background-attachment:fixed !important;
    background-size:cover!important;
}
.theme-layout.boxed-padding {padding-left: 292px; }
.parallax.no-parallax {
    background-attachment: scroll !important;
    background-position: inherit !important;
}
.layer.color.red::before {
    background: #d42525;
}
.layer:before{
    content:"";
    height:100%;
    opacity:0.95;
    z-index:0;
    position:absolute;
    left:0;
    top:0;
    width:100%;
}
.whitish:before{
    background-color:#ffffff;
    opacity:0.90;
}
.blackish:before{
    background-color:#000000;
    opacity:0.7;
}
ul {
    list-style: outside none none;
    margin: 0 0 30px;
    padding: 0;
}
ul li{
    color:#1e1e1e;
    margin-bottom: 15px;
    position: relative;
}
iframe{
    width:100%;
    border:0;
}
i{font-style:italic}
{/*! float:left; *//*! height:auto */}
:-webkit-input-placeholder{opacity:1}
:-moz-placeholder{opacity:1}
:-ms-input-placeholder{opacity:1}
input[type="text"],
input[type="password"],
input[type="email"], textarea {
    background: #e4e4e4 none repeat scroll 0 0;
    border: medium none;
    float: left;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 20px;
    padding: 19px 28px;
    width: 100%;
}
textarea{
    max-width:100%;
    min-height:200px;
    padding: 19px 28px;
}
strong{font-weight:800}
button{
    float:right;
    font-size:18px;
    padding:8px 30px;
    color:#FFF;

    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    -ms-border-radius:4px;
    -o-border-radius:4px;
    border-radius:4px;

    background:black;
    border:0;
    letter-spacing:1px;
}
/* Icons */
/* Arrows */
.slick-dotted .slick-dots {
    float: left;
    margin: 20px 0 0;
    text-align: center;
    width: 100%;
}
.slick-dots li {
    width: 14px;
    height: 14px;
    background: none;
    border: 2px solid #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin: 0 7px;
        margin-top: 0px;
    margin-top: 20px;
    float: none;
    display: inline-block;
}
.slick-dots li button {
    width: 6px;
    height: 6px;
    background: #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-top: 2px;
    margin-right: 2px;
    opacity: 0;
    padding: 0;
    color: transparent;
}
.slick-dots li.slick-active button {
    opacity: 1;
    background: #ffffff;
}
.slick-prev, .slick-next {
    background: none;
    border: none;


    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 40px;
    line-height: 0;
    margin-top: 0;
    outline: medium none;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 100px;
    margin: 0 40px;
    z-index: 11;
    text-align: center;
}
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {opacity: 1; }
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {opacity: .25; }
.slick-prev::before, .slick-next::before {color: #ffffff; font-family: "lineawesome"; font-size: 30px; left: 0; line-height: 1; opacity: 0.75; position: absolute; top: 9px; width: 100%;}
.slick-prev {left: -25px; }
[dir='rtl'] .slick-prev {right: -25px; left: auto; }
.slick-prev::before {content: "\f120"; }
[dir='rtl'] .slick-prev:before {content: '\f120’'; }
.slick-next {right: -25px; }
[dir='rtl'] .slick-next {right: auto; left: -25px; }
.slick-next:before {content: '\f121'; }
[dir='rtl'] .slick-next:before {content: '\f121'; }
.slick-slider
{
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {position: relative; display: block; overflow: hidden; margin: 0; padding: 0; }
.slick-list:focus {outline: none; }
.slick-list.dragging {cursor: pointer; cursor: hand; }
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.slick-track {position: relative; top: 0; left: 0; display: block; }
.slick-track:before, .slick-track:after {display: table; content: ''; }
.slick-track:after {clear: both; }
.slick-loading .slick-track {visibility: hidden; }
.slick-slide {display: none; float: left; height: 100%; min-height: 1px; }
.slideHome{}
[dir='rtl'] .slick-slide {float: right; }
.slick-slide img {display: block; }
.slick-slide.slick-loading img {display: none; }
.slick-slide.dragging img {pointer-events: none; }
.slick-initialized .slick-slide {display: block; }
.slick-loading .slick-slide {visibility: hidden; }
.slick-vertical .slick-slide {display: block; height: auto; border: 1px solid transparent; }
.slick-arrow.slick-hidden {display: none; }
/* Page Loading */
.page-loading {
    background-color: #ffffff;
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 2147483647;
}
.page-loading > img {
    left: 50%;
    position: absolute;
    top: 50%;

    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.page-loading > span {
    color: #b3b3b3;
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    letter-spacing: 0;
    margin-top: 30px;
    text-decoration: underline;
}
/* Basic styles */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  margin: 0;
}
label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 2em;
  margin-right: 10px;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
}
label::before,
label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 20px !important;
  height: 20px !important;
}
label::before {
  content: " ";
  border: 2px solid #e6e7ef;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

}
/* Checkbox */
input[type="checkbox"] + label::after {
  /* content: "\2714"; */
  content: "\2714\fe0e"; /* https://stackoverflow.com/questions/39514315/safari-on-iphone-is-unable-to-style-the-color-of-pseudo-element-after-with-cont/39708945 */
  color: #2c3e50;
  line-height: 1.5;
  text-align: center;
  border: none !important;
}
/* Radio */
input[type="radio"] + label::before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;

}
input[type=radio] + label::after {
  content: " ";
  top: 7px;
  left: 7px;
  width: 6px !important;
  height: 6px !important;
  background: #fff;
  border: 3px solid #FA5B05;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;

}
/* :checked */
input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  background: #ffffff;
  border-color: #e6e7ef;
}
input[type="checkbox"] + label::after,
input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
input[type="checkbox"]:checked + label::after,
input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* Transition */
label::before,
label::after {
  -webkit-transition: .25s all ease;
  -moz-transition: .25s all ease;
  -ms-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}


/* Main Slider Sec */
.main-slider-sec {
    float: left;
    width: 100%;
    position: relative;
    z-index: 0;
    margin: 0;
    background: #141f72;
}
.main-slider-sec img {

    opacity: 1;
}
.main-slider-sec::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;

    background: rgb(139,145,221);
    background: -moz-linear-gradient(45deg,  rgba(139,145,221,1) 0%, rgba(16,25,93,1) 71%, rgba(16,25,93,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(139,145,221,1)), color-stop(71%,rgba(16,25,93,1)), color-stop(100%,rgba(16,25,93,1)));
    background: -webkit-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );
    opacity: 0.85;
}
.main-slider-sec li {
    margin: 0;
}
.main-slider-sec::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url('../images/lines.png');
    z-index: 3;
    opacity: 0.1;
}

/* Header */
.stick-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 12;
}
.menu-sec {
    float: left;
    width: 100%;
    margin: 30px 0;
    margin-bottom: 0;
}
.logo {
    float: left;
    margin: 3px 0;
    position: relative;
}
.logo a {
    float: left;
}
.btn-extars {
    float: right;
}
.post-job-btn {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #ffffff;
    padding: 10px 27px;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

}
.post-job-btn i {
    float: left;
    margin-right: 6px;
    margin-top: 3px;
    font-size: 15px;
}
.account-btns {
    float: right;
    margin: 0;
    margin-top: 3px;
    margin-left: 0px;
    margin-left: 20px;
    padding: 8px 0;
}
.account-btns > li {
    float: left;
    margin: 0;
        margin-left: 0px;
    margin-left: 20px;
}
.account-btns > li a {
    font-family: Open Sans;
    font-size: 15px;
    color: #ffffff;
    float: left;
    line-height: 22px;
}
.account-btns > li a i {
    font-size: 18px;
    float: left;
    line-height: 25px;
    margin-right: 6px;
}
.account-btns > li:first-child {
    margin: 0;
}


.forsticky.sticky .menu-sec nav{margin-top: 4px;}

.newbg .menu-sec nav{margin-top: 4px;}

.menu-sec nav {
    float: right;
    margin-right: 20px;
    margin-top: 0px;
}


.menu-sec nav > ul {
    float: left;
    margin: 0;
}
.menu-sec nav > ul > li {
    float: left;
    position: relative;
    margin: 0;
}
.menu-sec nav > ul > li > a {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #ffffff;
    position: relative;
    padding: 14px 20px;
}
.menu-sec nav > ul > li.menu-item-has-children > a::before {
    position: absolute;
    right: 0px;
    width: 10px;
    height: 10px;
    content: "\f110";
    font-family: lineawesome;
    font-size: 9px;
    top: 50%;
    margin-top: -4px;
}
.menu-sec nav > ul > li > ul {
    opacity: 0;
    visibility: hidden;
    margin: 0;
    position: absolute;
    top: 100%;
    width: 240px;
    padding-top: 0;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);


    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.menu-sec nav > ul > li:hover > ul {
    opacity: 1;
    visibility: visible;
}
.menu-sec nav > ul > li > ul li {
    float: left;
    width: 100%;
    background: #ffffff;
    margin: 0;
    position: relative;
}
.menu-sec nav > ul > li > ul li a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    padding: 9px 25px;
}
.menu-sec nav > ul > li > ul li:first-child > a {
    margin-top: 14px;
}
.menu-sec nav > ul > li > ul li:last-child > a {
    margin-bottom: 14px;
}
.menu-sec nav > ul > li > ul li:first-child {

    -webkit-border-radius: 6px 6px 0px 0px;
    -moz-border-radius: 6px 6px 0px 0px;
    -ms-border-radius: 6px 6px 0px 0px;
    -o-border-radius: 6px 6px 0px 0px;
    border-radius: 6px 6px 0px 0px;

}
.menu-sec nav > ul > li > ul li:last-child {

    -webkit-border-radius: 0px 0px 6px 6px;
    -moz-border-radius: 0px 0px 6px 6px;
    -ms-border-radius: 0px 0px 6px 6px;
    -o-border-radius: 0px 0px 6px 6px;
    border-radius: 0px 0px 6px 6px;

}
.menu-sec nav > ul > li > ul::before {
    position: absolute;
    left: 40px;
    top: -4px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    content: "";
    background: #ffffff;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

}
.menu-sec nav > ul > li > ul li:hover > a {
    padding-left: 35px;
}

/* Account Popup Area */
.account-popup-area {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(20,31,114,0.6);
    opacity: 1;
    z-index: 99;
    overflow-Y: scroll;
    display: none;
}
.account-popup {
    position: relative;
    float: left;
    width: 460px;
    background: #ffffff;
    z-index: 1;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    left: 50%;
    margin-left: -230px;
    margin-top: 140px;
    margin-bottom: 60px;
    text-align: center;
    padding: 40px 60px;
}
.account-popup > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    color: #222222;
    font-weight: bold;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 12px;
    line-height: 10px;
}
.account-popup > span {
    float: left;
    width: 100%;
    font-size: 13px;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-top: 20px;
}
.select-user {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
.select-user > span {
    float: none;
    display: inline-block;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    border: 2px solid;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;

    padding: 9px 29px;
    margin: 0 5px;
        margin-top: 0px;
    margin-top: 5px;
    cursor: pointer;
}
.account-popup > form {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.account-popup > form .cfield {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 19px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.account-popup > form .cfield input {
    float: left;
    width: 100%;
    background: no-repeat;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #474747;
    padding: 16px 45px 16px 15px;
}
.account-popup > form .cfield i {
    position: absolute;
    right: 0;
    top: 15px;
    right: 20px;
    font-size: 19px;
}
.remember-label {
    float: left;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
}
.account-popup > form > a {
    float: right;
    font-family: Open Sans;
    font-size: 13px;
    text-decoration: underline;
    line-height: 30px;
}
.account-popup > form button {
    float: left;
    width: 100%;
    height: 60px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-top: 20px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.extra-login {
    float: left;
    width: 100%;
    margin-top: 30px;
    text-align: center;
    position: relative;
}
.extra-login::before {
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    height: 1px;
    background: #d8dcdc;
    content: "";
}
.extra-login > span {
    width: auto;
    float: none;
    display: inline-block;
    background: #ffffff;
    padding: 1px 20px;
    z-index: 1;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #afafaf;
    text-transform: capitalize;
}
.login-social {
    float: left;
    width: 100%;
    margin-top: 15px;
}
.login-social a {
    float: none;
    display: inline-block;
    width: 50px;
    height: 40px;
    border: 2px solid;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    line-height: 36px;
    font-size: 21px;
    margin: 0 5px;
        margin-top: 0px;
    margin-top: 6px;
}
.login-social a.fb-login {
    color: #3b5998;
    border-color: #3b5998;
}
.login-social a.tw-login {
    color: #1da1f2;
    border-color: #1da1f2;
}

.login-social a {
    font-size: 15px;
}

.account-popup .close-popup,
.account-popup .close-message {
    position: absolute;
    top: -20px;
    width: 50px;
    height: 50px;
    float: none;
    margin: 0;
    right: -20px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 20px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    cursor: pointer;
}
.no-scroll {
    overflow: hidden;
}
.dropdown-field {
    float: left;
    width: 100%;
    margin-bottom: 19px;
     border-radius: 8px;
}
.job-search-sec {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1180px;
    content: "";

    -webkit-transform: translateY(-50%) translateX(-50%);
    -moz-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    -o-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);

    margin-top: 0px;
}
.main-featured-sec {
    float: left;
    width: 100%;
    z-index: 1;
}
.job-search {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 50px;
}
.job-search > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 40px;
    font-weight: normal;
    color: #ffffff;
    letter-spacing: 0px;
    text-align: center;
    line-height: 39px;
    margin-bottom: 13px;
}
.job-search > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 400;
    color: #d5d8f3;
    text-align: center;
    margin-top: 10px;
}
.job-search form {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.job-field {
    float: left;
    width: 100%;
    position: relative;

}

.job-field input {
    float: left;
    width: 100%;
    background: no-repeat;
    border: none;
    font-size: 13px;
    color: #888888;
    margin: 0;
    padding: 0 70px 0 30px;
    height: 61px;
    /* line-height: 61px; */

    background-color: #FFF;

     -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
}

.job-field input::-webkit-input-placeholder { /* Chrome */
  color: #888888;font-size: 13px
}
.job-field input:-ms-input-placeholder { /* IE 10+ */
  color: #888888;font-size: 13px
}
.job-field input::-moz-placeholder { /* Firefox 19+ */
  color: #888888;font-size: 13px
}
.job-field input:-moz-placeholder { /* Firefox 4 - 18 */
  color: #888888;font-size: 13px
}

.job-field i {
    position: absolute;
    right: 30px;
    top: 17px;
    font-size: 27px;
}
.job-search form button {
    float: left;
    width: 100%;
    padding: 9px 0;
    font-size: 30px;
    background-color: #8b91dd; height: 61px;
    border-radius: 8px;
}
.job-search form .row {
    margin: 0 -12px;
}
.job-search form .row > div {
    padding: 0 12px;
}
.job-field .chosen-container-single .chosen-single div::before {
    display: none;
}
.search-job2 .job-field .chosen-container-single .chosen-single div::before {
    display: block;
    color: #333333;
    font-size: 10px;
}
.job-field .chosen-container .chosen-drop {
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
    border: none;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

}
.job-field .chosen-container-single .chosen-single {
    padding: 19px 30px 18px 30px;
}
.dropdown-field .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-with-drop.chosen-container-active .chosen-single {

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.dropdown-field .chosen-container .chosen-drop {
    border: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    background: #fbfbfb;
    margin-top: 10px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.or-browser {
    float: left;
    width: 100%;
    margin-top: 20px;
}
.or-browser > span {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #ffffff;
    line-height: 33px;
}
.or-browser > a {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #ffffff;
    margin-left: 15px; margin-right: 15px;
    border: 1px solid #FFF;
    height: 33px; line-height: 33px; width: 90px; text-align: center;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.scroll-to {
    float: left;
    text-align: center;
    width: 130px;
    height: 130px;
    border: 2px solid #ffffff;
    margin-top: -65px;
    z-index: 22;
    position: relative;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    left: 50%;
    margin-left: -65px;
}
.scroll-to a {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    background: #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    -webkit-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 30px rgba(0,0,0,0.1);

    line-height: 60px;
    font-size: 30px;
}

/* Heading */
.heading {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}
.heading > h2 {
    float: left;
    width: 100%;
    margin: 0;
    font-weight: bold;
    font-size: 30px;
    color: #202020;
    line-height: 23px;
}
.heading span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #888888;
    margin-top: 8px;
    line-height: 29px;
}
.cat-sec {
    float: left;
    width: 100%;
}
.p-category {
    float: left;
    width: 100%;
    z-index: 1;
    position: relative;
}
.p-category > a {
    float: left;
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8ecec;
    border-right: 1px solid #e8ecec;
}
.p-category > a i {
    float: left;
    width: 100%;
    color: #8b91dd;
    font-size: 70px;
    margin-top: 30px;
}
.p-category > a span {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 500;
    color: #232323;
    margin-top: 18px;
}
.p-category > a p {
    float: left;
    width: 100%;
    font-size: 13px;
    margin: 0;
        margin-top: 0px;
    margin-top: 3px;
}
.cat-sec .row > div:last-child a {
    border-right-color: #ffffff;
}
.cat-sec:last-child a {
    border-bottom-color: #ffffff;
}
.browse-all-cat {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 60px;
}
.browse-all-cat a {
    float: none;
    display: inline-block;
    border: 2px solid;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 13px 30px;
    font-family: Open Sans;
    font-size: 15px;
}

/* Simple text Block */
.simple-text-block {
    float: left;
    width: 100%;
    text-align: center;
}
.simple-text-block > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 40px;
    color: #ffffff;
    font-weight: bold;
    margin: 0;
    margin-bottom: 20px;
}
.simple-text-block span {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 18px;
    color: #ffffff;
}
.simple-text-block a {
    float: none;
    display: inline-block;
    border: 2px solid;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 13px 30px;
    font-family: Open Sans;
    font-size: 15px;
    margin-top: 40px;
    color: #ffffff;
}

/* Job Listing */
.job-listings-sec {
    float: left;
    width: 100%;
}
.job-listing {
    float: left;
    width: 100%;
    display: table;
    border-bottom: 1px solid #e8ecec;
    padding: 30px 0;
    background: #ffffff;
    border-left: 2px solid #ffffff;
    padding-right: 30px;
}
.job-title-sec {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
}
.c-logo {
    float: left;
    /* width: 130px;
    text-align: center; */
    width: 120px;
    text-align: left;

}
.c-logo img {
    float: none;
    display: inline-block;
    max-width: 100%;
}
.job-title-sec h3 {
    display: table;
    font-size: 18px;
    color: #232323;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 7px;
    margin-top: 3px;
}
.job-title-sec span {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    margin-top: 1px;
}
.job-lctn {
    display: table-cell;
    vertical-align: middle;
    font-family: open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 23px;
    width: 25%;
}
.job-lctn i {
    font-size: 24px;
    float: left;
    margin-right: 7px;
}
.fav-job {
    display: table-cell;
    vertical-align: middle;
    font-size: 25px;
    color: #888888;
    line-height: 10px;
    text-align: center;
    cursor: pointer;
}
.job-is {
    display: table-cell;
    vertical-align: middle;
    font-family: Open Sans;
    font-size: 12px;
    border: 1px solid;
    float: right;
    padding: 7px 0;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    width: 108px;
    margin: 9px 0;
    text-align: center;
}
.ft.fill {
    background: #8b91dd;
}
.fill.pt {
    background: #7dc246;
}
.fill.fl {
    background: #FA5B05;
}
.fill.tp {
    background: #26ae61;
}
.job-is.ft,
.job-list-modern .job-is.ft{
    color: #8b91dd;
    border-color: #8b91dd;
}
.job-is.pt,
.job-list-modern .job-is.pt{
    color: #7dc246;
    border-color: #7dc246;
}
.job-is.fl,
.job-list-modern .job-is.fl{
    color: #FA5B05;
    border-color: #FA5B05;
}
.job-is.tp,
.job-list-modern .job-is.tp{
    color: #26ae61;
    border-color: #26ae61;
}
/* .job-listing:hover {
    border-left-color: #8b91dd;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    z-index: 1;
    position: relative;
} */
.job-listing:last-child {
    border-bottom-color: #ffffff;
}
.heading.light h2 {
    color: #ffffff;
}
.heading.light span {
    color: #ffffff;
}
.layer.color.light::before {
    /* background: #8b91dd; */
    background: #797ebc;
    opacity: 0.9;
}
.reviews {
    float: left;
    width: 100%;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 30px 30px;
    position: relative;
    margin-top: 47px;
}
.reviews img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    width: 100px;
    height: 100px;
    margin-top: -75px;
}
.reviews h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 18px;
    color: #202020;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 40px;
    margin-bottom: 20px;
}
.reviews h3 span {
    font-family: Open Sans;
    font-size: 15px;
    color: #888888;
    margin-left: 10px;
}
.reviews p {
    float: left;
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    letter-spacing: 0px;
    line-height: 24px;
    margin-bottom: 15px;
}
.reviews::before {
    position: absolute;
    right: 40px;
    top: 20px;
    content: '“';
    font-family: Quicksand;
    font-size: 100px;
    font-weight: bolder;

    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);

    line-height: 30px;
    color: #8b91dd;
}
.reviews-sec {
    float: left;
    width: 100%;
}

.comp-sec {
    float: left;
    width: 100%;
}
.company-img {
    float: left;
    width: 20%;
    text-align: center;
}
.company-img a {
    float: left;
    width: 100%;
}
.company-img a img {
    float: none;
    display: inline-block;
    max-width: 100%;
}

/* Blog Sec */
.blog-sec {
    float: left;
    width: 100%;
    margin-top: -30px;
}
.my-blog {
    float: left;
    width: 100%;
    background: #ffffff;
    margin-top: 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    overflow: hidden;
}
.blog-thumb {
    float: left;
    width: 100%;
    position: relative;
}
.blog-thumb > a {
    float: left;
    width: 100%;
}
.blog-thumb > a img {
    float: left;
    width: 100%;
}
.blog-metas {
    position: absolute;
    left: 0;
    bottom: 0;
    background: #ffffff;
    width: 76%;
    left: 12%;

    -webkit-border-radius: 6px 6px 0px 0px;
    -moz-border-radius: 6px 6px 0px 0px;
    -ms-border-radius: 6px 6px 0px 0px;
    -o-border-radius: 6px 6px 0px 0px;
    border-radius: 6px 6px 0px 0px;

    text-align: center;
    padding: 12px 0;
        padding-bottom: 12px;
    padding-bottom: 0;
    z-index: auto;
    margin-bottom: -1px;
}
.blog-metas a {
    float: none;
    display: inline-block;
    font-family: Open Sans;
    font-size: 13px;
    padding: 0 10px;
    position: relative;
}
.blog-metas a::before {
    position: absolute;
    right: -3px;
    top: 0px;
    width: 1px;
    height: 90%;
    content: "";
}
.blog-metas a:last-child::before {
    display: none;
}
.blog-details {
    float: left;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    border: 1px solid #e8ecec;

    -webkit-border-radius: 0px 0px 6px 6px;
    -moz-border-radius: 0px 0px 6px 6px;
    -ms-border-radius: 0px 0px 6px 6px;
    -o-border-radius: 0px 0px 6px 6px;
    border-radius: 0px 0px 6px 6px;

}
.blog-details h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #232323;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 16px;
    margin-bottom: 20px;
}
.blog-details p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin-bottom: 20px;
}
.blog-details > a {
    float: left;
    width: 100%;
    text-align: center;
    border-top: 1px solid #e8ecec;
    font-family: Open Sans;
    font-size: 13px;
    padding: 17px 0;
}
.my-blog:hover {
    margin-top: 20px;
    -webkit-box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
}
.my-blog:hover .blog-details {
    border-color: #ffffff;
}
.simple-text {
    float: left;
    width: 100%;
    text-align: center;
    padding: 40px 0;
    background: #8b91dd;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.simple-text:hover {
    background: #FA5B05;
}

.simple-text h3 {
    float: left;
    width: 100%;
    font-weight: bold;
    color: #ffffff;
}
.simple-text span {
    float: left;
    width: 100%;
    font-size: 15px;
    color: #ffffff;
    margin: 0;
}
footer {
    float: left;
    width: 100%;
    background: #24324a;
}
.widget {
    float: left;
    width: 100%;
}
.about_widget {
    float: left;
    width: 100%;
}
.about_widget .logo {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.about_widget span, .about_widget p {
    width: 100%;
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #8a99b3;
    line-height: 30px;
    margin: 0;
}
.social {
    float: left;
    width: 100%;
    margin-top: 20px;
}
.social a {
    float: left;
    margin-right: 14px;
    font-size: 20px;
    color: #8a99b3;
}

.social i {
    font-size: 25px;
}

.link_widgets {
    float: left;
    width: 100%;
}
/* remove margin and line-height for link p wrapper created  by static placeholder */
.link_widgets p {
    margin: 0;
    line-height: unset;
}
.link_widgets a {
    float: left;
    width: 100%;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #8a99b3;
    margin-bottom: 12px !important; /* !important because it is not applied for links added through placeholder, as .link_widgets p {margin: 0} */
    padding-left: 24px;
}
.link_widgets a::before {
    position: absolute;
    left: 0;
    top: 8px;
    width: 15px;
    height: 1px;
    content: "";
    background: #8a99b3;
}
.link_widgets a:hover {
    color: #fff !important;
    padding-left: 36px !important;
}
.link_widgets a:hover::before {
    background: #ffffff;
    width: 27px;
}


.link_widgets1 {
    float: left;
    width: 100%;
}

.link_widgets1 a {
    float: left;
    width: 100%;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #8a99b3;
    margin-bottom: 12px;
    padding-left: 24px;

        -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.link_widgets1 a::before {
    position: absolute;
    left: 0;
    top: 8px;
    width: 15px;
    height: 1px;
    content: "";
    background: #8a99b3;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.link_widgets1 a:hover {
    color: #7dc246 !important;
    padding-left: 36px !important;
}
.link_widgets1 a:hover::before {
    background: #7dc246;
    width: 27px;
}


.link_widgets2 {
    float: left;
    width: 100%;
}
.link_widgets2 a {
    float: left;
    width: 100%;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #8a99b3;
    margin-bottom: 12px;
    padding-left: 24px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.link_widgets2 a::before {
    position: absolute;
    left: 0;
    top: 8px;
    width: 15px;
    height: 1px;
    content: "";
    background: #8a99b3;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.link_widgets2 a:hover {
    color: #fff !important;
    padding-left: 36px !important;
}
.link_widgets2 a:hover::before {
    background: #ffffff;
    width: 27px;
}


.link_widgets3 {
    float: left;
    width: 100%;
}

.link_widgets3 a {
    float: left;
    width: 100%;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
    color: #7a8493;
    margin-bottom: 12px;
    padding-left: 0px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.link_widgets3 a i {
    margin-right: 7px;

}

.link_widgets3 a:hover {
    color: #fff !important;
    padding-left: 7px !important;
}


.download_widget {
    float: left;
    width: 100%;
}
.download_widget a {
    float: left;
    width: 100%;
    margin-bottom: 24px;
}
.link_widgets a:last-child {
    margin-bottom: 0;
}
.bottom-line {
    float: left;
    width: 100%;
    text-align: center;
    background: #1d293e;
    padding: 24px 0;
}
.bottom-line span {
    font-family: Open Sans;
    font-size: 13px;
    color: #6a7d9b;
    line-height: 24px;
}

/* Header Style 2 */
header.style2 {
    padding: 0 50px;
}
header.style2 .post-job-btn {
    background: none;
    border: 2px solid #ffffff;
}
header.style2 nav {
    margin-right: 120px;
}
.main-slider-sec.style2::before {
    background: #16192c;
}
.search-job2 {
    float: left;
    width: 100%;
    background: rgba(255,255,255,0.3);

    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;

    margin-top: 50px;
    padding: 9px;
}
.search-job2 form {
    margin: 0;
    background: #ffffff;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;

}
.search-job2 form .row > div:first-child .job-field input {
    -webkit-border-radius: 40px 0px 0px 40px;
    -moz-border-radius: 40px 0px 0px 40px;
    -ms-border-radius: 40px 0px 0px 40px;
    -o-border-radius: 40px 0px 0px 40px;
    border-radius: 40px 0px 0px 40px;

}
.search-job2 form .row > div:last-child button  {

    -webkit-border-radius: 0px 40px 40px 0px;
    -moz-border-radius: 0px 40px 40px 0px;
    -ms-border-radius: 0px 40px 40px 0px;
    -o-border-radius: 0px 40px 40px 0px;
    border-radius: 0px 40px 40px 0px;

}
.search-job2 form button {
    font-size: 15px;
    font-weight: bold;
    background: #d42525;
    padding: 20px 0px;
    width: 100%;
    font-family: Open Sans;
}
.search-job2 .job-field::before {
    position: absolute;
    right: 0;
    top: 17px;
    width: 1px;
    height: 30px;
    background: #e8ecec;
    content: "";
    z-index: 1;
}
.search-job2 form .row {
    margin: 0;
}
.search-job2 form .row > div {
    padding: 0;
}
.job-search-sec .job-search.style2 > span {
    opacity: 1;
}
.quick-select-sec {
    float: left;
    width: 100%;
    text-align: center;
    position: absolute; bottom: -180px;
}
.quick-select {
    float: left;
    width: 100%;
    text-align: center;
}
.quick-select a {
    float: left;
    width: 100%;
}
.quick-select i {
    float: none;
    display: inline-block;
    width: 87px;
    height: 87px;
    text-align: center;
    color: #ffffff;
    line-height: 87px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    font-size: 36px;
}
.quick-select span {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    font-family: Quicksand;
}
.quick-select p {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 13px;
    margin: 0;
    margin-top: 1px;
}
.quick-select:hover i {
    background: #d42525;
}

/* Job Grid Sec */
.job-grid-sec {
    float: left;
    width: 100%;
    margin-top: -30px;
}
.job-grid {
    float: left;
    width: 100%;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -ms-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -o-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.06);

    padding: 0px 20px;
    margin-top: 30px;
}
.job-grid .job-title-sec {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8ecec;
}
.job-grid .job-title-sec .c-logo {
    float: left;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
}
.job-grid .job-title-sec h3 {
    float: left;
    width: 100%;
    margin: 0;
        margin-bottom: 0px;
    text-align: left;
    padding-left: 0px;
    margin-bottom: 6px;
}
.job-grid .job-title-sec span {
    margin-left: 0px;
}
.job-grid .fav-job {
    position: absolute;
    right: 0px;
    top: 20px;
    font-size: 21px;
    color: #A3A3A3;
}
.job-grid .job-lctn {
    float: left;
    width: auto;
    font-size: 13px;
    margin: 18px 0;
}
.job-grid > a {
    float: right;
    font-family: Open Sans;
    font-size: 13px;
    color: #FA5B05;
    border: 1px solid #FA5B05;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    padding: 6px 14px;
    letter-spacing: 0px;
    margin: 16px 0;
    display: inline-block;
}
.browse-all-cat .style2 {
    background: #ffffff;
    border: navajowhite;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    padding: 15px 44px;

    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -ms-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -o-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.06);

    font-size: 15px;
    color: #111111;
}

/* How to Sec */
.how-to-sec {
    float: left;
    width: 100%;
    margin-top: 0px;
    display: table;
}
.how-icon {
    float: none;
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 2px dashed;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    line-height: 94px;
    font-size: 53px;
    text-align: center;
    color: #d42525;
    border-color: #d42525;
}
.how-to h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 18px;
    color: #121212;
    margin: 0;
    margin-top: 30px;
}
.how-to p {
    float: left;
    width: 100%;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin-top: 14px;
}
.how-to {
    float: left;
    width: 33%;
    text-align: center;
    position: relative;
}
.how-to::before {
    position: absolute;
    left: 50%;
    top: 60px;
    width: 270px;
    height: 36px;
    background-image: url('../images/line1.png');
    content: "";
    z-index: 1;
    margin-left: 61px;
}
.how-to:nth-child(n+2)::before {
    background-image: url('../images/line2.png');
    top: 13px;
}
.how-to:last-child::before {
    display: none;
}
.how-to:nth-child(2n+2) {
    padding: 0 50px;
}

/* Top Company Sec */
.top-company-sec {
    float: left;
    width: 100%;
    margin-top: -30px;
}
.top-compnay {
    float: left;
    width: 100%;
    background: #ffffff;

    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -ms-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -o-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.06);


    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 30px;
    text-align: center;
    padding: 20px 20px;
    margin-bottom: 6px;
    margin-bottom: 30px;
}
.top-compnay img {
    float: none;
    display: inline-block;
    max-width: 100%;
}
.top-compnay h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #111111;
    margin-top: 10px;
    margin-bottom: 5px;
}
.top-compnay span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #FA5B05;
}
.top-compnay > a {
    float: none;
    font-family: Open Sans;
    font-size: 13px;
    color: #FA5B05;
    border: 1px solid #FA5B05;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    padding: 6px 14px;
    letter-spacing: 0px;
    margin: 16px 0;
    display: inline-block;
    margin-top: 25px;
    margin-bottom: 5px;
}
.top-company-sec button.slick-arrow {
    z-index: 12;
    border: 2px dashed #c8cccc;
    width: 55px;
    height: 55px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-top: -21px;
}
.top-company-sec button.slick-prev.slick-arrow {
    left: -110px;
}
.top-company-sec button.slick-next.slick-arrow {
    right: -110px;
}
.top-company-sec button.slick-arrow::before {
    color: #bec2c2;
    line-height: 35px;
}

/* Stats Sec */
.stats-sec {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -30px;
}
.stats {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.stats span {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 60px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0px;
    line-height: 50px;
}
.stats h5 {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    font-family: Quicksand;
    font-size: 18px;
    color: #ffffff;
    margin-top: 10px;
}

/* Reviews Style2 */
.reviews.style2 {
    text-align: center;
    padding: 0 240px;
    margin: 0;
    background: none;
}
.reviews.style2 img {
    float: none;
    display: inline-block;
    margin: 0;
    padding: 5px;
    width: 106px;
    height: 106px;
    border: 2px dashed #d42525;
}
.reviews.style2 h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    margin-top: 20px;
}
.reviews.style2 h3 span {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 7px;
    margin-bottom: 10px;
}
.reviews.style2::before {
    font-size: 213px;
    color: #f4f5fa;
    top: auto;
    bottom: 110px;
}
.reviews.style2::after {
    font-size: 213px;
    color: #f4f5fa;
    top: 30px;
    content: '“';
    bottom: 110px;
    font-family: Quicksand;
    font-weight: bold;
    left: 0;
    right: auto;
    position: absolute;
}
.reviews-sec#reviews .slick-dots li {
    border-color: #b7bac6;
}
.reviews-sec#reviews .slick-dots li button {
    background: #b7bac6;
}
.blog-date {
    position: absolute;
    left: 15px;
    background: #ffffff;
    top: 15px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    width: 82px;
    height: 74px;
    text-align: center;
}
.blog-date a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 18px;
    color: #202020;
    font-weight: bold;
    padding: 16px 0;
}
.blog-date a i {
    float: left;
    width: 100%;
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    margin-top: 1px;
}

/* Plans Sec */
.plans-sec {
    float: left;
    width: 100%;
    margin-top: -30px;
}
.pricetable {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    background: #f4f5fa;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 10px;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.pricetable:hover {margin-top: 20px;}

.pricetable-head {
    float: left;
    width: 100%;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -ms-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -o-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.06);

    padding: 35px 0;
}
.pricetable-head h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 20px;
    color: #202020;
    margin: 0;
}
.pricetable-head h2 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 60px;
    font-weight: bold;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 17px;
    line-height: 50px;
    margin-bottom: 13px;
}
.pricetable-head h2 i {
    font-style: normal;
    font-size: 18px;
    line-height: 0px;
    position: relative;
    bottom: 3px;
}
.pricetable-head span {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 15px;
    color: #888888;
    margin-top: 2px;
}
.pricetable ul {
    float: left;
    width: 100%;
    margin: 0;
    margin-top: 30px;
}
.pricetable ul li {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 40px;
}
.pricetable > a {
    float: none;
    display: inline-block;
    font-family: Open Sans;
    font-size: 15px;
    color: #ffffff;
    padding: 15px 55px;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    margin-bottom: 40px;
    margin-top: 20px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.pricetable.active > a {
    background: #ffffff;
}
.pricetable.active ul li {
    color: #ffffff;
}

.pricetable > a:hover {
    background: #d42525;
    color: #FFF;
}


/* Subscription Sec */
.subscription-sec {
    float: left;
    width: 100%;
}
.subscription-sec h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-weight: bold;
    font-size: 30px;
    color: #202020;
    letter-spacing: 0px;
    margin-bottom: 13px;
}
.subscription-sec span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin: 0;
}
.subscription-sec form {
    float: left;
    width: 100%;
    background: #ffffff;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    overflow: hidden;
    position: relative;

    -webkit-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -ms-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    -o-box-shadow: 0px 2px 4px rgba(0,0,0,0.06);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.06);

}
.subscription-sec form input {
    float: left;
    width: 100%;
    background: no-repeat;
    margin: 0;
    font-size: 13px;
    font-family: Open Sans;
    padding: 0px 50px;
    height: 50px;
}
.subscription-sec form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 24px;
    background: #d42525;
}
.social-links {
    float: left;
    width: 100%;
    text-align: center;
}
.social-links a {
    float: none;
    display: inline-block;
    margin: 0 25px;
    line-height: 40px;
}
.social-links a i {
    float: left;
    width: 40px;
    height: 40px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    line-height: 40px;
    font-size: 19px;
    color: #ffffff;
    margin-right: 13px;
}
.social-links a.fb-color i {
    background: #313b87;
}
.social-links a.tw-color i {
    background: #98a3f5;
}
.social-links a.in-color i {
    background: #700ec4;
}
.social-links a.pt-color i {
    background: #ee3c3c;
}
.social-links a.dr-color i {
    background: #bd2a8e;
}
.social-links a.gl-color i {
    background: #d42525;
}
.mega-widget {
    float: left;
    width: 100%;
    text-align: center;
}
.mega-widget .logo {
    float: none;
    display: inline-block;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 20px;
}
.links {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}
.links a {
    display: inline-block;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin: 6px 17px;
}
.mega-widget span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-top: 15px;
}
.bottom-line.style2 span {
    color: #ffffff;
}
header.style3 nav > ul > li > a {
    color: #333333;
}

/* Find Canditate Sec */
.find-cand-sec {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 300px;
    padding-bottom: 190px;
}
.mockup-top {
    position: absolute;
    right: -9%;
    top: 0;
}
.mockup-top img {
    position: relative;
}
header.style3 .menu-sec nav {
    margin-right: 20px;
}
.find-cand {
    float: left;
    width: 100%;
}
.find-cand h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 50px;
    font-weight: bold;
    color: #7dc246;
    letter-spacing: -1px;
}
.find-cand > span {
    float: left;
    width: 100%;
    font-size: 15px;
    color: #222222;
    margin: 0;
    margin-top: -10px;
    margin-left: 4px;
}
.find-cand form {
    float: left;
    width: 100%;
    background: #ffffff;
    padding: 6px;

    -webkit-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 30px rgba(0,0,0,0.1);

    margin-top: 40px;
    margin-left: 4px;

    -webkit-border-radius: 70px;
    -moz-border-radius: 70px;
    -ms-border-radius: 70px;
    -o-border-radius: 70px;
    border-radius: 70px;

    position: relative;
}
.find-cand form .job-field {
    float: left;
    width: 50%;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

}
.find-cand form .job-field input {
    padding: 16px 40px !important;
    height: auto !important;
    line-height: normal !important;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

}
.find-cand .job-field .chosen-container-single .chosen-single {
    padding: 12px 0;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

}

.find-cand .job-field::before {
    position: absolute;
    right: 20px;
    top: 12px;
    width: 1px;
    height: 30px;
    background: #e8ecec;
    content: "";
    z-index: 1;
}
.find-cand .job-field:last-child:before{
    display: none;
}
.find-cand form > button {
    position: absolute;
    right: 7px;
    top: 6px;
    z-index: 1;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    padding: 0;
    width: 50px;
    height: 50px;
    font-size: 23px;
    background-color: #7dc246;
}
.mockup-bottom {
    position: absolute;
    left: 0;
    bottom: -3px;
    left: 50%;
    margin-left: -220px;
}
.scroll-to.style2 {
    border: none;
}
.rounded {
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    -ms-border-radius: 30px !important;
    -o-border-radius: 30px !important;
    border-radius: 30px !important;

}
.layer.color.green::before {
    background: #7dc246;
    opacity: 0.9;
}
.job-listing.rounded {
    -webkit-border-radius: 0 0 !important;
    -moz-border-radius: 0 0 !important;
    -ms-border-radius: 0 0 !important;
    -o-border-radius: 0 0 !important;
    border-radius: 0 0 !important;

    background: no-repeat;
    border-left: none;
}
.job-listing.rounded:hover {
    background: #ffffff;

    -webkit-border-radius: 60px !important;
    -moz-border-radius: 60px !important;
    -ms-border-radius: 60px !important;
    -o-border-radius: 60px !important;
    border-radius: 60px !important;

    margin-top: -1px;
}
.how-to-sec.style2 .how-to::before {
    background-image: url('../images/line3.png');
}
.how-to-sec.style2 .how-to:nth-child(n+2)::before {
    background-image: url(../images/line4.png);
}
.how-to-sec.style2 .how-icon {
    background: #7dc246;
    border: none;
    color: #ffffff;
}

.top-compnay.style2 > a {
    color: #647585;
    border-color: #647585;
}

.top-compnay.style2 > a:hover {
    color: #FFF;
    background-color: #647585;
    border-color: #647585;
}


.top-compnay:hover {

    -webkit-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 10px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 10px 30px rgba(0,0,0,0.1);

}

/* Download Sec */
.download-sec {
    float: left;
    width: 100%;
    display: table;
}
.download-text {
    display: table-cell;
    vertical-align: middle;
    width: 60%;
}
.download-text h3 {
    float: left;
    width: 100%;
    color: #ffffff;
    font-family: Quicksand;
    font-size: 40px;
    font-weight: bold;
    margin: 0;
}
.download-text > p {
    float: left;
    width: 100%;
    color: #ffffff;
    margin: 0;
    margin-top: 14px;
    padding-right: 50px;
}
.download-text ul {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    margin-top: 10px;
}
.download-text ul li {
    float: left;
    margin: 0;
        margin-top: 0px;
    margin-top: 25px;
}
.download-text ul li a {
    float: left;
    border: 1px solid #ffffff;
    width: 230px;
    height: 65px;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    margin-right: 30px;
    padding-left: 82px;
    color: #ffffff;
    position: relative;
}
.download-text ul li a i {
    position: absolute;
    left: 20px;
    top: 7px;
    font-size: 45px;
}
.download-text ul li a span {
    float: left;
    width: 100%;
    font-size: 13px;
}
.download-text ul li a p {
    float: left;
    width: 100%;
    margin: 0;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
}
.download-text ul li a {
    float: left;
    border: 1px solid #ffffff;
    width: 235px;
    height: 65px;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    margin-right: 25px;
    padding-left: 80px;
    color: #ffffff;
    position: relative;
    padding-top: 12px;
}
.download-img {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
}
.download-img img {
    max-width: 100%;
    float: right;
    margin-top: 30px;
}

/* Team Sec */
.team-sec {
    float: left;
    width: 100%;
    margin-top: -30px;
}
.team {
    float: left;
    width: 100%;
    margin-top: 90px;
    text-align: center;
    margin-bottom: 27px;
}
.team-img {
    float: left;
    width: 100%;
}
.team-img img {
    max-width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    border: 7px solid #ffffff;
    float: none;
    display: inline-block;
}
.team-detail {
    float: left;
    width: 100%;
    padding-top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
}
.team-detail > a {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #647585;
    margin-top: 20px;
    opacity: 0;
}
.team-detail h3 {
    float: left;
    width: 100%;
    margin: 0;
    font-family: Open Sans;
    font-size: 15px;
    color: #202020;
}
.team-detail > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-top: 11px;
}
.team-detail > p {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    font-size: 13px;
    color: #888888;
    line-height: 23px;
    margin-top: 20px;
    opacity: 0;
}
.team > .team-img {
    margin-top: -60px;
}
.team:hover {
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-box-shadow: 0px 7px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 7px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 7px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 7px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 7px 20px rgba(0,0,0,0.1);

}
.team:hover p {
    opacity: 1;
}
.team:hover a {
    opacity: 1;
}
.team-sec .slick-dots li {
    border-color: #647585;
}
.team-sec .slick-dots li.slick-active button{
    background: #647585;
}
.pricetable.style2 .pricetable-head h2 {
    color: #7dc246;
}
.pricetable.style2 .pricetable-head h2 i {
    color: #A3A3A3;
}
.pricetable.style2 > a {
    background: #647585;
    color: #ffffff;
}

.pricetable.style2 > a:hover {
    background: #7dc246;
    color: #ffffff;
}


.pricetable.active.style2 {
    background: #647585;
}
.pricetable.active.style2 > a {
    background: #7dc246;
    color: #FFF;
}
.pricetable.style2.active .pricetable-head h2 {
    color: #647585;
}
.ft {
    background: none;
    border-top: 1px solid #eaeeee;
    margin-top: 60px;
}
.ft .footer-title {
    color: #333333;
}
.ft .link_widgets a:hover {
    color: #7dc246;
}
.ft .bottom-line {
    background: #ffffff;
    padding-top: 0;
}
.ft .bottom-line .container {
    border-top: 1px solid #e8ecec;
    padding-top: 24px;
}
header.style4 {
    float: left;
    width: 100%;
    background: #ffffff;
}
.topbar {
    float: left;
    width: 100%;
    background: #222b38;
    padding: 2px 0;
}
.h-social {
    float: left;
    margin: 0;
}
.h-social > li {
    margin: 0;
    float: left;
}
.h-social li a {
    color: #7a8493;
    padding: 0 4px;
    font-size: 18px;
}

.h-social li a i {font-size: 13px;}

.h-contact {
    float: right;
    margin-top: 3px;
}
.h-contact span {
    float: left;
    font-size: 12px;
    color: #ffffff;
    margin-left: 30px;
    line-height: 21px;
}
.h-contact span i {
    float: left;
    font-size: 19px;
    margin-right: 10px;
}
header.style4 .menu-sec nav > ul > li > a {
    color: #222222;
    padding-top: 17px;
    padding-bottom: 42px;
}
header.style4 .menu-sec {
    margin-top: 20px;
}
header.style4 .logo {
    margin: 14px 0;
}
header.style4 .account-btns > li a {
    color: #222b38;
    border: 1px solid #222b38;
    padding: 8px 20px;
    margin: 0;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

}

header.style4 .signin-popup a:hover {
    background: #222b38 !important;
    border-color: #222b38 !important;
    color: #ffffff !important;
}


header.style4 .account-btns > li.signup-popup a {
    color: #1e83f0;
    border-color: #1e83f0;
}
header.style4 .menu-sec nav > ul > li.menu-item-has-children > a::before {
    top: 26px;
}
.main-slider-sec.style3::before {
    display: none;
}
.main-slider-sec.style3::after {
    display: none;
}
.main-slider-sec.style3 {
    background: #000;
}
.main-slider-sec.style3 img {
    opacity: 0.5;
}
.job-search-sec .job-search > h3 {
    font-weight: bold;
}
.search-job2.style2 {
    background: #ffffff;
    padding: 0;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 200px;
}
.search-job2.style2 form {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.search-job2.style2 form .job-field {
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    border-radius: 6px !important;

}
.search-job2.style2 form .job-field label::before {
    display: none;
}
.search-job2.style2 form .job-field label {
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    margin: 0;
    margin-top: 0px;
    line-height: 20px;
    margin-top: 30px;
    padding: 0 30px;
}
.search-job2.style2 form .job-field input {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    line-height: normal;
}
.search-job2.style2 .job-field .chosen-container-single .chosen-single {
    padding-top: 0;
    padding-bottom: 25px;
}
.search-job2.style2 form .job-field::before {
    height: 65px;
}


.job-field.with-icon {
    padding-left: 30px;
}
.job-field.with-icon i {
    right: auto;
    left: 15px;
    top: 35px;
    font-size: 33px;
}
.search-job2.style2 form div button {
    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    border-radius: 6px !important;

    background: #1e83f0;
    font-family: Open Sans;
    padding: 16px 10px;
    margin: 21px 0;
}
.search-job2.style2 form div button i {
    font-size: 24px;
    margin-right: 4px;
    position: relative;
    bottom: -4px;
}
.search-job2.style2 .job-field .chosen-container-single .chosen-single div::before {
    top: 12px;
}
.search-job2.style2 {
    padding: 0 17px;
        padding-left: 17px;
    padding-left: 0;
}
.job-search-sec.style3 {
    -webkit-transform: translateX(-50%) translateY(0%);
    -moz-transform: translateX(-50%) translateY(0%);
    -ms-transform: translateX(-50%) translateY(0%);
    -o-transform: translateX(-50%) translateY(0%);
    transform: translateX(-50%) translateY(0%);

    width: 1000px;
    left: 50%;
    top: auto;
    bottom: -42px;
}

.job-search-sec.style3 .style2{margin-bottom: 0px;}

.job-is.ft {
    margin-top: 12px;
}
.block.overlape {
    z-index: 2;
}
.cat-sec.style2 {
    margin-top: -30px;
}
.p-category.style2 {
    background: #ffffff;
    border: none;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 30px;
}
.p-category.style2 > a {
    border: none;
}
.p-category.style2 > a i {
    color: #647585;
}
.p-category.style2 > a p {
    color: #b1b1b1;
}

.p-category.style2{
    height: auto;
    width: 100%;
    margin-left: 0px;
}


.p-category.style2:hover{
    margin-top: 20px !important;
}

/* Tabs sec */
.tab-sec {
    float: left;
    width: 100%;
    text-align: center;
}
.nav.nav-tabs {
    float: none;
    width: auto;
    text-align: center;
    margin: 0;
    display: inline-block;
    border: 1px solid #e7e7e7;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 0 4px;
}
.nav.nav-tabs > li {
    float: none;
    display: inline-block;
    margin: 0;
}
.nav.nav-tabs > li a {
    float: left;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0px;
    padding: 15px 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 4px;
}
.nav.nav-tabs > li a.current {
    color: #ffffff;
    background-color: #1e83f0;
}
.job-listing.wtabs {
    border: 1px solid #ebefef;
    margin-top: 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    display: inherit;
    text-align: left;
    position: relative;
}
.job-listing.wtabs .job-title-sec {
    float: left;
    width: 70%;
}
.job-listing.wtabs .job-title-sec > span {
    color: #1e83f0;
    display: table;
    float: none;
}
.job-listing.wtabs .job-lctn {
    display: inline;
    padding-top: 20px;
    width: 100%;
    font-size: 13px;
}
.job-listing.wtabs .job-lctn i {
    float: none;
    font-size: 15px;
}
.job-style-bx {
    float: left;
    width: 30%;
    position: absolute;
    right: 0px;
    bottom: 0;
    padding: 15px;
}
.job-style-bx .fav-job {
    font-size: 20px;
    float: right;
    margin-top: 5px;
    margin-right: 10px;
}
.job-style-bx .job-is {
    margin: 0;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;

    color: #ffffff;
}
.tab-sec .tab-content {
    display: none;
}
.tab-sec .tab-content.current {
    display: block;
}
.tab-sec .browse-all-cat .style2 {
    border: 1px solid #ebefef;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;


    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}

.how-to-sec.style2.no-lines .how-to::before {
    display: none;
}
.how-to-sec.style2.no-lines .how-icon {
    border: 1px solid #e8ecec;
    background: none;
    color: #707070;
}
.link_widgets.nolines a::before {
    display: none;
}
.link_widgets.nolines a {
    padding: 0;
    color: #7a8493;
}
footer.style2 span {
    color: #7a8493;
}
.link_widgets.nolines a i {
    font-size: 19px;
    float: left;
    margin-right: 10px;
    width: 21px;
    text-align: left;
}
.subscribe_widget {
    float: left;
    width: 100%;
}
.subscribe_widget > p {
    width: 100%;
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #7a8493;
    line-height: 20px;
}
.subscribe_widget form {
    float: left;
    width: 100%;
    position: relative;
}
.subscribe_widget form input{
    border: 1px solid #7a8493;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    background: none;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #dae4f3;
    padding: 15px 30px;
}
.subscribe_widget form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    -webkit-border-radius: 0 6px 6px 0px;
    -moz-border-radius: 0 6px 6px 0px;
    -ms-border-radius: 0 6px 6px 0px;
    -o-border-radius: 0 6px 6px 0px;
    border-radius: 0 6px 6px 0px;

    width: 50px;
    background: #1e83f0;
    padding: 0;
    font-size: 25px;
}
footer.style2 {
    background: #222b38;
}
.bottom-line.style3 {
    background: #12171e;
}
.bottom-line.style3 span {
    float: left;
    line-height: 33px;
}
.back-top {
    float: right;
    font-family: Open Sans;
    font-size: 13px;
    color: #7a8493;
    line-height: 33px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}
.back-top i {
    float: right;
    width: 33px;
    height: 33px;
    background: #333e4e;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-left: 13px;
    line-height: 33px;
    font-size: 19px;
}

.back-top:hover {
    color: #FFF;
}

/* Header5 */
header.style5 {
    background: #26ae61;
    float: left;
    width: 100%;
    position: relative;
    padding: 20px 60px;
}
header.style5 .account-btns {
    padding: 10px 0;
}
header.style5 .menu-sec {
    margin-top: 0px;
}
header.style5 .post-job-btn {
    background: none;
    border: 2px solid #ffffff;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

}
header.style5 nav {
    float: left;
    margin-right: 0;
}
header.style5 .logo {
    position: absolute;
    left: 50%;
    top: 50%;

    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

}

/* Location */
.form-location-sec {
    float: left;
    width: 100%;
    text-align: center;
    margin: 70px 0;
}
.form-location-sec h3 {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 60px;
    margin: 0;
    margin-top: -15px;
}
.form-location-sec h3 strong {
    float: left;
    width: 100%;
}
.form-location-sec form {
    float: none;
    width: auto;
    text-align: center;
    display: inline-block;
    margin-top: 40px;
    position: relative;
}
.form-location-sec form .field-lct {
    float: left;
    width: auto;
    margin-right: 2px;
    position: relative;
}
.form-location-sec form .field-lct input {
    float: left;
    width: 364px;
    height: 60px;
    background: #ffffff;
    margin: 0;
    font-size: 13px;
    color: #000000;
}
.form-location-sec form .field-lct.sl input {
    width: 240px;
}
.form-location-sec form .field-lct i {
    position: absolute;
    right: 11px;
    top: 15px;
    font-size: 23px;
    color: #737373;
}
.form-location-sec form button {
    display: inline;
    width: 70px;
    height: 60px;
    background: #26ae61;

    -webkit-border-radius: 0 0;
    -moz-border-radius: 0 0;
    -ms-border-radius: 0 0;
    -o-border-radius: 0 0;
    border-radius: 0 0;

    font-size: 26px;
    padding: 0;
    margin-left: 1px;
}



.form-location-sec form a {
    display: block;
    text-align: right;
    font-size: 13px;
    color: #B3B3B3;
    position: relative;
    top: 10px;
    font-size: 13px;
    font-family: Open Sans;
}
.form-location-sec form a {
    text-align: right;
    font-size: 13px;
    color: #B3B3B3;
    position: relative;
    font-size: 13px;
    font-family: Open Sans;
    height: 20px;
    position: absolute;
    width: auto;
    right: 0;
    top: 100%;
    margin-top: 14px;
}
.p-category.style2.gray {
    background: #f4f5fa;
}
.p-category.style2.gray i {
    color: #26ae61;
}
.heading.left {
    text-align: left;
}
.job-listings-sec.style2 .job-listing {
    background: #f4f5fa;
    border: none;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 30px;
}
.job-listings-sec.style2 .job-listing .job-title-sec span {
    color: #26ae61;
}
.job-listings-sec.style2 .job-listing .job-lctn {
    font-size: 13px;
    color: #888888 !important;;
    line-height: 20px;
    margin-left: 14px;
}
.job-listings-sec.style2 .job-title-sec {
    width: 70%;
}
.job-listings-sec.style2 .fav-job {
    font-size: 18px;
    float: right;
    margin: 17px 0;
}
.job-listings-sec.style2 .job-is {
    border: none;
}
.job-listings-sec.style2 .aply-btn {
    float: right;
    font-size: 12px;
    background: #26ae61;
    border: 1px solid #26ae61;
    color: #ffffff;
    padding: 7px 15px;
    padding-top: 8px;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    line-height: 10px;
    padding-top: 7px;
    margin: 10px 0;
}
.job-listings-sec.style2 .job-listing:hover {
    background: #ffffff;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

}
.job-grid.style2 {
    background: #f4f5fa;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;


    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;

}
.job-grid.style2 .job-title-sec {
    padding: 0;
    border: none;
}
.job-grid.style2 .job-title-sec .c-logo {
    margin: 0;
    padding: 0 20px;
}
.job-grid.style2 .job-lctn {
    margin: 0;
        margin-top: 0px;
    margin-top: 2px;
}
.job-grid.style2 > p {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    font-size: 13px;
    line-height: 18px;
    color: #888888;
    letter-spacing: 0px;
    margin-top: 9px;
}
.grid-info-box {
    float: left;
    width: 100%;
}
.grid-info-box {
    float: left;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 17px;
}
.grid-info-box .job-is {
    float: left;
    padding: 0;
    border: none;
    width: auto;
}
.grid-info-box a {
    float: right;
    font-size: 12px;
    background: #26ae61;
    border: 1px solid #26ae61;
    color: #ffffff;
    padding: 8px 15px;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    line-height: 14px;
    margin: 10px 0;
    margin: 6px 0;
    margin-left: 13px;
}
.grid-info-box .fav-job {
    position: static;
    float: left;
    font-size: 19px;
    margin: 8px 0;
    margin-left: 13px;
}
.layer.color.green2::before {
    background-color: #26ae61;
}

.layer.color.orange::before {
    background-color: #FA5B05;
}

.layer.color.blue::before {
    background-color: #141f72;
}

/* Who am */
.who-am {
    float: left;
    width: 100%;
    margin: 100px 0 110px 0;
    padding-left: 370px;
    padding-right: 100px;
}
.who-am h3 {
    float: left;
    width: 100%;
    color: #ffffff;
    font-family: Quicksand;
    font-size: 40px;
    margin: 0;
}
.who-am p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    line-height: 26px;
    color: #ffffff;
}
.who-am a {
    float: left;
    border: 2px solid #ffffff;
    font-family: Quicksand;
    font-size: 15px;
    color: #ffffff;
    padding: 13px 50px;
}
.who-am.flip {
    padding-right: 370px;
    padding-left: 100px;
    text-align: right;
}
.who-am.flip a {
    float: right;
}
.how-to-sec.style2.lines .how-to::before {
    background-image: none;
    height: 1px;
    content: "";
    background-color: #e8ecec;
    top: 53px;
    bottom: auto;
}
.how-to-sec.style2.lines .how-icon {
    background: #f4f5fa;
    color: #26ae61;
}
.how-to-sec.style2.lines .how-to:first-child .how-icon {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    background: #f4f5fa;

}
.stats-sec.style2 .stats span {
    color: #26ae61;
}
.stats-sec.style2 .stats h5 {
    color: #333333;
}

/* Toggle Widget */
#toggle-widget {
    float: left;
    width: 100%;
}
#toggle-widget h2 {
    float: left;
    width: 100%;
    font-size: 15px;
    color: #222222;
    background: #f4f5fa;
    padding: 21px 40px;
    font-family: Open Sans;
    cursor: pointer;
    margin-bottom: 1px;
    position: relative;
}
#toggle-widget .content {
    float: left;
    width: 100%;
    padding: 0 40px;
}
#toggle-widget .content p {
    float: left;
    width: 100%;
    color: #888888;
    font-size: 13px;
    line-height: 24px;
    margin: 0;
    padding: 25px 0;
}
#toggle-widget h2::before {
    position: absolute;
    font-family: lineawesome;
    content: "\f121";
    font-size: 20px;
    color: #FA5B05;
    top: 50%;
    right: 20px;
    margin-top: -12px;
}
#toggle-widget h2.active::before {

    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);

}
.col-lg-4 .reviews.style2 {
    padding: 0;
}
.col-lg-5 .reviews.style2 {
    padding: 0;
}
.col-lg-6 .reviews.style2 {
    padding: 0;
}
.col-lg-4 .reviews.style2::before,
.col-lg-5 .reviews.style2::before,
.col-lg-6 .reviews.style2::before{
    font-size: 100px;
    top: 120px;
    height: auto;
    bottom: auto;
}
.col-lg-4 .reviews.style2::after,
.col-lg-5 .reviews.style2::after,
.col-lg-6 .reviews.style2::after{
    display: none;
}
.detailbar {
    float: left;
    width: 100%;
    padding-right: 150px;
    margin: 60px 0;
}
.detailbar h3 {
    float: left ;
    width: 100%;
    float: left;
    color: #ffffff;
    font-family: Quicksand;
    font-size: 30px;
    font-weight: bold;
}
.detailbar p {
    float: left;
    width: 100%;
    color: #ffffff;
    margin: 0;
}
.detalbr-mkp {
    float: right;
    margin-top: -86px;
}
footer.style3 {
    background: #202020;
}
footer.style3 .bottom-line {
    background: #101010;
}
footer.style3 .bottom-line .back-top i {
    background: none;
    color: #26ae61;
}
footer.style3 .bottom-line span {
    color: #929393;
}
footer.style3 span, footer.style3 a {
    color: #929393;
}
.job-search > h4 {
    float: left;
    width: 100%;
    margin: 0;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
}

.inner-header::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;

    background: rgb(139,145,221);
    background: -moz-linear-gradient(45deg,  rgba(139,145,221,1) 0%, rgba(16,25,93,1) 71%, rgba(16,25,93,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(139,145,221,1)), color-stop(71%,rgba(16,25,93,1)), color-stop(100%,rgba(16,25,93,1)));
    background: -webkit-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );
    opacity: 0.8;
}
.inner-header::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url('../images/lines.png');
    z-index: 0;
    opacity: 0.14;
}
.inner-header {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 240px; padding-bottom: 15px;
    z-index: 0;
}
.inner-header.wform .job-search-sec {
    position: relative;
    float: left;
    z-index: 4;
    top: 0;

    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

}
section.overlape {
    z-index: 2;
}
.emlthis {
    float: left;
    border: 2px solid #d8dcdc;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    border-radius: 6px;

    padding: 12px 30px;
    font-size: 13px;
    color: #888888;
    margin-bottom: 30px;

    line-height: 18px;
}
.emlthis i {
    float: left;
    font-size: 19px;
    margin-right: 11px;
    position: relative;
    top: -1px;
    color: #B3B3B3;
}
.filterbar {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}
.filterbar > h5 {
    float: left;
    font-family: Quicksand;
    font-size: 20px;
    color: #222222;
    font-weight: bold;
    line-height: 33px;
    margin: 0;
}
.sortby-sec {
    float: right;
    padding-top: 5px; /* Center sorting drop vertically inside filterbar */
}

.sortby-sec > span {
    float: left;
    font-size: 13px;
    line-height: 33px;
    color: #888888;
    padding-top: 2px; /* Center text vertically against chosen drop */
    margin-right: 10px;
}
.sortby-sec .chosen-container > a {
    border: none;
    background: #f4f5fa;
    font-size: 13px;
    width: auto;
    padding: 7px 20px;
}
.sortby-sec .chosen-container {
    border: none;
    float: left;
    width: auto !important;
    clear: none;
    margin-left: 10px;
}
.sortby-sec .chosen-container > a div::before {
    font-size: 11px;
    color: #737373;
}
.sortby-sec .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-with-drop.chosen-container-active a {
    background: #dfdfdf;
    color: #222222;
}
.job-grid.border {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    border: 2px solid #e8ecec;
}
.job-grid:hover {
    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

    border-color: #ffffff;
    margin-top: 20px;
}

/* pagination */
.pagination {

    float: left;
    margin: 60px 0 0;
    padding: 0;
    text-align: center;
    width: 100%;
}
.pagination ul {
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    width: 100%; text-align: center;
}
.pagination li {
    display: inline-block;
    font-family: Quicksand;
    font-size: 12px;
    margin: 0 10px 0 0;
}
.pagination li:last-child {
    margin: 0;
}
.pagination li.active a, .pagination li a:active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
}
.pagination li.active a, .pagination li a:hover {
    color: #ffffff;
    background-color: #8b91dd;
    border-color: #8b91dd;
}
.pagination li .delimeter {
    display: block;
    padding-top: 6px;
}
.pagination a,
.pagination span{
    background: #ffffff none repeat scroll 0 0;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    float: left;
    font-family: Open Sans;
    padding: 3px 13px;
    text-decoration: none;
    border: 3px solid #edeff7;
    padding: 9px 15px;
    font-size: 13px;
    color: #000000;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    height: 41px;
}
.pagination li.prev a, .pagination li.next a {
    padding: 9px 25px;
}
.pagination a i {
    font-size: 15px;
    color: #FA5B05;
}
.inner-header > h3 {
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin: 0;
    margin-bottom: 50px;
}
.search_widget_job {
    float: left;
    width: 100%;
}
.field_w_search {
    float: left;
    width: 100%;
    position: relative;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-bottom: 20px;
}
.field_w_search input {
    float: left;
    width: 100%;
    background: none;
    padding: 15px 25px;
    font-size: 13px;
    margin: 0;
}
.field_w_search i {
    position: absolute;
    right: 15px;
    top: 12px;
    color: #FA5B05;
    font-size: 24px;
}
.border-right {
    padding-right: 40px;
    border-right: 1px solid #edeff7;
    padding-top: 40px;
    padding-bottom: 40px;
}
.search_widget_job .field_w_search:last-child {
    margin: 0;
}
aside .widget {
    margin-top: 25px;
}
aside .widget:first-child {
    margin: 0;
}
.sb-title {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #111111;
    margin: 0;
    position: relative;
    padding-right: 30px;
    margin-bottom: 0;
    cursor: pointer;
}
.sb-title::before {
    position: absolute;
    right: 0;
    top: 11px;
    width: 20px;
    height: 1px;
    background: #222222;
    content: "";
}
.posted_widget {
    float: left;
    width: 100%;
}
.posted_widget label {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    margin-bottom: 16px;
    padding-left: 30px;
}

.frm_widget {
    float: left;
    width: 100%;
}
.frm_widget label {
    font-size: 13px;
    color: #888888;
    position: relative;
    display: inline-block;
    padding: 0 0 0 2em;
    margin-right: 10px;
    height: 1.5em;
    line-height: 1.5;
    cursor: pointer;
}


.nm {
    margin: 0 !important;
}
.type_widget {
    float: left;
    width: 100%;
}
.type_widget p {
    float: left;
    width: 100%;
    margin: 0;
    font-size: 13px;
    color: #888888;
    margin-bottom: 4px;
}
.type_widget p label {
    padding-left: 31px;
}
.flchek label::before {
    border-color: #FA5B05 !important;
}
.ftchek label::before {
    border-color: #8b91dd !important;
}
.ischek label::before {
    border-color: #f831e3 !important;
}
.ptchek label::before {
    border-color: #7dc246 !important;
}
.tpchek label::before {
    border-color: #26ae61 !important;
}
.vtchek label::before {
    border-color: #18f0f8 !important;
}
.sb-title::after {
    position: absolute;
    right: 8px;
    top: 4px;
    width: 1px;
    height: 0px;
    background: #222222;
    content: "";
}
.sb-title.open.active::before {
    width: 17px;
}
.sb-title::after {
    height: 0px;
}
.sb-title.open.active::after {
    height: 15px;
}

.sb-title.open.active {
    margin-bottom: 0;
}
.specialism_widget {
    float: left;
    width: 100%;
}
.simple-checkbox p {
    float: left;
    width: 100%;
    margin: 0;
        margin-bottom: 0px;
    font-family: Open Sans;
    font-size: 13px;
    margin-bottom: 4px;
}
.simple-checkbox p label {
    padding-left: 31px;
}
.simple-checkbox p input[type="checkbox"]:checked + label::before, .simple-checkbox p input[type="radio"]:checked + label::before {
    background: #2c7dfa;
    border-color: #2c7dfa;
}
.simple-checkbox p input[type="checkbox"] + label::after {
    color: #ffffff;
}
.simple-checkbox {
    float: left;
    width: 100% !important;
}
.sb-title.closed::after {
    height: 15px;
}
.sb-title.closed::before {
    width: 17px;
}
aside .widget > div {
    margin-top: 15px;
}
.subscribe_widget > h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #111111;
    margin: 0;
        margin-bottom: 0px;
    position: relative;
    padding-right: 30px;
    margin-bottom: 15px;
    cursor: pointer;
}
aside .subscribe_widget form input {
    border: 2px solid #e8ecec;
    color: #333333;
}
aside .subscribe_widget form button {
    background: #FA5B05;
}


.tags-bar {
    float: left;
    width: 100%;
    margin-top: 40px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 10px;
    position: relative;
}
.modrn-joblist {
    float: left;
    width: 100%;
    padding-left: 30px;
    /* border-bottom: 1px solid #edeff7; */
}
.tags-bar > span {
    float: left;
    background: #f4f5fa;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    font-family: Open Sans;
    font-size: 13px;
    padding: 7px 17px;
    margin-right: 15px;
    position: relative;
}
.tags-bar > span i {
    position: absolute;
    right: -6px;
    top: -5px;
    width: 16px;
    height: 16px;
    font-style: normal;
    background: #FA5B05;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    color: #ffffff;
    text-align: center;
    line-height: 13px;
    font-size: 10px;
    font-family: Open Sans;
    cursor: pointer;
}
.action-tags {
    float: right;
}
.action-tags a {
    float: left;
    font-size: 13px;
    color: #8b91dd;
    padding: 7px 6px;
    line-height: 17px;
}
.action-tags a i {
    float: left;
    font-size: 18px;
    margin-right: 4px;
}
.modrn-joblist .filterbar {
    margin-top: 30px;
}
.filterbar .emlthis {
    margin: 0;
    padding: 10px 30px;
}
.modrn-joblist .filterbar h5 {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    margin-top: 25px;
}
.job-style-bx > i {
    position: absolute;
    right: 16px;
    bottom: 0;
    font-style: normal;
    font-size: 13px;
    color: #888888;
}
.job-list-modern .job-listing.wtabs {
    margin: 0;
        margin-top: 0px;

    -webkit-border-radius: 0 0;
    -moz-border-radius: 0 0;
    -ms-border-radius: 0 0;
    -o-border-radius: 0 0;
    border-radius: 0 0;

    border-left-color: #ffffff;
    border-right-color: #ffffff;
    border-top-color: #edeff7;
    border-bottom-color: #edeff7;
    margin-top: -1px;
    padding: 30px 0px;
}
.job-list-modern .job-listing.wtabs .job-style-bx {
    padding-bottom: 31px;
    bottom: 50%;

    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);

}

.ss-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}

.ss-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.ss-content.rtl {
  width: calc(100% + 18px);
  right: auto;
}

.ss-scroll {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  width: 5px;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;

  top: 0;
  z-index: 2;
  cursor: pointer;

  -webkit-transition: opacity 0.25s linear;
  -moz-transition: opacity 0.25s linear;
  -ms-transition: opacity 0.25s linear;
  -o-transition: opacity 0.25s linear;
  transition: opacity 0.25s linear;

}

.ss-hidden {
  display: none;
}

.ss-container:hover .ss-scroll,
.ss-container:active .ss-scroll {
  opacity: 1;
}

.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.simple-checkbox.scrollbar {
    height: 232px;
}
.viewmore {
    float: left;
    width: 100%;
    margin-top: 90px;
    text-align: center;
    margin-bottom: 90px;
}
.viewmore span {
    float: none;
    display: inline-block;
    border: 2px solid #e8ecec;
    padding: 9px 25px;
    font-size: 13px;
    font-family: Open Sans;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    cursor: pointer;
}
.viewmore span i {
    width: 7px;
    height: 7px;
    float: left;
    background: #e8ecec;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    margin: 5px 2px;
}
.viewmore span i:last-child {
    margin-right: 10px;
}
.job-search .tags-bar {
    padding: 0 120px;
    border: none;
}
.job-search .tags-bar > span {
    border: 2px solid #ffffff;
    background: none;
    color: #ffffff;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

}
.job-search .tags-bar > span > i {
    color: #FA5B05;
    background: #ffffff;
}
.job-search .tags-bar .action-tags a {
    color: #ffffff;
}
.widget.border {
    border: 2px solid #e6e7ef;
    padding: 18px 20px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
aside .widget.border {
    margin-top: 10px;
}
.banner_widget {
    float: left;
    width: 100%;
    margin-top: 10px;
}
.banner_widget a {
    float: left;
    width: 100%;
}
.banner_widget a img {
    float: left;
    width: 100%;
}
aside .widget.border:first-child {
    margin-top: 30px;
}
.job-listings-sec.no-border .job-listing {
    border: 2px solid #ffffff;
    border-radius: 8px;
}
.job-statistic {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 50px;
    z-index: 1;
}
.job-statistic span {
    float: none;
    display: inline-block;
    font-size: 12px;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 7px 20px;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

}
.job-statistic p {
    float: none;
    display: inline-block;
    color: #ffffff;
    font-size: 13px;
    margin: 0 20px;
}
.job-statistic p i {
    font-size: 23px;
    float: left;
    line-height: 29px;
    margin-right: 9px;
}

/* Job Single Sec */
.job-single-sec {
    float: left;
    width: 100%;
}
.job-single-head {
    float: left;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8ecec;
    display: table;
}
.job-thumb {
    display: table-cell;
    vertical-align: top;
    width: 107px;
}
.job-thumb img {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.job-head-info {
    display: table-cell;
    vertical-align: middle;
    padding-left: 25px;
}
.job-head-info h4 {
    float: left;
    width: 100%;
    font-size: 24px;
    color: #202020;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 10px;
}
.job-head-info span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 10px;
}
.job-head-info p {
    float: left;
    margin: 0;
        margin-top: 0px;
        margin-right: 0px;
    font-size: 13px;
    margin-right: 40px;
    color: #888;
    margin-top: 11px;
}
.job-head-info p i {
    float: left;
    font-size: 21px;
    line-height: 27px;
    margin-right: 9px;
}
.job-details {
    /* float: left; */
    width: 100%;
    padding-top: 20px;
}
.job-details h3 {
    /* float: left; */
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 15px;
    margin-top: 10px;
}
.job-details p,
.job-details li {
    /* float: left; */
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
    margin-bottom: 19px;
}
.job-details > ul {
    /* float: left; */
    width: 100%;
    margin-bottom: 20px;
}
.job-details > ul li {
    /* float: left; */
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
    position: relative;
    padding-left: 23px;
    line-height: 21px;
    margin-bottom: 10px;
    font-size: 13px;
    color: #888888;
}
.job-details > ul li::before {
    position: absolute;
    left: 0;
    top: 13px;
    width: 10px;
    height: 1px;
    background: #888888;
    content: "";
}
.share-bar {
    float: left;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #e8ecec;
    border-bottom: 1px solid #e8ecec;
}
.share-bar span {
    float: left;
    font-size: 15px;
    color: #202020;
    line-height: 40px;
    margin-right: 14px;
}

.share-bar  a {
    float: none;
    display: inline-block;
    width: 47px;
    height: 35px;
    border: 2px solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    line-height: 30px;
    font-size: 18px;
    margin: 0 5px;
        margin-top: 0px;
    text-align: center;
    margin-top: 0px;
    margin-top: 6px;
}

.share-bar a.share-fb {
    color: #3b5998;
    border-color: #3b5998;
}
.share-bar  a.share-twitter {
    color: #1da1f2;
    border-color: #1da1f2;
}
.share-bar  a.share-google {
    color: #FA5B05;
    border-color: #FA5B05;
}

.share-bar a.share-fb:hover {
    background: #3b5998;
    border-color: #3b5998;
    color: #ffffff;
}
.share-bar  a.share-twitter:hover {
    background: #1da1f2;
    border-color: #1da1f2;
    color: #ffffff;
}
.share-bar  a.share-google:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}

.recent-jobs {
    float: left;
    width: 100%;
    padding-top: 30px;
}
.recent-jobs > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #202020;
    font-weight: 500;
    margin-bottom: 15px;
}
.apply-thisjob {
    float: left;
    width: 100%;
    border: 2px solid #8b91dd;
    text-align: center;
    color: #8b91dd;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 20px 20px;
    font-size: 15px;
    font-family: Open Sans;
    font-weight: bold;
}
.apply-thisjob i {
    font-size: 28px;
    margin-right: 8px;
    line-height: 11px;
    position: relative;
    top: 5px;
}
.apply-alternative {
    float: left;
    width: 100%;
    padding-top: 30px;
}
.apply-alternative a {
    float: left;
    border: 2px solid #e8ecec;
    font-size: 13px;
    color: #888888;
    padding: 0 20px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    height: 50px;
    line-height: 50px;

}
.apply-alternative a i {
    font-size: 20px;
    float: left;
    margin-right: 6px;
    margin-top: 12px;
}
.apply-alternative span {
    float: right;
    border: 2px solid #e8ecec;
    font-size: 13px;
    color: #888888;
    padding: 0 30px;
    height: 50px; line-height: 50px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.apply-alternative span:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}

.apply-alternative span i {
    font-size: 20px;
    float: left;
    margin-right: 6px;
    margin-top: 14px;
}

/* Job Overview */
.job-overview {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.job-overview > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}
.job-overview ul {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin: 0;
    padding: 15px !important;
}
.job-overview ul > li {
    float: left;
    width: 100%;
    margin: 0;
    position: relative;
    padding-left: 67px;
    margin: 15px 0;
}
.job-overview ul > li i {
    position: absolute;
    left: 23px;
    top: 5px;
    font-size: 30px;
    color: #8b91dd;
}
.job-overview ul > li h3 {
    float: left;
    width: 100%;
    font-size: 13px;
    font-family: Open Sans;
    margin: 0;
}
.job-overview ul > li span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    margin-top: 7px;
}
.job-location {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.job-location > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #202020;
}
.job-lctn-map {
    float: left;
    width: 100%;
}
.job-lctn-map iframe {
    float: left;
    width: 100%;
    border: none;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    height: 270px;
}
.extra-job-info {
    float: left;
    width: calc(100% - 30px);
    margin-top: 30px;
}
.extra-job-info > span {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 17px 20px;
    margin-bottom: 20px;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
}
.extra-job-info > span i {
    float: left;
    font-size: 30px;
    color: #8b91dd;
    width: 30px;
    margin-right: 12px;
}
.extra-job-info > span strong {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
    color: #202020;
    line-height: 28px;
    display: inline-block;
    margin-right: 7px;
}
.job-single-head2 {
    float: left;
    width: 100%;
    padding-bottom: 50px;
    border-bottom: 1px solid #e8ecec;
}
.job-title2 {
    float: left;
    width: 100%;
}
.job-title2 > h3 {
    float: left;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
        margin-right: 0px;
    margin-right: 20px;
}
.job-title2 span.job-is {
    float: left;
    margin: 0;
}
.job-title2 > i {
    float: left;
    font-size: 26px;
    color: #B8B8B8;
    margin: 1px 0;
    margin-left: 18px;
}
.tags-jobs {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    margin-top: 20px;
}
.tags-jobs > li {
    float: left;
    margin: 0;
        margin-right: 0px;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-right: 30px;
}
.tags-jobs > li i {
    float: left;
    font-size: 23px;
    float: left;
    line-height: 15px;
    margin-right: 8px;
    color: #8b91dd;
}
.tags-jobs > li span {
    color: #8b91dd;
}
.job-single-head2 > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    margin-top: 20px;
}
.job-single-head2 > span strong {
    font-weight: normal;
    color: #202020;
}
.job-wide-devider .job-overview {
    margin: 0;
}
.job-single-sec .job-overview ul {
    padding: 0;

    margin-bottom: 20px;
}
.job-single-sec .job-overview ul li {
    float: left;
    width: 33.334%;
    padding-left: 50px;
}
.job-single-sec .job-overview ul li i {
    left: 0;
}
.job-single-head.style2 {
    float: left;
    width: 100%;
    display: inherit;
    text-align: center;
    border: none;
}
.job-single-head.style2 .job-thumb {
    float: left;
    width: 100%;
    text-align: center;
}
.job-single-head.style2 .job-thumb img {
    float: none;
    display: inline-block;
    width: auto;
    border: none;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);


    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.job-single-head.style2 .job-head-info {
    float: left;
    width: 100%;
    display: inherit;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 40px;
}
.job-single-head.style2 .job-head-info p {
    float: left;
    width: 100%;
    text-align: center;
    margin: 0;
        margin-top: 0px;
    margin-top: 5px;
}
.job-single-head.style2 .job-head-info p i {
    float: none;
    color: #8b91dd;
}
.job-single-head.style2 .job-head-info > span {
    margin-top: 5px;
    margin-bottom: 20px;
}
.job-single-head.style2 > a {
    float: none;
    display: inline-block;
}
.apply-job-btn {
    background: #FA5B05;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    padding: 20px 0;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    width: 250px;
    height: 60px;
}
.apply-job-btn i {
    margin-right: 10px;
    float: none;
    font-size: 23px;
    line-height: 9px;
    position: relative;
    top: 4px;
}
.apply-job-linkedin {
    float: none;
    display: inline-block;
    background: #ffffff;
    padding: 21px 0;

    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);


    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    font-family: Open Sans;
    font-size: 13px;
    color: #FA5B05;
    width: 250px;
    height: 60px;
    margin-top: 35px;
}
.apply-job-linkedin i {
    float: none;
    font-size: 25px;
    margin-right: 10px;
    line-height: 8px;
    position: relative;
    top: 4px;
}
.viewall-jobs {
    background: #8b91dd;
    width: 250px;
    height: 60px;
    line-height: 60px;
    color: #ffffff;
    font-family: Open Sans;
    font-size: 13px;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

    margin-top: 35px;
}
.job-single-head3 {
    float: left;
    width: 100%;
    display: table;
    padding-bottom: 30px;
    border: none;
}
.job-single-head3 .job-thumb {
    width: 125px;
}
.job-single-info3 {
    display: table-cell;
    vertical-align: top;
    padding-left: 25px;
}
.job-single-info3 > h3 {
    float: left;
    width: 100%;
    font-size: 24px;
    color: #202020;
    font-weight: bold;
    margin-bottom: 4px;
}
.job-single-info3 > span {
    float: left;
    font-size: 13px;
    color: #888888;
    margin: 7px 0;
}
.job-single-info3 > span i {
    float: left;
    font-size: 23px;
    float: left;
    line-height: 15px;
    margin-right: 8px;
    color: #8b91dd;
}
.job-single-info3 .job-is {
    margin: 0;
        margin-left: 0px;
    margin-left: 20px;
    font-size: 12px;
}
.job-single-info3 .tags-jobs {
    margin-top: 16px;
}
.job-thumb > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #FA5B05;
    text-align: center;
    margin-top: 23px;
}
.job-single-sec.style3 .share-bar {
    border: none;
    padding: 0;
}
.job-single-sec .extra-job-info {
    border: 2px solid #e8ecec;
    padding: 20px 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    width: 100% !important;

}
.job-single-sec .extra-job-info > span {
    float: left;
    width: 33.334%;
    padding: 0;
    border: none;
    margin: 0;
}

.job-head-wide {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e8ecec;
}
.job-head-wide .apply-thisjob {
    background: #9fa4e1;
    border: 2px solid #9fa4e1;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;

    color: #ffffff;
}
.job-head-wide .apply-alternative a, .job-head-wide .apply-alternative span {

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;

}
.job-wide-devider {
    float: left;
    width: 100%;
}
.job-wide-devider .job-overview {
    margin-top: 30px;
}
.job-wide-devider .job-overview ul {
    padding: 20px 0;

}
.job-wide-devider .job-overview > ul > li {
    float: left;
    width: 100%;
    padding-left: 67px;
}
.job-wide-devider .job-overview > ul > li i {
    left: 23px;
}

/* Quick Form JOb */
.quick-form-job {
    float: left;
    width: 100%;
    margin-top: 10px;
}
.quick-form-job > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #202020;
    font-weight: 500;
    margin-bottom: 15px;

}
.quick-form-job form {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 30px;
}
.quick-form-job form input {
    float: left;
    width: 100%;
    background: none;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    font-size: 13px;
    color: #888888;
    padding: 15px 25px;
}
.quick-form-job form input, .quick-form-job form textarea {
    float: left;
    width: 100%;
    background: none;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    font-size: 13px;
    color: #888888;
    padding: 15px 25px;
    font-family: Open Sans;
    margin-bottom: 15px;
}
.quick-form-job form button {
    float: left;
    width: 100%;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    background: #424f95;
    font-size: 18px;
    font-family: Open Sans;
    padding: 16px 10px;
    margin-bottom: 15px;
    border: 2px solid #424f95;
}
.quick-form-job form > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    text-align: center;
}
.quick-form-job form > span a {
    color: #202020;
}

/* Employe Lists */
.emply-list-sec {
    float: left;
    width: 100%;
}
.emply-list {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e8ecec;
    display: table;
    padding-left: 35px;
    padding-top: 35px;
    padding-bottom: 35px;
    padding-right: 30px;
}
.emply-list-thumb {
    display: table-cell;
    vertical-align: top;
    width: 80px;
}
.emply-list-thumb > a {
    float: left;
    width: 100%;
}
.emply-list-thumb > a img {
    float: left;
    width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.emply-list-info {
    display: table-cell;
    vertical-align: top;
    padding-left: 17px;
    position: relative;
}
.emply-pstn {
    position: absolute;
    right: 0;
    top: 0;
    color: #8b91dd;
    font-size: 13px;
}
.emply-list-info > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #202020;
    margin-bottom: 7px;
    font-weight: 500;
}
.emply-list-info > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #FA5B05;
}
.emply-list-info > h6 {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    font-size: 13px;
    font-family: Open Sans;
    margin-top: 13px;
}
.emply-list-info > p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
        margin-top: 0px;
    margin-top: 12px;
}
.emply-list-info > h6 i {
    float: left;
    font-size: 20px;
    line-height: 16px;
    margin-right: 4px;
    color: #999999;
}
.emply-list-sec .pagination {
    margin-bottom: 60px;
    text-align: center;
}
.emply-list:hover {
    -webkit-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);

    border-color: #ffffff;
}
.margin_widget .widget, .margin_widget .widget.border {
    margin-bottom: 30px !important;
    margin-top: 0;
}
.filterbar > p {
    float: left;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 36px;
}
.alpha-pag {
    float: left;
    width: 100%;
    background: #f4f5fa;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 16px 20px;
    margin-bottom: 30px;
}
.alpha-pag a.active, .alpha-pag a:hover {
    background: #8b91dd;
    color: #ffffff;
}
.alpha-pag a {
    float: left;
    font-size: 13px;
    color: #888888;
    padding: 6px 11px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    letter-spacing: 0px;
}
.emply-list-sec.style2 .emply-list {
    border: 2px solid #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.emply-list-sec.style2 .emply-list:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    border-color: #e6e7ef;
}
.emply-list-sec.style2 .emply-list-thumb > a img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    border: 2px solid #e8ecec;
}

.is_clickable {
  cursor: hand;
  cursor: pointer;
}
.nstSlider {
  cursor: pointer;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: relative;
  width: 100%;
  height: 10px;
  background: #f4f5fa;
  margin-top: 10px;
}
.nstSlider .leftGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip,
.nstSlider .leftGrip {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 2px solid #FA5B05;
  top: -5px;
}
.nstSlider .bar {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  background: #FA5B05;
  height: 8px;
  top: 0px;
}
.nstSlider .highlightPanel {
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: absolute;
  background: #e87722;
  height: 20px;
  top: 0;
}
.range_slider {
    float: left;
    width: 100%;
}
.range_slider .leftLabel, .range_slider .rightLabel {
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-top: 9px;
}
.range_slider .leftLabel {
    float: left;
}
.range_slider .rightLabel {
    float: right;
}

.emply-list.box {
    border: 2px solid #e6e7ef;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    float: left;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}
.emply-list.box .emply-list-thumb {
    float: left;
    width: 100%;
}
.emply-list.box .emply-list-thumb a {
    float: none;
    width: auto;
    display: inline-block;
}
.emply-list.box .emply-list-thumb a img {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    border: 2px solid #e8ecec;
}
.emply-list.box .emply-list-info {
    float: left;
    width: 100%;
    padding: 0;
        padding-top: 0px;
    padding-top: 12px;
    position: static;
}
.emply-list.box .emply-pstn {
    position: absolute;
    right: 15px;
    top: 14px;
}
.emply-list-info > h6 i {
    float: none;
    line-height: 13px;
    position: relative;
    top: 2px;
    margin-right: 3px;
}
.emply-list.box:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    border-color: #8b91dd;
}
.alpha-pag.full a {
    font-size: 13px;
    padding: 10px 15px;
    font-weight: bold;
}
.emply-text-sec {
    float: left;
    width: 100%;
}
.emply-text {
    float: left;
    width: 100%;
    border: 2px solid #e6e8ef;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 20px;
    margin-bottom: 30px;
}
.emply-text > h3 {
    float: left;
    width: 100%;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 16px;
}
.emply-text > h3 span {
    float: left;
    width: 50px;
    height: 50px;
    background: #FA5B05;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    text-align: center;
    color: #ffffff;
    line-height: 50px;
    font-weight: bold;
    font-size: 15px;
}
.emply-text > ul {
    float: left;
    width: 100%;
    margin: 0;
}
.emply-text > ul > li {
    float: left;
    width: 100%;
}
.emply-text > ul > li {
    float: left;
    width: 100%;
    margin: 5px 0;
    padding-left: 34px;
    position: relative;
    font-family: Open Sans;
    font-size: 13px;
}
.emply-text > ul > li::before {
    position: absolute;
    left: 0;
    top: 8px;
    width: 15px;
    height: 1px;
    background: #888888;
    content: "";
}
.emply-btns {
    float: left;
    width: 100%;
}
.emply-btns > a {
    float: left;
    width: 100%;
    background: #FA5B05;
    font-family: Open Sans;
    font-size: 13px;
    color: #ffffff;
    text-align: center;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    border: 2px solid #FA5B05;
    padding: 11px 0;
    margin-top: 10px;
}
.emply-btns > a.followus {
    background-color: #ffffff;
    border-color: #8b91dd;
    color: #8b91dd;
}
.job-head-wide .share-bar a {
    margin: 0 3px;
    float: none;
    display: inline-block;
}

.job-head-wide .share-bar a i {
    font-size: 15px;
}

.job-single-head3.emplye .job-thumb {
    width: 180px;
}
.job-single-head3.emplye .job-single-info3 {
    vertical-align: middle;
}
.job-listing.wtabs.noimg {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 8px;
}
.job-overview.divide ul {
    margin: 0;
    border: none;
        border-bottom-width: medium;
        border-bottom-style: none;
        border-bottom-color: currentcolor;
    padding: 0;
    border-bottom: 1px solid #e8ecec;
}
.job-overview.divide ul > li {
    float: left;
    width: 33%;
}
.job-overview.divide ul > li span {
    margin-top: 5px;
}
.job-overview.divide {
    margin: 0;
}
.job-single-head.style2 .share-bar {
    padding: 0;
    border: none;
    margin-top: -20px;
}
.job-single-head.style2 .share-bar > a {
    width: 45px;
    height: 45px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    line-height: 41px;
    margin: 0 7px;
}

.job-single-head.style2 .share-bar > a i{font-size: 18px;}

.job-single-head.style2 .emply-btns {
    padding: 0 60px;
    padding-top: 14px;
}
.job-single-head.style2 .emply-btns a {
    padding: 16px 20px;
    font-size: 15px;

    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;

    margin-bottom: 10px;
}

/* Tree Widget */
.widget .tree_widget-sec {
    margin: 0;
}
.tree_widget-sec > ul {
    float: left;
    width: 100%;
    margin: 0;
}
.tree_widget-sec > ul > li {
    float: left;
    width: 100%;
    margin: 0;
}
.tree_widget-sec > ul > li > a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 41px;
}


.tree_widget-sec > ul > li > a:hover {
    color: #8b91dd;
}

.tree_widget-sec > ul > li > a:hover i
{
    color: #8b91dd;
}

.tree_widget-sec > ul > li > a i {
    float: left;
    font-size: 28px;
    line-height: 41px;
    color: #babebe;
    margin-right: 5px;
    width: 35px;
}
.tree_widget-sec > ul > li > ul {
    float: left;
    width: 100%;
    margin: 0;
}
.tree_widget-sec > ul > li > ul {
    float: left;
    width: 100%;
    margin: 0;
    padding-left: 34px;
    margin: 17px 0;
    display: none;
}

.tree_widget-sec > ul > li > ul > li {
    float: left;
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    border-left: 1px solid #e8ecec;
}
.tree_widget-sec > ul > li > ul > li a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    padding: 3px 0;
}
.tree_widget-sec > ul > li > ul > li::before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 1px;
    content: "";
    background: #e8ecec;
}
.tree_widget-sec > ul > li > ul > li:first-child::before {
    top: 0;
}
.tree_widget-sec > ul > li > ul > li:last-child::before {
    bottom: 0;
    top: auto;
}
.tree_widget-sec > ul > li > ul > li:first-child > a {
    padding-top: 0;
    line-height: 2px;
    margin-bottom: 7px;
}
.tree_widget-sec > ul > li > ul > li:last-child > a {
    padding-bottom: 0;
    line-height: 2px;
    margin-top: 7px;
}





.tree_widget-sec > ul > li > ul > ol {
    float: left;
    width: 100%;
    position: relative;
    margin: 0;
    padding-left: 20px;
    border-left: 1px solid #e8ecec;
}
.tree_widget-sec > ul > li > ul > ol a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    padding: 3px 0;
}
.tree_widget-sec > ul > li > ul > ol::before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 1px;
    content: "";
    background: #e8ecec;
}
.tree_widget-sec > ul > li > ul > ol:first-child::before {
    top: 0;
}
.tree_widget-sec > ul > li > ul > ol:last-child::before {
    bottom: 0;
    top: auto;
}
.tree_widget-sec > ul > li > ul > ol:first-child > a {
    padding-top: 0;
    line-height: 2px;
    margin-bottom: 7px;
}
.tree_widget-sec > ul > li > ul > ol:last-child > a {
    padding-bottom: 0;
    line-height: 2px;
    margin-top: 7px;
}







.tree_widget-sec > ul > li.inner-child.active > a {
    color: #8b91dd;
}
.tree_widget-sec > ul > li.inner-child.active > a i {
    color: #8b91dd;
}
.padding-left {
    float: left;
    width: 100%;

}
.profile-title {
    float: left;
    width: 100%;
}
.profile-title > h3 {
    float: left;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid #edeff7;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
        margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 40px;
}
.upload-img-bar {
    float: left;
    width: 100%;
    display: table;
    margin-top: 40px;
    padding-left: 30px;
}
.upload-img-bar > span {
    display: table-cell;
    vertical-align: top;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    position: relative;
    width: 160px;
}
.upload-img-bar > span img {
    float: left;
    width: 100%;
}
.upload-img-bar > span i {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 23px;
    height: 23px;
    background: #FA5B05;
    color: #ffffff;
    font-style: normal;
    text-align: center;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    font-size: 13px;
    line-height: 21px;
    cursor: pointer;
}
.upload-info {
    display: table-cell;
    vertical-align: bottom;
    padding-left: 30px;
}
.upload-info > a {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #8b91dd;
    border: 2px solid #8b91dd;

    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;

    padding: 10px 40px;
}
.upload-info > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    margin-top: 20px;
}
.profile-form-edit {
    float: left;
    width: 100%;
}
.profile-form-edit > form {
    float: left;
    width: 100%;
    padding-left: 30px;
}
.pf-title {
    float: left;
    width: 100%;
    margin-top: 30px;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    margin-bottom: 13px;
}
.pf-field {
    float: left;
    width: 100%;
    position: relative;

}

.job-field .chosen-container{border: none !important;}

.pf-field > input, .pf-field > textarea {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;
    margin-bottom: 20px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 14px 45px 14px 15px;
    background: #ffffff;
    font-family: Open Sans;
    font-size: 13px;
    color: #101010;
    line-height: 24px;
}
.pf-field > textarea {
    color: #888888;
    padding: 30px 30px;
}
.profile-form-edit > form button {
    float: right;
    background: #ffffff;
    border: 2px solid #FA5B05;
    color: #202020;
    font-family: Open Sans;
    font-size: 15px;
    padding: 11px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
}
.social-edit {
    float: left;
    width: 100%;
}
.social-edit > h3 {
    float: left;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid #edeff7;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
    margin-top: 0px;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 40px;
}
.social-edit > form {
    float: left;
    width: 100%;
    padding-left: 30px;
}
.pf-field > i {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 20px;
    color: #8b91dd;
    line-height: 48px;
}
.contact-edit > h3 {
    float: left;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid #edeff7;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
        margin-top: 0px;
    margin-top: 0px;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 40px;
}
.contact-edit {
    float: left;
    width: 100%;
    margin-bottom: 60px;
}
.contact-edit > form {
    float: left;
    width: 100%;
    padding-left: 30px;
}
.contact-edit .srch-lctn {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #8b91dd;
    border: 2px solid #8b91dd;

    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;

    padding: 11px 26px;
    margin-top: 30px;
}
.pf-map {
    float: left;
    width: 100%;
}
.pf-map iframe {
    float: left;
    width: 100%;
    height: 300px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.contact-edit > form button {
    float: right;
    background: #ffffff;
    border: 2px solid #FA5B05;
    color: #202020;
    font-family: Open Sans;
    font-size: 15px;
    padding: 11px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
}
.manage-jobs-sec > h3 {
    float: left;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid #edeff7;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
        margin-top: 0px;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 40px;
}
.manage-jobs-sec {
    float: left;
    width: 100%;
}


.manage-jobs-sec .edu-history-sec,
.manage-jobs-sec .mini-portfolio,
.manage-jobs-sec .progress-sec{
    padding-left: 30px;
}

.manage-jobs-sec .extra-job-info {
    border: 2px solid #e8ecec;
    padding: 20px 30px;
    margin-left: 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.manage-jobs-sec .extra-job-info > span {
    float: left;
    width: 32.334%;
    padding: 0;
    border: none;
    margin: 0;
}
.manage-jobs-sec > table {
    float: left;
    width: calc(100% - 30px);
    margin-top: 50px;
    margin-bottom: 60px;
    margin-left: 30px
}
.manage-jobs-sec > table thead tr td {
    font-size: 15px;
    font-weight: bold;
    color: #FA5B05;
    padding-bottom: 14px;
}
.manage-jobs-sec > table thead {
    border-bottom: 1px solid #e8ecec;
}
.table-list-title > h3 {
    font-size: 16px;
    color: #202020;
    margin: 0;
    display: table;
}
.table-list-title > span {
    font-size: 13px;
    color: #888888;
    float: left;
    margin-top: 0px;
    line-height: 10px;
    margin-top: 8px;
    display: table;
}
.table-list-title > span i {
    float: left;
    font-size: 15px;
    color: #999999;
    margin-right: 5px;
    line-height: 11px;
}
.applied-field {
    float: left;
    font-size: 13px;
    color: #888888;
}
.manage-jobs-sec > table tbody td > span {
    float: left;
    display: table;
    font-size: 13px;
    color: #888888;
}
.manage-jobs-sec > table tbody td {
    vertical-align: middle;
    padding: 29px 0;
    border-bottom: 1px solid #e8ecec;
}
.status {
    color: #7dc246 !important;
}
.status.active {
    color: #8b91dd !important;
}
.action_job {
    margin: 0;
    text-align: center;
}
.action_job > li {
    float: left;
    margin: 0;
    position: relative;
}
.action_job > li > a {
    float: left;
    font-size: 15px;
    color: #9c9c9c;
    padding: 0 6px;
}
.action_job > li span {
    position: absolute;
    left: 50%;
    bottom: 100%;
    opacity: 0;
    visibility: hidden;
    background: #0f36a5;
    color: #ffffff;
    width: 80px;
    font-size: 12px;
    padding: 5px 0;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-left: -40px;
    margin-bottom: 9px;
}
.action_job > li:hover span {
    opacity: 1;
    visibility: visible;
}
.action_job > li span::before {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 10px;
    height: 10px;
    content: "";
    background: #0f36a5;
    margin-left: -5px;
    margin-bottom: -4px;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);


    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;

}

/* Profiles Dropdown */
.btns-profiles-sec {
    float: right;
    position: relative;
}
.btns-profiles-sec > span {
    float: right;
    color: #ffffff;
    font-family: Open Sans;
    font-size: 13px;
    line-height: 50px;
    cursor: pointer;
}
.btns-profiles-sec > span {
    float: right;
    color: #ffffff;
    font-family: Open Sans;
    font-size: 13px;
    line-height: 50px;
}
.btns-profiles-sec > span > img {
    float: left;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-right: 13px;
}
.btns-profiles-sec > span > i {
    float: right;
    line-height: 50px;
    font-size: 10px;
    margin-left: 13PX;
}
.btns-profiles-sec > ul {
    position: absolute;
    right: 0;
    top: 100%;
    margin: 0;
        margin-top: 0px;
    background: #ffffff;

    -webkit-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);


    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 30px;
    width: 240px;
    padding: 20px 3px;
    display: none;
}
.btns-profiles-sec > ul > li {
    float: left;
    width: 100%;
    margin: 4px 0;
}
.btns-profiles-sec > ul > li > a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    line-height: 30px;
    padding-left: 24px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.btns-profiles-sec > ul > li > a:hover {
    padding-left: 30px;
}

.btns-profiles-sec > ul > li > a i {
    float: left;
    font-size: 22px;
    color: #8b91dd;
    margin-right: 3px;
    width: 30px;
    line-height: 30px;
}
.btns-profiles-sec > ul::before {
    position: absolute;
    right: 40px;
    top: -6px;
    width: 14px;
    height: 14px;
    background: #ffffff;
    content: "";

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);


    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;

}
.emply-resume-thumb {
    display: table-cell;
    vertical-align: top;
    width: 100px;
}
.emply-resume-thumb img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.emply-resume-info {
    display: table-cell;
    vertical-align: middle;
    padding-left: 30px;
}
.emply-resume-sec > h3 {
    float: left;
    width: 100%;
    margin-top: 40px;
    border-bottom: 1px solid #edeff7;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
        margin-top: 0px;
    margin-top: 0px;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 40px;
}
.emply-resume-sec {
    float: left;
    width: 100%;
}
.emply-resume-list {
    float: left;
    width: 100%;
    display: table;
    padding: 30px 0;
    border-bottom: 1px solid #edeff7;
    position: relative;
    padding-left: 30px;
}
.emply-resume-info > h3 {
    float: left;
    width: 100%;
    font-size: 18px;
    color: #202020;
    margin: 0;
}
.emply-resume-info > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #202020;
    margin-top: 7px;
}
.emply-resume-info > span i {
    font-style: normal;
    color: #8b91dd;
}
.emply-resume-info > P {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    margin-top: 0px;
    font-size: 13px;
    font-family: Open Sans;
    margin-top: 0;
    line-height: 28px;
}
.emply-resume-info > P i {
    float: none;
    line-height: 13px;
    position: relative;
    top: 2px;
    margin-right: 3px;
    font-size: 17px;
    color: #999999;
}
.action-center {
    position: relative;
    float: left;
}
.action-center > span {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #8b91dd;
    border: 2px solid #8b91dd;
    width: 150px;
    height: 40px;
    padding-left: 30px;
    text-align: left;
    line-height: 35px;
    padding-right: 20px;

    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    -o-border-radius: 21px;
    border-radius: 21px;

    cursor: pointer;
}
.action-center > ul {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 20px;
    background: #ffffff;

    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

    width: 150px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 15px 0;
    display: none;
}
.action-center > ul::before {
    position: absolute;
    right: 40px;
    top: -6px;
    width: 14px;
    height: 14px;
    background: #ffffff;
    content: "";

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);


    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;

}
.action-center > span i {
    font-size: 10px;
    margin-left: 3px;
    float: right;
    line-height: 36px;
}
.action-center > ul > li {
    float: left;
    width: 100%;
    margin: 0;
}
.action-center > ul > li a {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    padding: 5px 20px;
}
.action-resume {
    display: table-cell;
    vertical-align: middle;
}
.del-resume {
    display: table-cell;
    vertical-align: middle;
}
.del-resume > a {
    float: left;
    font-size: 18px;
    color: #999999;
    margin-left: 30px;
}
.coverletter-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(20,31,114,0.6);
    display: none;
    overflow-Y: scroll;
}
.cover-letter {
    position: absolute;
    left: 0;
    top: 110px;
    background: #ffffff;
    width: 600px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    left: 50%;
    margin-left: -300px;
    padding: 30px 30px;
}
.cover-letter > i.close-letter {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    height: 50px;
    background: #FA5B05;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
    font-size: 27px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    cursor: pointer;
}
.cover-letter > h3 {
    float: left;
    width: 100%;
    text-align: center;
    color: #202020;
    font-size: 22px;
    font-weight: bold;
    margin: 0;
}
.cover-letter p {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
        margin-top: 0px;
    margin-top: 17px;
}
.contactus-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(20,31,114,0.6);
    display: none;
    overflow-y: scroll;
}
.contact-popup {
    position: absolute;
    left: 50%;
    top: 50px;
    background: #ffffff;
    width: 460px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-left: -230px;
    padding: 30px 40px;
        padding-bottom: 30px;
    padding-bottom: 50px;
}
.contact-popup > h3 {
    float: left;
    width: 100%;
    font-size: 22px;
    color: #202020;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}
.contact-popup form {
    float: left;
    width: 100%;
}
.contact-popup form > .popup-field {
    float: left;
    width: 100%;
    position: relative;
    border: 2px solid #e8ecec;
    margin-bottom: 15px;
    border-radius: 8px;
}
.contact-popup form > .popup-field > input {
    width: 100%;
    float: left;
    background: no-repeat;
    padding: 16px 15px;
    font-family: Open Sans;
    font-size: 13px;
    margin: 0;

}
.contact-popup form > .popup-field i {
    position: absolute;
    right: 19px;
    top: 0;
    font-size: 23px;
    color: #FA5B05;
    line-height: 50px;
}
.contact-popup form > .popup-field textarea {
    background: none;
    font-family: Open Sans;
    font-size: 13px;
    min-height: 135px;
}
.contact-popup form > button {
    float: left;
    width: 100%;
    font-size: 18px;
    background: #141f72;
    height: 60px;
}
.emply-resume-list.active {
    z-index: 2;
}
.emply-resume-list:last-child {
    margin-bottom: 60px;
    border-bottom-color: #ffffff;
}
.close-contact  {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    height: 50px;
    background: #FA5B05;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
    font-size: 27px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    cursor: pointer;
}
.pkges-table thead {
    background: #0f36a5;
}
.pkges-table thead tr td {
    padding: 19px 24px !important;
        padding-right: 24px;
    color: #ffffff !important;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: normal !important;
    padding-right: 14px !important;
}
.pkges-table tbody tr td {
    border: 1px solid #edeff7;
    padding: 26px 23px;
}
.steps-sec {
    float: left;
    width: 100%;
    padding: 0 90px;
    text-align: center;
}
.step {
    display: inline-block;
    width: 33.334%;
    float: left;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
}
.step  i {
    float: none;
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    text-align: center;
    line-height: 66px;
    font-size: 32px;
    color: #888888;
}
.step span {
    float: left;
    width: 100%;
    font-size: 16px;
    color: #888888;
    margin-top: 9px;
    font-family: Quicksand;
    font-weight: 500;
}
.step::before {
    position: absolute;
    left: 100%;
    top: 45px;
    width: 100%;
    height: 2px;
    background: #e8ecec;
    content: "";
    margin-left: -50%;
}
.step > p {
    position: relative;
    z-index: 1;
    background: #ffffff;
    float: none;
    display: inline-block;
    border: 8px solid #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin: 0;
}
.step:last-child:before{
    display: none;
}
.step.active i {
    background: #8b91dd;
    border-color: #8b91dd;
    color: #ffffff;
}
.step.active span {
    color: #8b91dd;
}
.tags-bar.rt {
    margin: 0;
    padding: 8px;
}
table.alrt-table tbody .action_job {
    float: right;
}
table.alrt-table tbody td > span {
    float: right;
    display: inline-block;
    width: auto;
    margin-right: 20px;
    line-height: 21px;
}
.text-right {
    text-align: right;
}
.change-password {
    float: left;
    width: 100%;
}
.change-password form {
    float: left;
    width: 100%;
    padding-left: 30px;
}
.change-password form input {
    margin-bottom: 0;
}
.change-password form button {
    float: right;
    background: #ffffff;
    border: 2px solid #FA5B05;
    color: #202020;
    font-family: Open Sans;
    font-size: 15px;
    padding: 11px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 30px;
}
.big-icon {
    font-size: 290px;
    color: #EFEFEF;
    text-align: center;
    width: 100%;
    float: left;
    line-height: 413px;
    margin-bottom: 80px;
}
.shortlists {
    display: table-cell;
    vertical-align: middle;
}
.shortlists > a {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #8b91dd;
    border: 2px solid #8b91dd;
    width: 150px;
    height: 40px;
    padding-left: 30px;
    text-align: left;
    line-height: 35px;
    padding-right: 20px;

    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    -o-border-radius: 21px;
    border-radius: 21px;

    cursor: pointer;
    margin-right: 40px;
}
.shortlists > a i {
    float: right;
    line-height: 33px;
    font-size: 13px;
}
header.gradient{
    background: rgb(139,145,221);
    background: -moz-linear-gradient(45deg,  rgba(139,145,221,1) 0%, rgba(16,25,93,1) 71%, rgba(16,25,93,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(139,145,221,1)), color-stop(71%,rgba(16,25,93,1)), color-stop(100%,rgba(16,25,93,1)));
    background: -webkit-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    padding-bottom: 23px;
}
#map_div {
    height: 450px;
    width: 100%;
}
/* Custom Tooltip */
.custom-tooltip > div:nth-child(4) {
    border: medium none !important;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;

    position: relative;
}
.custom-tooltip > div:nth-child(4) > div > div > div {
    border: medium none !important;

    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    border-radius: 6px !important;

}
.custom-tooltip > div:nth-child(4) > div > div > div:nth-child(2) {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
    border: medium none !important;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;


    -webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    -ms-border-radius: 6px !important;
    -o-border-radius: 6px !important;
    border-radius: 6px !important;

}
.custom-tooltip > div:nth-child(4) > div > div > div:nth-child(3) > div {
    display: none;
}
.infowindow .popular-listing {
    opacity: 1;
}
.infowindow .popular-listing::before {
    height: 100%;
    opacity: 1;
}
.infowindow .popular-listing .listing-features {
    opacity: 1;
}
.infowindow .popular-listing .pl-title-sec {
    opacity: 1;
}
.infowindow {
    color: transparent;
}
.infowindow > span {
    bottom: -4px;
    float: left;
    position: relative;
}
.gm-style .gm-style-iw {
    width: 256px !important;
}
.infowindow .popular-listing {
    margin-bottom: -12px;
    margin-right: 0;
    opacity: 1;
}
.custom-tooltip > div:nth-child(4) > div > div:last-child img {
    display: none;
}
.custom-tooltip > div:nth-child(4) > div > div:last-child {
    color: #333333;
    content: "x";
    font-size: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 13px;
    width: 20px !important;
}
.custom-tooltip > div:nth-child(4) > div > div:last-child::before {
    background: #333333 none repeat scroll 0 0;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    color: #ffffff;
    content: "x";
    font-family: Nunito;
    font-size: 15px;
    font-weight: bold;
    height: 100%;
    left: 0;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}
.custom-tooltip > div:nth-child(4) > div {
    overflow: hidden;
    position: relative;
    width: 297px !important;
}
.custom-tooltip {
    margin-left: 8px;
    margin-top: 55px;
}
.infowindow .map-can {
    float: left;
    width: 100%;
    display: table;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0 28px;
}
.map-candimg {
    display: table-cell;
    vertical-align: middle;
    width: 60px;
}
.map-candimg img {
    float: left;
    width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    height: 60px;
    width: 60px;
}
.infowindow .emply-resume-info > span {
    margin: 0;
    font-size: 13px;
}
.infowindow .emply-resume-info > P {
    line-height: 20px;
}
.map-can h3 {
    font-size: 15px;
    margin-bottom: 1px;
}
.emply-resume-list.round .emply-resume-thumb img {

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.emply-resume-list.round .emply-resume-thumb {
    width: 90px;
}
.emply-resume-sec .pagination {
    margin-bottom: 60px;
}
.emply-resume-list.square {
    border: 2px solid #ffffff;
    padding: 30px 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.emply-resume-list.square:hover {
    border-color: #e8ecec;
}
.emply-resume-list.square .shortlists > a {
    margin: 0;
}
.inner-header .container {
    position: relative;
    z-index: 1;
}
.skills-btn {
    float: left;
    width: 100%;margin-bottom: 15px;
}
.skills-btn a {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #ffffff;
    border: 2px solid #ffffff;

    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    -o-border-radius: 21px;
    border-radius: 21px;

    padding: 6px 20px;
    margin-right: 12px;
}
.action-inner {
    float: right;
    text-align: right;
    width: 100%;
}
.action-inner a {
    float: right;
    font-family: Open Sans;
    font-size: 13px;
    color: #ffffff;
    margin-left: 30px;
}
.action-inner a i {
    float: left;
    font-size: 25px;
    line-height: 17px;
    margin-right: 10px;
}

/* Canditate Single User */
.cand-single-user {
    float: left;
    width: 100%;
    display: table;
}
.cand-single-user > div {
    display: table-cell;
    width: 33.334%;
    float: none;
}
.cand-single-user .share-bar.circle {
    vertical-align: middle;
    border: none;
    padding: 0;
}
.cand-single-user .share-bar.circle a {

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    width: 45px;
    height: 45px;
    line-height: 41px;
}
.can-detail-s {
    vertical-align: top;
    text-align: center;
}
.cst {
    float: none;
    display: inline-block;
    margin-top: -97px;
    border: 2px solid #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    width: 175px;
    height: 175px;
    padding: 17px;
}
.cst img {
    float: left;
    width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.can-detail-s > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 22px;
    color: #202020;
    font-weight: bold;
    margin: 0;
}
.can-detail-s span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #202020;
    margin-top: 14px;
}
.can-detail-s span i {
    font-style: normal;
    color: #8b91dd;
}
.can-detail-s > p {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
    font-size: 13px;
    color: #888888;
    line-height: 13px;
    margin-top: 10px;
}
.can-detail-s > p i {
    margin-right: 5px;
}
.download-cv {
    vertical-align: middle;
    text-align: right;
}
.download-cv a {
    float: right;
    background: #ffffff;
    border: 2px solid #8b91dd;
    color: #8b91dd;
    font-family: Open Sans;
    font-size: 13px;
    padding: 14px 30px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.download-cv a:hover{background-color: #8b91dd; color: #FFF;}

.download-cv a i {
    margin-left: 5px;
}
.cand-extralink {
    float: left;
    width: 100%;
    text-align: center;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 40px;
}
.cand-extralink > li {
    float: none;
    display: inline-block;
    margin: 0 10px;
}
.cand-extralink > li a {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #888888;
    padding: 20px 10px;
    position: relative;
    margin-bottom: -5px;
}
.cand-extralink > li:hover a {
    color: #8b91dd;
    border-color: #8b91dd;
}
.cand-details-sec {
    float: left;
    width: 100%;
}
.cand-details {
    float: left;
    width: 100%;
}
.cand-details h2 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 20px;
    font-weight: bold;
    color: #202020;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.cand-details > p {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 20px;
}
.edu-history-sec {
    float: left;
    width: 100%;

}
.edu-history {
    float: left;
    width: 100%;
    display: table;
    margin-bottom: 20px;
    position: relative;
}
.edu-history > i {
    display: table-cell;
    vertical-align: top;
    width: 70px;
    font-size: 50px;
    color: #FA5B05;
    line-height: 60px;
}
.edu-hisinfo {
    display: table-cell;
    vertical-align: top;
}
.edu-hisinfo > h3 {
    float: left;
    width: 100%;
    font-size: 16px;
    color: #8b91dd;
    margin: 0;
        margin-top: 0px;
    margin-top: 10px;
}
.edu-hisinfo > i {
    float: left;
    width: 100%;
    font-style: normal;
    font-size: 13px;
    color: #888888;
    margin-top: 7px;
}
.edu-hisinfo > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #202020;
    margin-top: 8px;
}
.edu-hisinfo > span i {
    font-size: 13px;
    color: #888888;
    font-style: normal;
    margin-left: 12px;
}
.edu-hisinfo > p {
    float: left;
    width: 100%;
    margin: 0;
    font-size: 13px;
    color: #888888;
    font-style: normal;
    line-height: 24px;
    margin-top: 10px;
}
.edu-history.style2 {
    margin: 0;
    padding-bottom: 20px;
    position: relative;
    padding-left: 40px;
    margin-bottom: 24px;
    padding-bottom: 0;
}
.edu-history.style2 > i {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #8b91dd;
    content: "";

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.edu-history.style2 .edu-hisinfo > h3 {
    margin: 0;
}
.edu-history.style2::before {
    position: absolute;
    left: 7px;
    top: 20px;
    width: 2px;
    height: 100%;
    content: "";
    background: #e8ecec;
}
.edu-history.style2:last-child::before {
    display: none;
}
.edu-history.style2 .edu-hisinfo > h3 span {
    color: #202020;
    margin-left: 10px;
}
.mini-portfolio {
    float: left;
    width: 100%;
}
.mportolio {
    float: left;
    width: 100%;
    position: relative;
    z-index: 0;
    margin-bottom: 30px;
}
.mp-row {
    margin: 0 -15px;
}
.mp-col {
    float: left;
    width: 25%;
    padding: 0 15px;
}
.mportolio img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.mportolio > a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    text-align: center;

    background: rgb(139,145,221);
    background: -moz-linear-gradient(45deg,  rgba(139,145,221,1) 0%, rgba(16,25,93,1) 71%, rgba(16,25,93,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(139,145,221,1)), color-stop(71%,rgba(16,25,93,1)), color-stop(100%,rgba(16,25,93,1)));
    background: -webkit-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    background: linear-gradient(45deg,  rgba(139,145,221,1) 0%,rgba(16,25,93,1) 71%,rgba(16,25,93,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    opacity: 0;
}
.mportolio:hover > a {
    opacity: 0.9;
}
.mportolio > a i {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    top: 50%;
    color: #ffffff;
    font-size: 30px;
    line-height: 10px;
    margin-top: -5px;
}
.progress-sec {
    float: left;
    width: 100%;
}
.progress-sec {
    float: left;
    width: 100%;
    margin-bottom: 31px;
}
.progress-sec > span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 15px;
    color: #202020;
    margin-bottom: 9px;
}
.progressbar {
    float: left;
    width: 100%;
    position: relative;
    height: 6px;
    background: #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #f64a84;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.progress span {
    position: absolute;
    right: -14px;
    top: -23px;
    font-size: 13px;
    color: #202020;
}
.progress-sec:last-child {
    margin: 0;
}
.companyies-fol-sec {
    float: left;
    width: 100%;
}
.cmp-follow {
    float: left;
    width: 100%;
}
.cmp-follow a {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}
.cmp-follow a img {
    float: none;
    display: inline-block;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    border: 2px solid #e8ecec;
    margin-bottom: 10px;
}
.cmp-follow a span {
    width: 100%;
    float: left;
}
.cand-details-sec .job-overview {
    margin-top: 10px;
    margin-bottom: 20px;
}
.cand-details-sec .quick-form-job form {
    border-width: 2px;
}
.text-socail {
    float: left;
    width: 100%;
}
.text-socail > a {
    float: left;
    color: #ffffff;
    font-size: 18px;
    margin-right: 17px;
    margin-top: 13px;
}
.action-inner .download-cv {
    float: right;
}
.action-inner .download-cv a {
    background: none;
    color: #ffffff;
    border-color: #ffffff;

    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    border-radius: 40px;

}
.action-inner.style2 > a {
    margin-top: 17px;
}
.skills-badge {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
}
.skills-badge span {
    float: none;
    display: inline-block;
    width: auto;
    margin: 0;
    background: #edeff7;
    color: #888888;
    font-family: Open Sans;
    font-size: 13px;
    margin: 0 6px;
        margin-top: 0px;
    padding: 6px 20px;

    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    -o-border-radius: 21px;
    border-radius: 21px;

    margin-top: 14px;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.skills-badge span:hover {

    background: #e1e4ec;

}

.job-overview.style2 {
    margin: 0;
    border-top: 1px solid #e8ecec;
    border-bottom: 1px solid #e8ecec;
    padding: 20px 0;
    margin-bottom: 20px;
}
.job-overview.style2 ul {
    padding: 0;
    border: none;
    margin: 0;
}
.job-overview.style2 ul > li {
    float: left;
    width: 33.334%;
}
.progress-sec.style2 {
    display: table;
    padding-right: 240px;
}
.progress-sec.style2 > span {
    float: none;
    display: table-cell;
    vertical-align: middle;
    width: 170px;
    line-height: 10px;
}
.progress-sec.style2 .progressbar {
    background: none;
    height: auto;
    margin-top: 3px;
}
.progress-sec.style2 .progressbar > i {
    float: left;
    width: 19px;
    height: 19px;
    background: #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    border: 2px solid #e6e8ef;
    margin-right: 12px;
}
.progress-sec.style2 .progressbar .progress {
    background: none;
}
.progress-sec.style2 .progressbar .progress > i {
    float: left;
    width: 19px;
    height: 19px;
    background: #e9477c;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-right: 12px;
}
.progress-sec.style2 > p {
    float: none;
    display: table-cell;
    text-align: left;
    font-size: 13px;
    color: #202020;
    line-height: 0px;
    position: relative;
    top: -3px;
}
.job-overview.style3 {
    margin: 0;
    padding: 0 20px;
}
.job-overview.style3 ul {
    margin: 0;
    border: none;
    border-top: 1px solid #e8ecec;

    -webkit-border-radius:  0 0;
    -moz-border-radius: 0 0;
    -ms-border-radius: 0 0;
    -o-border-radius: 0 0;
    border-radius: 0 0;

    margin-bottom: 20px;
}
.job-overview.style3 ul li {
    float: left;
    width: 100%;
    padding-top: 15px; padding-bottom: 15px;
    margin: 0;
    padding-left: 42px;
}
.job-overview.style3 ul li h3 {
    float: left;
    width: 100%;
    font-size: 15px;
    color: #888888;
}
.job-overview.style3 ul li i {
    font-size: 23px;
    line-height: 37px;
    left: 0;
}
.job-overview > a {
    float: left;
    width: 100%;
    height: 50px;
    font-size: 13px;
    background: #FA5B05;
    text-align: center;
    line-height: 50px;
    color: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-bottom: 20px;
}
.job-overview > a.contct-user {
    background: #8b91dd;
}
.skill-perc {
    float: left;
    width: 100%;
}
.skill-perc > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 20px;
    color: #202020;
    font-weight: bold;
    margin: 0;
}
.skill-perc > p {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin-top: 10px;
    letter-spacing: 0px;
}
.skills-bar {
    float: left;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    background: #ffffff;
    padding: 16px;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    position: relative;
}
.skills-bar > span {
    position: absolute;
    right: 28px;
    top: 53px;
    font-size: 17px;
    font-family: Quicksand;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
}
.manage-jobs-sec .cat-sec {
    margin-top: 40px;
}
.manage-jobs-sec .cat-sec:last-child {
    margin: 0;
}
.my-profiles-sec {
    float: right;
}
.my-profiles-sec > span {
    float: left;
    font-size: 13px;
    color: #ffffff;
    font-family: Open Sans;
    line-height: 50px;
    cursor: pointer;
}
.my-profiles-sec > span > img {
    float: left;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    border: 2px solid #ffffff;
    margin-right: 18px;
}
.my-profiles-sec > span > i {
    float: right;
    line-height: 50px;
    font-size: 20px;
    margin-left: 12px;
}
.profile-sidebar {
    position: fixed;
    right: -316px;
    top: 0;
    z-index: 22;
    background: #ffffff;
    width: 316px !important;

    -webkit-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 40px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 40px rgba(0,0,0,0.1);

    overflow-y: scroll;
    height: 100%;
    opacity: 0;
    visibility: hidden;
}
.profile-sidebar .close-profile {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 32px;
    height: 32px;
    background: #FA5B05;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    text-align: center;
    line-height: 32px;
    color: #ffffff;
    cursor: pointer;
}
.profile-sidebar .can-detail-s {
    margin: 0;
    float: left;
    width: 100%;
    padding: 0 20px;
}
.profile-sidebar .can-detail-s .cst {
    margin: 0;
        margin-top: 0px;
    margin-top: 20px;
}
.profile-sidebar .can-detail-s .cst img {
    width: 114px;
    height: 114px;
    border: 2px solid #ffffff;

    -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

}
.profile-sidebar .tree_widget-sec {
    padding: 0 40px;
    margin-top: 30px;
    float: left;
    width: 100%;
    margin-bottom: 40px;
}
.profile-sidebar .tree_widget-sec > ul {
    border-top: 1px solid #e8ecec;
    padding-top: 22px;
}
.profile-sidebar.active {
    right: -16px;
    opacity: 1;
    visibility: visible;
}
.wishlist-dropsec {
    float: right;
    margin-right: 20px;
    position: relative;
}
.wishlist-dropsec > span {
    float: left;
    width: 46px;
    height: 46px;
    border: 2px solid #ffffff;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    text-align: center;
    line-height: 42px;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    margin-top: 3px;
}
.wishlist-dropsec > span strong {
    position: absolute;
    right: -1px;
    top: -5px;
    font-size: 10px;
    font-weight: normal;
    background: #8b91dd;
    height: 16px;
    line-height: 14px;
    width: 16px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.wishlist-dropdown {
    position: absolute;
    right: -30px;
    width: 360px;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    top: 100%;
    margin-top: 30px;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    padding: 20px 15px;
    display: none;
}
.wishlist-dropdown > ul {
    height: 243px;
    float: left;
    width: 100%;
    margin: 0;
}
.wishlist-dropdown > ul li {
    margin: 0;
}
.wishlist-dropdown > ul li .job-listing {
    padding-right: 0;
    border: none;
    padding-bottom: 0;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

}
.wishlist-dropdown > ul li .job-listing h3 {
    font-size: 15px;
}
.wishlist-dropdown .c-logo {
    width: 110px;
    text-align: left;
}
.wishlist-dropdown::before {
    position: absolute;
    right: 43px;
    top: -6px;
    width: 15px;
    height: 15px;
    content: "";
    z-index: 1;
    background: #ffffff;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);


    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;

}
.view-resumesec{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(20,21,114,0.6);
    display: none;
    overflow-y: scroll;
}
.view-resumes {
    width: 700px;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    position: absolute;
    left: 50%;
    margin-left: -350px;
    margin-top: 90px;
    padding: 0 20px;
    padding-bottom: 30px;
}
.view-resumes > .close-resume-popup {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    height: 50px;
    background: #FA5B05;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 22px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    cursor: pointer;
}
.view-resumes > h3 {
    float: left;
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    color: #202020;
    text-align: center;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.job-listing.wtabs .date-resume {
    float: right;
    font-size: 13px;
    color: #888888;
    margin-top: 30px;
}
.view-resumes .job-listing.wtabs {
    border: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
}
.follow-companiesec{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    background: rgba(20,21,114,0.6);
    display: none;
}
.follow-companies {
    width: 700px;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    position: absolute;
    left: 50%;
    margin-left: -350px;
    margin-top: 90px;
    padding: 0 20px;
    padding-bottom: 30px;
    padding-right: 30px;
}
.follow-companies > .close-follow-company {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 50px;
    height: 50px;
    background: #FA5B05;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    font-size: 22px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    cursor: pointer;
}
.follow-companies > h3 {
    float: left;
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    color: #202020;
    text-align: center;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 20px;
}
.follow-companies > ul {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
}
.follow-companies > ul li {
    margin: 0;
}
.follow-companies > ul li .job-listing.wtabs {
    margin: 0;
    border: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    padding: 0;
    margin: 16px 0;
    padding-right: 30px;
}
.follow-companies > ul li .job-listing.wtabs .go-unfollow {
    float: right;
    background: #ffffff;
    font-family: Open Sans;
    font-size: 13px;
    color: #8b91dd;
    border: 2px solid #8b91dd;

    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    -ms-border-radius: 21px;
    -o-border-radius: 21px;
    border-radius: 21px;

    padding: 9px 36px;
    margin-top: 10px;
}
.follow-companies > ul li .job-listing.wtabs .job-lctn {
    padding-top: 0px;
}
.follow-companies #scrollbar {
    height: 333px;
}
.upload-img-bar > span.round {

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.upload-img-bar > span.round img {
    float: left;
    width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.upload-img-bar > span.round i {
    right: 10px;
    top: 10px;
}
.pf-field.no-margin .tags-bar {
    margin: 0;
    padding: 8px;
}
.border-title {
    float: left;
    width: 100%;
    border-bottom: 1px solid #edeff7;
    padding-bottom: 20px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 20px;
}
.border-title > h3 {
    float: left;
    font-family: Quicksand;
    font-size: 20px;
    font-weight: bold;
    color: #202020;
    margin: 0;
    padding-left: 30px;
}
.border-title > a {
    float: right;
    line-height: 27px;
    font-family: Open Sans;
    font-size: 13px;
    color: #FA5B05;
}
.border-title > a i {
    float: left;
    width: 27px;
    height: 27px;
    background: #FA5B05;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    color: #ffffff;
    text-align: center;
    line-height: 27px;
    font-size: 13px;
    margin-right: 8px;
}
.editdel {
    position: absolute;
    right: 0;
    top: 10px;
}
.editdel a {
    float: left;
    font-size: 22px;
    color: #8b91dd;
    margin-left: 8px;
}
.edu-history.style2 .editdel {
    top: 0;
}
.mini-portfolio .editdel {
    position: static;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -16px;
}
.mini-portfolio .editdel a {
    float: none;
    display: inline-block;
}
.progress-sec.with-edit {
    padding-right: 80px;
    position: relative;
    padding-left: 0px;
}
.progress-sec.with-edit .editdel {
    top: 14px;
}
.border-title > a.cancel i {
    background: #8b91dd;
}
.border-title > a.cancel {
    color: #8b91dd;
}
.resumeadd-form {
    float: left;
    width: 100%;
    padding-left: 30px;
}
.resumeadd-form button {
    float: right;
    border: 2px solid #FA5B05;
    color: #ffffff;
    font-family: Open Sans;
    font-size: 15px;
    padding: 12px 41px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
    background: #f43574;
}
.resumeadd-form .remember-label {
    float: right;
    margin-top: 70px;
}
input[type="file"] {

}
.custom-file-upload {
}
.custom-file-upload
{


    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    line-height: 40px;
    text-align: center;
}
.custom-file-upload i
{
  color: #8b91dd;
    font-size: 32px;
  float: left;
  width: 100%;
  margin-top: 42px;
}
.upload-portfolio {
    float: left;
    width: 100%;
    display: table;
}
.uploadbox {
    display: table-cell;
    vertical-align: middle;
    border: 2px dashed #e6e8ef;
    width: 200px;
    height: 150px;
    float: left;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    position: relative;
}
.custom-file-upload::before {
    display: none;
}
.custom-file-upload span {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #8b91dd;
    line-height: 25px;
}
.uploadfield {
    float: left;
    width: 400px;
    margin-left: 30px;
}
.uploadbutton {
    float: left;
    margin-left: 30px;
    margin-top: 50px;
}
.job-list-del {
    float: right;
}
.job-list-del a {
    float: left;
    line-height: 70px;
    font-size: 20px;
    color: #B9B9B9;
}
.manage-jobs-sec .job-listing.wtabs {
    margin: 0;
    border: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

    padding-top: 25px;
    padding-bottom: 25px;
}
.manage-jobs-sec .job-listing.wtabs:hover {
    background: #f8f9fc;
}
.table-list-title > i {
    font-family: Open Sans;
    font-size: 13px;
    color: #8b91dd;
    font-style: normal;
    margin-bottom: 1px;
    float: left;
}
.coverletter-sec {
    float: left;
    width: 100%;
}
.coverletter-sec > form {
    float: left;
    width: 100%;
    padding-left: 30px
}
.coverletter-sec > form button {
    float: right;
    background: #ffffff;
    border: 2px solid #FA5B05;
    color: #202020;
    font-family: Open Sans;
    font-size: 15px;
    padding: 11px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
}
.bloglist-sec {
    float: left;
    width: 100%;
    margin-top: -40px;
}
.blogpost {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.blog-posthumb {
    float: left;
    width: 100%;
}
.blog-posthumb > a {
    float: left;
    width: 100%;
    overflow: hidden;
    background-color: #202020;
    border-radius: 8px;
}
.blog-posthumb > a img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    -webkit-transform: scale(1.1) translate(-20px);
    -moz-transform: scale(1.1) translate(-20px);
    -ms-transform: scale(1.1) translate(-20px);
    -o-transform: scale(1.1) translate(-20px);
    transform: scale(1.1) translate(-20px);
}
.blog-posthumb > a:hover img {
    -webkit-transform: translate(0px) scale(1.1);
    -moz-transform: translate(0px) scale(1.1);
    -ms-transform: translate(0px) scale(1.1);
    -o-transform: translate(0px) scale(1.1);
    transform: translate(0px) scale(1.1);

    opacity: 0.8;
}
.blog-postdetail {
    float: left;
    width: 100%;
    margin-top: 26px;
}
.post-metas {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}
.post-metas > li {
    float: left;
    margin: 0;
        margin-right: 0px;
    margin-right: 30px;
}
.modrn-joblist.np {
    padding: 0;
}
.post-metas > li a {
    float: left;
    font-family: Open Sans;
    font-size: 13px;
    color: #a7a7a7;
}
.post-metas > li a i {
    float: left;
    line-height: 17px;
    font-size: 20px;
    margin-right: 5px;
}
.post-metas > li a.metascomment {
    color: #8b91dd;
}
.post-metas > li a.metascomment i{
    color: #a7a7a7;
}
.blog-postdetail > h3 {
    float: left;
    width: 100%;
    margin: 0;
    font-family: Open Sans;
    font-size: 20px;
    color: #202020;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    margin-top: 14px;
    margin-bottom: 10px;
}
.blog-postdetail > p {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
    margin-top: 4px;
}
.bbutton {
    float: left;
    font-family: Open Sans;
    font-size: 15px;
    color: #FA5B05;
    border: 2px solid #FA5B05;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 12px 30px;
    margin-top: 24px;
}
.no-margin {
    margin: 0 !important;
}
.widget > h3 {
    float: left;
    width: 100%;
    margin: 0;
    font-size: 18px;
    color: #202020;
    font-weight: 500;
}
.sidebar-links {
    float: left;
    width: 100%;
}
.sidebar-links a {
    float: left;
    width: 100%;
    position: relative;
    font-size: 13px;
    color: #888888;
    margin: 9px 0;
}
.sidebar-links a i {
    float: left;
    font-size: 9px;
    line-height: 18px;
    width: 18px;
}
.mini-blog {
    float: left;
    width: 100%;
    display: table;
    margin: 10px 0;
    margin-bottom: 18px;
}
.post_widget {
    float: left;
    width: 100%;
}
.mini-blog > span {
    display: table-cell;
    vertical-align: top;
    width: 74px;
}
.mini-blog > span a {
    float: left;
    width: 100%;
}
.mini-blog > span a img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.mb-info {
    display: table-cell;
    vertical-align: top;
    padding-left: 15px;
}
.mb-info > h3 {
    float: left;
    width: 100%;
    font-family: Open sans;
    font-size: 13px;
    color: #202020;
    line-height: 20px;
    margin: 0;
}
.mb-info > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #888888;
    margin-top: 5px;
}
.tags_widget {
    float: left;
    width: 100%;
}
.tags_widget > a {
    float: left;
    font-family: Open sans;
    font-size: 13px;
    color: #888888;
    border: 1px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 6px 14px;
    margin-right: 11px;
    margin-bottom: 11px;
}
.blogpost.style2 {
    display: table;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8ecec;
}
.blogpost.style2 .blog-posthumb {
    width: 322px;
    display: table-cell;
    float: none;
    vertical-align: top;
}
.blogpost.style2 .blog-postdetail {
    float: none;
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding-left: 40px;
}
.blogpost.style2 .blog-postdetail .bbutton {
    border: none;
    padding: 0;
    margin-top: 20px;
}
.blogpost.style2 .blog-postdetail .bbutton i {
    font-size: 17px;
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 4px;
}
footer .footer-title {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    color: #ffffff;
    margin: 0;
        margin-bottom: 0px;
    margin-bottom: 30px;
}
.blog-single {
    float: left;
    width: 100%;
}
.bs-thumb {
    float: left;
    width: 100%;
    margin-bottom: 40px;
}
.bs-thumb > img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.post-metas > li a img {
    float: left;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    margin-right: 15px;
    margin-top: -13px;
}
.blog-single > h2 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 24px;
    margin: 20px 0;
    color: #202020;
}
.blog-single > p {
    float: left;
    width: 100%;
    /* font-size: 13px; */
    font-size: 16px;
    color: #888888;
    /* line-height: 24px; */
    line-height: 28px;
    margin-bottom: 20px;
}
.blog-single > h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    /* font-size: 15px; */
    font-size: 20px;
    color: #202020;
    margin-bottom: 20px;
}
blockquote {
    float: left;
    width: 100%;
    background: #f5f7fa;
    padding: 25px 40px;
    margin-bottom: 23px;
}
blockquote > p {
    float: left;
    width: 100%;
    margin: 0;
    font-family: Open Sans;
    font-size: 13px;
    font-style: italic;
    color: #888888;
    line-height: 16px;
}
blockquote > p i {
    font-size: 60px;
    line-height: 48px;
    display: inherit;
    width: 30px;
    float: left;
    margin-right: 10px;
}
blockquote > p span i {
    float: none;
    line-height: 0px;
    position: relative;
    top: 30px;
    margin-left: 13px;
}
blockquote > strong {
    float: left;
    font-weight: normal;
    font-size: 13px;
    font-style: italic;
    color: #202020;
    margin: 0;
        margin-top: 0px;
    margin-top: 18px;
}
.tags-share {
    float: left;
    width: 100%;
    border-top: 1px solid #e8ecec;
    border-bottom: 1px solid #e8ecec;
    padding: 20px 0;
}
.tags-share .tags_widget {
    float: left;
    width: 50%;
    margin-top: 6px;
}
.tags-share .tags_widget span {
    float: left;
    font-size: 15px;
    color: #202020;
    margin-right: 20px;
    line-height: 28px;
}
.tags-share .share-bar {
    padding: 0;
    border: none;
    float: right;
    width: 50%;
}
.tags-share .share-bar a {
    float: right;
    width: 40px;
    height: 40px;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    line-height: 36px;
    margin: 0;
        margin-left: 0px;
    margin-left: 20px;
}
.tags-share .share-bar span {
    float: right;
    margin: 0;
}
.tags-share .tags_widget a {
    margin-bottom: 0;
}
.post-navigation {
    float: left;
    width: 100%;
    border-bottom: 1px solid #e8ecec;
    padding-bottom: 30px;
    padding-top: 30px;
}
.post-hist {
    float: left;
    width: 50%;
}
.post-hist a {
    float: left;
    display: table;
    width: 100%;
}
.post-hist a > i {
    display: table-cell;
    vertical-align: top;
    width: 53px;
    height: 53px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    text-align: center;
    line-height: 49px;
    font-size: 20px;
    color: #202020;
}
.post-hist a .post-histext {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    font-family: Open Sans;
    font-size: 15px;
    color: #202020;
}
.post-hist a .post-histext i {
    float: left;
    width: 100%;
    font-style: normal;
    font-size: 13px;
    color: #888888;
    margin-top: 4px;
}
.post-hist.next a {
    text-align: right;
}
.post-hist.next a .post-histext {
    padding-left: 0;
    padding-right: 20px;
}

.comment-sec {
    width: 100%;
    float: left;
    margin-top: 30px;
}
.comment-sec > h3 {
    float: left;
    width: 100%;
    font-size: 20px;
    font-family: Open Sans;
    color: #202020;
}
.comment-sec > ul {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
}
.comment-sec > ul > li {
    float: left;
    width: 100%;
    margin: 0;
}
.comment {
    float: left;
    width: 100%;
    padding: 30px 0;
    display: table;
}
.comment-avatar {
    display: table-cell;
    vertical-align: top;
    width: 80px;
}
.comment-avatar img {
    float: left;
    width: 100%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

}
.comment-detail {
    display: table-cell;
    vertical-align: top;
    position:relative;
    padding-left: 30px;
}
.comment-detail > h3 {
    float: left;
    width: 50%;
    font-family: Open Sans;
    font-size: 15px;
    color: #202020;
    margin: 0;
}
.comment-detail > p {
    float: left;
    width: 100%;
    margin: 0;
    font-size: 13px;
    line-height: 24px;
    color: #888888;
    margin-top: 11px;
}
.date-comment {
    float: right;
    width: 50%;
    text-align: right;
}
.date-comment a {
    float: right;
    font-size: 13px;
    color: #8b91dd;
}
.date-comment a i {
    font-size: 18px;
    line-height: 18px;
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 7px;
}
.comment-detail > a {
    float: left;
    font-size: 13px;
    color: #FA5B05;
    margin-top: 13px;
}
.comment-detail > a i {
    float: left;
    font-size: 20px;
    line-height: 16px;
    margin-right: 6px;
}
.comment-sec > ul > li .comment-child {
    padding-left: 80px;
    float: left;
    width: 100%;
    border-top: 1px solid #e8ecec;
    border-bottom: 1px solid #e8ecec;
}
.comment-sec > ul > li:last-child .comment {
    border: none;
}
.commentform-sec {
    float: left;
    width: 100%;
    margin-top: 30px;
}
.commentform-sec > h3{
    float: left;
    width: 100%;
    font-size: 20px;
    font-family: Open Sans;
    color: #202020;
}
.commentform-sec > form {
    float: left;
    width: 100%;
    padding-right: 260px;
}
.commentform-sec .pf-title {
    margin-top: 0;
}
.commentform-sec > form button {
    float: left;
    font-size: 15px;
    background: #FA5B05;
    color: #ffffff;
    padding: 15px 28px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 10px;
}
.commentform-sec form textarea {
    min-height: 150px;
}
.photo-widget {
    float: left;
    width: 100%;
}
.photo-widget .row {
    margin: 0 -2px;
}
.photo-widget .row > div {
    padding: 0 2px;
}
.photo-widget a {
    float: left;
    width: 100%;
    margin-bottom: 4px;
}
.photo-widget a img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
header.white > .menu-sec {
    margin-bottom: 0;
}
header.white .menu-sec nav > ul > li > a {
    color: #202020;
}
header.white .account-btns > li a {
    color: #202020;
}
header.white .menu-sec nav > ul > li {
    padding-bottom: 27px;
}
header.white .menu-sec nav > ul > li > ul {
    margin-top: 3px;
}
.inner-title2 {
    float: none;
    width: 50%;
    padding: 60px 0px;
    display: table-cell;
    vertical-align: middle;
}
.inner-title2 > h3 {
    float: left;
    width: 100%;
    font-family: Quicksand;
    font-size: 30px;
    color: #202020;
    font-weight: bold;
    margin: 0;
}
.inner-title2 > span {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #808080;
    margin-top: 7px;
}
.breadcrumbs {
    float: right;
    margin: 0;
}
.breadcrumbs > li {
    position: relative;
    padding-left: 30px;
    margin: 0;
    float: left;
}
.breadcrumbs > li a {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #808080;
}
.breadcrumbs > li::before {
    position: absolute;
    left: 9px;
    top: 11px;
    width: 11px;
    height: 1px;
    background: #888888;
    content: "";
}
.breadcrumbs > li:first-child::before {
    display: none;
}
.inner2 {
    float: left;
    width: 100%;
    display: table;
}
.page-breacrumbs {
    display: table-cell;
    vertical-align: middle;
}
header.white {
    float: left;
    width: 100%;
    position: relative;

    -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
    -ms-box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
    -o-box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.15);

}
.about-us {
    float: left;
    width: 100%;
}
.about-us  h3 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 20px;
    color: #202020;
}
.about-us p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
}
.about-us img {
    float: left;
    width: 100%;
    margin-bottom: 40px;
}
.about-us .tags-share {
    float: left;
    width: auto;
    border: none;
    padding: 0;
}
.about-us .tags-share .share-bar {
    float: left;
    width: auto;
}
.our-services {
    float: left;
    width: 100%;
    margin-bottom: -60px;
}
.service {
    float: left;
    width: 100%;
    display: table;
    margin-bottom: 60px;
}
.service > i {
    vertical-align: top;
    width: 80px;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    float: left;
    height: 80px;
    text-align: center;
    line-height: 76px;
    font-size: 40px;
    color: #888888;
    margin-right: 25px;
}
.service-info {
    display: table-cell;
    vertical-align: top;
}
.service-info > h3 {
    float: left;
    width: 100%;
    font-size: 15px;
    font-family: Open Sans;
    margin: 0;
}
.service-info > p {
    float: left;
    width: 100%;
    font-size: 13px;
    line-height: 24px;
    color: #888888;
    margin: 0;
    margin-top: 11px;
}
.our-services h2 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 20px;
    color: #202020;
    margin-bottom: 40px;
}
.contact-form > h3 {
    float: left;
    width: 100%;
    font-size: 20px;
    color: #202020;
    font-family: Open Sans;
}
.contact-form > form {
    float: left;
    width: 100%;
    padding-right: 100px;
}
.contact-form > form .pf-title {
    margin-top: 0;
}
.contact-form > form textarea {
    min-height: 155px;
}
.contact-form > form button {
    float: left;
    margin-top: 5px;
    font-family: open sans;
    font-size: 15px;
    color: #ffffff;
    background: #FA5B05;
    padding: 15px 58px;
    border: 2px solid #FA5B05;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.contact-textinfo {
    float: left;
    width: 100%;
    padding-right: 100px;
}
.contact-textinfo > h3 {
    float: left;
    width: 100%;
    font-size: 20px;
    color: #202020;
    font-family: Open Sans;
}
.contact-textinfo > ul {
    float: left;
    width: 100%;
    margin: 0;
}
.contact-textinfo > ul > li {
    float: left;
    width: 100%;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    display: table;
    margin-bottom: 15px;
    margin-top: 15px;
}
.contact-textinfo > ul > li > i {
    display: table-cell;
    vertical-align: top;
    font-size: 50px;
    color: #647585;
    width: 70px;
}
.contact-textinfo > ul > li > span {
    display: table-cell;
    vertical-align: middle;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
}
.contact-textinfo > a {
    float: left;
    margin-top: 5px;
    font-family: open sans;
    font-size: 15px;
    color: #0f36a5;
    padding: 15px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-right: 20px;
    border: 2px solid #0f36a5;
}
.contact-textinfo > a.fill {
    background: #0f36a5;
    color: #ffffff;
}
.contact-map {
    float: left;
    width: 100%;
}
.contact-map #map_div {
    height: 350px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.contact-textinfo.style2 {
    float: left;
    width: 100%;
    background: #edeff7;
    padding: 50px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.account-popup-area.static {
    position: static;
    float: left;
    width: 100%;
    display: block !important;;
    overflow: auto;
    background: no-repeat;
    z-index: 1;
}
.account-popup-area.static .account-popup {
    margin-top: 0;
    width: 430px;
    padding: 0;
}
.account-popup-area.static .account-popup {
    margin-top: 0;
    width: 430px;
    padding: 0;
}
.account-popup-area.static .account-popup > span {
    line-height: 24px;
    margin: 0;
}
.account-popup-area.static .account-popup > h3 {
    line-height: 30px;
}

/* FAQS */
.faqs {
    float: left;
    width: 100%;
}
.faq-box {
    float: left;
    width: 100%;
    margin-bottom: 50px;
}
.faq-box > h2 {
    float: left;
    width: 100%;
    position: relative;
    cursor: pointer;
    font-family: Open Sans;
    font-size: 20px;
}
.faq-box > h2 > i {
    float: right;
}
.faq-box > h2 > i::after {
    position: absolute;
    right: 10px;
    top: 3px;
    width: 1px;
    height: 13px;
    content: "";
    background: #202020;
    opacity: 0;
}
.contentbox {
    float: left;
    width: 100%;
}
.contentbox > p {
    float: left;
    width: 100%;
    font-size: 13px;
    line-height: 24px;
    color: #888888;
    margin: 0;
}
.faq-box > h2.active i::after {
    opacity: 1;
}
.terms-conditions {
    float: left;
    width: 100%;
}
terms {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}
.terms:last-child {
    margin: 0;
}
.terms > h2 {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 20px;
    color: #202020;
    margin-bottom: 15px;
}
.terms > p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 24px;
    margin: 0;
}
.terms {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}
.faq-box:last-child {
    margin: 0;
}
.how-works {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 100px;
}
.how-workimg {
    float: left;
    width: 55%;
    position: relative;
    display: table;
    z-index: 0;
}
.how-workimg > img {
    float: left;
    width: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.how-workimg:before{
    background: rgb(251,35,106);
    background: -moz-linear-gradient(45deg,  rgba(251,35,106,1) 0%, rgba(251,35,106,1) 44%, rgba(20,31,114,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(251,35,106,1)), color-stop(44%,rgba(251,35,106,1)), color-stop(100%,rgba(20,31,114,1)));
    background: -webkit-linear-gradient(45deg,  rgba(251,35,106,1) 0%,rgba(251,35,106,1) 44%,rgba(20,31,114,1) 100%);
    background: -o-linear-gradient(45deg,  rgba(251,35,106,1) 0%,rgba(251,35,106,1) 44%,rgba(20,31,114,1) 100%);
    background: -ms-linear-gradient(45deg,  rgba(251,35,106,1) 0%,rgba(251,35,106,1) 44%,rgba(20,31,114,1) 100%);
    background: linear-gradient(45deg,  rgba(251,35,106,1) 0%,rgba(251,35,106,1) 44%,rgba(20,31,114,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FA5B05', endColorstr='#141f72',GradientType=1 );
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    opacity: 0.8;
}
.how-work-detail {
    position: absolute;
    right: 0;
    top: 0;
    width: 52%;
    height: 100%;
    padding: 30px 0;
}
.how-work-box {
    background: #ffffff;
    vertical-align: middle;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    position: relative;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    padding: 93px 100px;
    float: left;
}
.how-work-box > span {
    width: 66px;
    height: 66px;
    background: #FA5B05;
    position: absolute;
    left: 0;
    top: 50%;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    text-align: center;
    color: #ffffff;
    line-height: 66px;
    font-weight: bold;
    font-size: 25px;
    margin-top: -33px;
    margin-left: -33px;
}
.how-work-box > i {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 120px;
    color: #edeff7;
}
.how-work-box > h3 {
    float: left;
    width: 100%;
    font-size: 22px;
    color: #202020;
    font-family: Open Sans;
}
.how-work-box > p {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 13px;
    color: #888888;
    line-height: 30px;
    margin: 0;
}
.how-works.flip .how-workimg {
    float: right;
}
.how-works.flip .how-work-detail {
    right: auto;
    left: 0;
    text-align: right;
}
.how-works.flip .how-work-box > span {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -33px;
}
.how-works.flip .how-work-box > i {
    right: auto;
    left: 20px;
}
.how-works:last-child {
    margin: 0;
}
.error-sec {
    position: absolute;
    left: 50%;
    top: 50%;

    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    text-align: center;
}
.error-sec > img {
    float: none;
    display: inline-block;
}
.error-sec > span {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #d5d8f3;
    margin-top: 40px;
}
.error-sec > p {
    float: left;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
    line-height: 24px;
    margin-top: 20px;
}
.error-sec form {
    float: left;
    width: 100%;
    padding: 0;
}
.error-sec form > input {
    float: none;
    width: 510px;
    height: 60px;
    background: #ffffff;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    font-size: 13px;
    display: inline-block;
    margin: 0;
}
.error-sec form button {
    float: none;
    display: inline-block;
    width: 70px;
    height: 60px;
    text-align: center;
    padding: 0;
    font-size: 32px;
    background: #8b91dd;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-left: 15px;
    position: relative;
    top: 7px;
}
.error-sec h6 a {
    float: none;
    display: inline-block;
    font-family: Open Sans;
    font-size: 13px;
    background: #0f36a5;
    color: #ffffff;
    padding: 12px 40px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    margin-top: 30px;
}
.error-sec > h6 {
    float: left;
    width: 100%;
    margin: 0;
}

.stick-top .style3 .post-job-btn:hover {
    background: transparent;
    border: 2px solid #FA5B05;
    color: #FA5B05;
}

.btn-extars .post-job-btn:hover {
    background: transparent;
    border: 2px solid #FA5B05;
    color: #FFF;
}

.account-popup > form button:hover{
    background: #FA5B05;
}

.login-social a.tw-login:hover{
    background: #1da1f2;
    border-color: #1da1f2;
    color: #ffffff;
}

.login-social a.fb-login:hover{
    background: #3b5998;
    border-color: #3b5998;
    color: #ffffff;
}

.job-search form button:hover{
     background: #FA5B05;
}
.or-browser > a:hover {
    background: #ffffff;
    color: #333333;
}
.scroll-to:hover{
    border-color: #FA5B05;
}
.scroll-to:hover a{
    background: #FA5B05;
    color: #ffffff;
}
.browse-all-cat a:hover {
    background: #FA5B05;
    color: #ffffff;
}
.simple-text-block a:hover {
    border-color: #FFF;
    background: #FFF;
    color: #FA5B05;
}
.fav-job.active {
    color: red;
}
.social a:hover {
    color: #fff;
}
header.style2 .post-job-btn:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
header.style2 .account-btns {
    padding: 10px 0;
}
.job-grid > a:hover {
    background: #FA5B05;
    color: #ffffff;
    border-color: #FA5B05;
}
.top-compnay > a:hover {
    background: #FA5B05;
    color: #ffffff;
    border-color: #FA5B05;
}
.subscription-sec form button:hover {
    background: #333333;
}
.social-links:hover a {
    opacity: 0.1;
}
.social-links:hover a:hover {
    opacity: 1;
}
.links a:hover {
    color: #333333;
}
header.style3 .post-job-btn:hover {

    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

}
.find-cand form > button:hover {
    background: #FA5B05;
}
.download-text ul li a:hover {
    background: #7dc246;
    border-color: #7dc246;
}
.team-detail > a:hover {
    color: #000000;
}
footer.ft .social a:hover {
    color: #333333;
}
header.style4 .account-btns > li a:hover {
    background: #1e83f0;
    border-color: #1e83f0;
    color: #ffffff;
}
.job-search form button:hover {
    background: #FA5B05;
}
.p-category.style2:hover > a i {
    color: #FA5B05;
}
.subscribe_widget form button:hover {
    background: #FA5B05;
}
header.style5 .post-job-btn:hover {
    background: #ffffff;
    color: #333333;
    border: 2px solid #fff;
}
.form-location-sec form button:hover{
    background: #FA5B05;
}
.p-category.style2.gray:hover {
    background: #ffffff;
    box-shadow: none;

    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;

    height: auto;
    width: auto; margin-left: 0px;

}
.job-listings-sec.style2 .aply-btn:hover {
    background: transparent;
    border:1px solid #26ae61;
    color: #26ae61;
}
.job-grid.style2:hover {
    background: #ffffff;
    box-shadow: none;
}
.grid-info-box a:hover {
    background: transparent;
    border:1px solid #26ae61;
    color: #26ae61;
}
.who-am a:hover {
    background: #ffffff;
    color: #333333;
}
.emlthis.active {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
.emlthis.active i {
    color: #ffffff;
}
.job-head-wide .apply-thisjob:hover {
    background: transparent;
    border-color: #9fa4e1;
    color: #9fa4e1;
}

.apply-thisjob:hover {
    background: #9fa4e1;
    border-color: #9fa4e1;
    color: #FFF;
}

.apply-alternative a:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
.share-bar a:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
.quick-form-job form button:hover {
    background: transparent;
    border: 2px solid #424f95;
    color: #424f95;
}
.emply-text > ul > li:hover::before {
    background: #FA5B05;
    width: 20px;
}
.emply-text > ul > li:hover a {
    color: #FA5B05;
}
.job-single-head.style2 > a:hover {

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    color: #ffffff;
}
.job-single-head.style2 > .apply-job-linkedin:hover {
    color: #FA5B05;
}
.job-overview ul > li:hover i {
    color: #FA5B05;
}

.emply-btns > a:hover {
    background: #8b91dd;
    border-color: #8b91dd;
    color: #FFF;
}

.emply-btns > a.seemap:hover {
    background: transparent;
    border-color: #FA5B05;
    color: #FA5B05;
}

.upload-info > a:hover {
    background: #8b91dd;
    color: #ffffff;
}
.pf-field .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-with-drop.chosen-container-active a {
    border: 2px solid #e8ecec;
}
.pf-field .chosen-container-single .chosen-drop {
    margin-top: 5px;
    border: 1px solid #e8ecec;
}
.profile-form-edit > form button:hover,
.contact-edit > form button:hover{
    background: #FA5B05;
    color: #ffffff;
}
.contact-edit .srch-lctn:hover {
    background: #8b91dd;
    color: #ffffff;
    border-color: #8b91dd;
}
.pf-map #map_div {
    height: 300px;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.table-list-title h3 a:hover {
    color: #FA5B05;
}
.tree_widget-sec > ul > li > ul > li a:hover {
    color: #202020;
}
.contact-popup form > button:hover {
    background: #333333;
}
.change-password form button:hover {
    background: #FA5B05;
    color: #ffffff;
}
.shortlists > a:hover {
    background: #8b91dd;
    color: #ffffff;
}
.action-inner .download-cv a:hover {
    background: #FA5B05;
    border-color: #FA5B05;
}
.follow-companies > ul li .job-listing.wtabs .go-unfollow:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #fff;
}
.border-title > a:hover {
    color: #555555;
}
.border-title > a:hover i {
    background: #555555;
}
.resumeadd-form button:hover{
    background: #555555;
    border-color: #555555;
}
.coverletter-sec > form button:hover {
    background: #FA5B05;
    color: #ffffff;
}
.bbutton:hover {
    background: #FA5B05;
    color: #ffffff;
}
.blogpost.style2 .blog-postdetail .bbutton:hover {
    background: none;
    color: #333333;
}
.tags_widget > a:hover {
    background: #fafafa;
}
.sidebar-links a:hover {
    color: #FA5B05;
}
.mb-info > h3:hover{
    color: #FA5B05;
}
.blog-postdetail > h3:hover {
    color: #FA5B05;
}
.post-hist a:hover > i {
    background: #fafafa;
}
.commentform-sec > form button:hover {
    background: #555555;
}
.service:hover i {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
.contact-form > form button:hover {
    background: transparent;
    border: 2px solid #FA5B05;
    color: #FA5B05;
}
.contact-textinfo > a.fill:hover, .contact-textinfo > a:hover {
    background: #FA5B05;
    border-color: #FA5B05;
    color: #ffffff;
}
.error-sec form button:hover {
    background: #FA5B05;
}
.error-sec h6 a:hover {
    background: #FA5B05;
}
.menu-sec nav > ul > li ul li.menu-item-has-children > ul {
    position: absolute;
    left: 100%;
    width: 250px;
    padding-left: 11px;
    opacity: 0;
    visibility: hidden;


}
.menu-sec nav > ul > li ul li.menu-item-has-children > ul::before {
    position: absolute;
    left: 7px;
    top: 13px;
    width: 13px;
    height: 13px;
    background: #fafafa;
    content: "";

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

}
.menu-sec nav > ul > li ul li.menu-item-has-children:hover > ul {
    opacity: 1;
    visibility: visible;
}
.bottom-line .scrollup {
    position: absolute;
    right: 70px;
    bottom: 44px;
    width: 50px;
    height: 50px;
    border: 2px solid #8a99b3;

    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;

    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

    line-height: 46px;
    color: #8a99b3;
    font-size: 23px;
}

.bottom-line .scrollup:hover {
    border: 2px solid #FFF;
    color: #FFF;
}

footer.ft .scrollup:hover{border-color: #647585; color: #647585;}

.bottom-line.style2 .scrollup {
    border: none;
    bottom: 8px;
}
footer.ft .scrollup {
    border-color: #7dc246;
    color: #7dc246;
    bottom: 190px;
    z-index: 6;
}
.forsticky {
    position: absolute;
    left: 0;
    top: 0;
}
.forsticky.sticky .menu-sec nav > ul > li > a {
    color: #232323;
    line-height: 10px;
}
.forsticky.sticky .account-btns > li a {
    color: #222222;
}
.forsticky.sticky .menu-sec {
    margin-top: 23px;
    margin-bottom: 17px;
}
.forsticky.sticky .post-job-btn:hover {
    background: #FA5B05;
    color: #FFF;
}
.forsticky.sticky {
    position: fixed;
    left: 0;
    top: 0;
    background: #ffffff;
     -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}
.showsticky {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
}
.forsticky.sticky .logo a img.showsticky {
    display: block;
}
.forsticky.sticky .logo a img.hidesticky {
    opacity: 0;
}
*:focus {outline:0;}
.top-company-sec button.slick-arrow:hover {
    background: #FA5B05;
    border: 2px solid #FA5B05;
}
.top-company-sec button.slick-arrow:hover:before {
    color: #ffffff;
}

.iconmove {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.iconmove img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.p-category.green > a i {
    color: #647585;
}
.p-category.green:hover a i {
    color: #7dc246;
}
.browse-all-cat.green a {
    border-color: #7dc246;
}
.browse-all-cat.green a:hover {
    background: #7dc246;
}
.job-field.with-icon.nolbel input {
    margin: 41px 0;
}
.browse-all-cat .style2.noradius {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;

}
.alpha-pag.full #filter {
    margin: 0;
    float: left;
    width: 100%;
}
.alpha-pag.full #filter li {
    float: left;
    margin: 0;
}
.alpha-pag.full a.selected {
    background: #8b91dd;
    color: #ffffff;
}
.alpha-pag.full #filter li:first-child a {
    font-size: 13px;
    color: #888888;
    padding: 10px 20px;
    margin-right: 30px;
    font-weight: normal;
}
.alpha-pag.full #filter li:first-child a.selected {
    color: #ffffff;
}
.alpha-pag.full #filter li:first-child a:hover {
    color: #fff;
}

.tags {
    float: left;
    width: 100%;
    border: 2px solid #e8ecec;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

    padding: 8px;
}
.tags > .addedTag {
    float: left;
    background: #f4f5fa;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    font-family: Open Sans;
    font-size: 13px;
    padding: 7px 17px;
    margin-right: 10px;
    position: relative;
}
.tags > .addedTag > span {
    position: absolute;
    right: -6px;
    top: -5px;
    width: 16px;
    height: 16px;
    font-style: normal;
    background: #FA5B05;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
    line-height: 13px;
    font-size: 10px;
    font-family: Open Sans;
    cursor: pointer;
}
.tagAdd.taglist input {
    float: left;
    width: auto;
    background: #ffffff;
    border-left: 1px solid #e8ecec;
    margin-left: 10px;
    padding: 0;
    height: 19px;
    margin: 5px 0;
    margin-left: 15px;
    padding-left: 15px;
}
.tags li {
    margin: 0;
}
.emply-resume-list:hover {
    border-color: #ffffff;

    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

    margin-top: 0px;
    position: relative;
    z-index: 12;
}
.edu-history-sec .action_job {
    position: absolute;
    right: 0;
    top: 0;
    left: auto;
}
.edu-history-sec .action_job li a {
    font-size: 19px;
    color: #8b91dd;
}
.mp-col .action_job {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: -10px;
}
.mp-col .action_job li {
    float: none;
    display: inline-block;
}
.mp-col .action_job li a {
    font-size: 19px;
    color: #8b91dd;
}
.progress-sec.with-edit .action_job {
    position: absolute;
    right: 0;
    top: 17px;
}
.progress-sec.with-edit .action_job li a {
    font-size: 19px;
    color: #8b91dd;
}
.menu-sec nav > ul > li ul li.menu-item-has-children > ul > li {
    background: #fafafa;
}
.cand-extralink.stick {
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    background: #ffffff;
    z-index: 9;
}
.cand-extralink > li.active a {
    border-bottom: 2px solid #8b91dd;
}
.responsive-header {
    float: left;
    width: 100%;
    position: relative;
    background: #0b1b46;
    padding: 0 25px;
    z-index: 99;
    display: none;
}

.responsive-header.three {
    background: #FFF !important;
}

.responsive-header.four {
    background: #FFF !important;
}

.responsive-header.five {
    background: #26ae61 !important;
}

.responsive-header.five .res-openmenu {
    background: transparent !important;
}


.responsive-header.three .res-openmenu{color: #7dc246;}
.responsive-header.three .res-closemenu{color: #7dc246;}

.responsive-header.four .res-openmenu{color: #7dc246;}
.responsive-header.four .res-closemenu{color: #7dc246;}

.responsive-menubar {
    float: left;
    width: 100%;
    padding: 30px 0;
}
.res-logo {
    float: left;
}
.menu-resaction {
    float: right;
    position: relative;
}
.responsivemenu {
    float: left;
    width: 100%;
    /* margin-top: 40px;
    margin-bottom: 50px; */
    margin-top: 15px;
    margin-bottom: 30px;

}
.res-openmenu {
    float: left;
    color: #fff;
    font-size: 15px;
    font-family: Open Sans;
    padding: 12px 27px;

    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    border-radius: 26px;

    cursor: pointer;
}
.res-openmenu img {
    float: left;
    margin-right: 11px;
}
.res-closemenu {
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    font-size: 15px;
    font-family: Open Sans;
    padding: 13px 27px;

    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
    border-radius: 26px;

    cursor: pointer;
    opacity: 0;
    visibility: hidden;
}
.responsive-opensec {
    width: 100%;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background: #0b1b46;
    padding: 0 30px;
    overflow-y: scroll;
    /* height: 620px; */
}
.responsive-opensec .btn-extars {
    float: left;
    width: 100%;
    border-top: 1px solid #38425f;
    border-bottom: 1px solid #38425f;
    padding: 20px 0;
}
.res-search {
    float: left;
    width: 100%;
    margin-top: 40px;
    background: #040e2b;
    position: relative;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;

}
.res-search input {
    float: left;
    width: 100%;
    margin: 0;
    background: none;
    font-size: 13px;
    color: #9299af;
    padding: 18px 30px;
        padding-right: 30px;
    padding-right: 80px;
}
.res-search button {
    position: absolute;
    right: 0;
    background: none;
    padding: 0;
    color: #62697f;
    font-size: 25px;
    padding: 11px 20px;
}
.responsivemenu > ul {
    float: left;
    width: 100%;
    margin: 0;
}
.responsivemenu > ul > li {
    float: left;
    width: 100%;
    margin: 15px 0;
    position: relative;
}
.responsivemenu > ul > li > a {
    float: left;
    width: 100%;
    font-family: Open Sans;
    font-size: 22px;
    color: #96a0bd;
    padding-right: 30px;
    position: relative;
}
.responsivemenu > ul > li.menu-item-has-children > a::before {
    position: absolute;
    right: 0;
    top: 13px;
    width: 20px;
    height: 1px;
    background: #ffffff;
    content: "";
}
.responsivemenu > ul > li.menu-item-has-children > a::after {
    position: absolute;
    right: 10px;
    top: 4px;
    width: 1px;
    height: 20px;
    content: "";
    background: #ffffff;
}
.responsivemenu > ul > li ul {
    float: left;
    width: 100%;
    padding: 0;
    padding-left: 0px;
    margin: 20px 0 -20px 0;
    padding-left: 30px;
    display: none;
}
.responsivemenu > ul > li ul > li {
    float: left;
    width: 100%;
    margin: 0;
}
.responsivemenu > ul > li ul > li a {
    float: left;
    width: 100%;
    color: #ffffff;
    font-size: 13px;
    padding: 8px 0;
}
.responsivemenu > ul > li.menu-item-has-children.active {
    margin-bottom: 0;
}
.responsivemenu > ul > li.menu-item-has-children.active > ul {
    margin-bottom: 0;
}
.responsivemenu > ul > li.menu-item-has-children.active a::after {
    height: 0px;
}
.responsivemenu > ul > li.menu-item-has-children.active > a {
    color: #ffffff;
}
.responsivemenu > ul > li ul > li a:hover {
    color: #FA5B05;
    padding-left: 15px;
}
.res-openmenu.active {
    opacity: 0;
    visibility: hidden;

    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);

}
.responsive-header.active .res-closemenu {
    opacity: 1;
    visibility: visible;
}
.res-closemenu img {
    float: left;
    margin-right: 12px;
}
.blogpost.style2 .blog-posthumb > a img {

    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;

}
.res-logo > a {
    float: left;
}







/* add new 21.03.2019 */

.new-slide{
    height: 700px;
    overflow: hidden;
    position: relative;
    background: rgb(20,31,114);
    background: -moz-linear-gradient(130deg,  rgba(20,31,114,1) 0%, rgba(52,65,161,1) 71%, rgba(52,65,161,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(20,31,114,1)), color-stop(71%,rgba(52,65,161,1)), color-stop(100%,rgba(52,65,161,1)));
    background: -webkit-linear-gradient(130deg,  rgba(20,31,114,1) 0%,rgba(52,65,161,1) 71%,rgba(52,65,161,1) 100%);
    background: -o-linear-gradient(130deg,  rgba(20,31,114,1) 0%,rgba(52,65,161,1) 71%,rgba(52,65,161,1) 100%);
    background: -ms-linear-gradient(130deg,  rgba(20,31,114,1) 0%,rgba(52,65,161,1) 71%,rgba(52,65,161,1) 100%);
    background: linear-gradient(130deg,  rgba(20,31,114,1) 0%,rgba(52,65,161,1) 71%,rgba(52,65,161,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );

}

.new-slide img{ position: absolute; bottom: 0px; right: 0px; opacity: 0.20;}



.new-slide-2{
    height: 700px;
    overflow: hidden;
    position: relative;
    background: rgb(36,51,169);
    background: -moz-linear-gradient(-150deg,  rgba(36,51,169,1) 0%, rgba(234,49,124,1) 71%, rgba(234,49,124,1) 100%);
    background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(36,51,169,1)), color-stop(71%,rgba(234,49,124,1)), color-stop(100%,rgba(234,49,124,1)));
    background: -webkit-linear-gradient(-150deg,  rgba(36,51,169,1) 0%,rgba(234,49,124,1) 71%,rgba(234,49,124,1) 100%);
    background: -o-linear-gradient(-150deg,  rgba(36,51,169,1) 0%,rgba(234,49,124,1) 71%,rgba(234,49,124,1) 100%);
    background: -ms-linear-gradient(-150deg,  rgba(36,51,169,1) 0%,rgba(234,49,124,1) 71%,rgba(234,49,124,1) 100%);
    background: linear-gradient(-150deg,  rgba(36,51,169,1) 0%,rgba(234,49,124,1) 71%,rgba(234,49,124,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b91dd', endColorstr='#10195d',GradientType=1 );
}

.new-slide-2 img{ position: absolute; top: 20%; right: 0px; }



.new-slide-3{
    height: 700px;
    overflow: hidden;
    position: relative;
    background: #f4f5fa;
}

.new-slide-3 img{ position: absolute; top: 15%; right: 0px; opacity: 0.9;}



.new-header{
    position: fixed;
    left: 0;
    top: 0;
    background: #ffffff;
     -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}

.forsticky.new-header .menu-sec{
    margin-top: 23px;
    margin-bottom: 23px;
}

.forsticky.new-header .logo a img.showsticky {
    display: block;
}

.forsticky.new-header .menu-sec nav > ul > li > a {
    color: #232323;
    line-height: 10px;
}
.forsticky.new-header .account-btns > li a {
    color: #222222;
}



.newbg{
    background-color: #fff;
}


.newbg .job-search > h3{ color: #202020; text-align: left; }
.newbg .job-search > span{color: #888888; text-align: left;}
.newbg .job-field input,
.newbg .chosen-container-single .chosen-single{
    border-radius: 8px;
     -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}


.newbg .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-with-drop.chosen-container-active > a{
    -webkit-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -ms-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    -o-box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1) !important;
}


.newbg .job-search form button{background-color: #0f36a5;}

.newbg .or-browser > span{color: #888888;}
.newbg .or-browser > a{color: #888888; border-color: #888888;}


/* add new 21.03.2019 */





/* Cross Browser Compatibility */
header,
.res-openmenu,
.res-closemenu,
.responsivemenu > ul > li.menu-item-has-children > a,
.responsivemenu > ul > li ul > li a,
.menu-sec nav > ul > li,
.menu-sec nav > ul > li *,
.chosen-container,
.responsivemenu > ul > li.menu-item-has-children > a::before,
.responsivemenu > ul > li.menu-item-has-children > a::after,
.contact-textinfo > a.fill,
.contact-textinfo > a,
.chosen-container *,
.emply-resume-list,
.p-category,
.p-category *,
.job-listing,
.job-listing *,
.my-blog,
.my-blog *,
.link_widgets,
.link_widgets *,
.link_widgets a:before,
.quick-select,
.quick-select *,
.top-compnay,
.top-compnay *,
.team,
.team *,
.sb-title::after,
.sb-title::before,
.sb-title,
.emply-list,
.emply-list *,
.mportolio a,
.profile-sidebar,
.post-job-btn,
.account-popup > form button,
.login-social a,
.job-search form button,
.or-browser > a,
.scroll-to,
.scroll-to *,
.browse-all-cat a,
.simple-text-block a,
.social *,
.job-grid,
.job-grid *,
.top-compnay *,
.subscription-sec form button,
.social-links,
.social-links *,
.links a,
.find-cand form > button,
.download-text ul *,
.team-detail *,
header.style4 .account-btns > li a,
.tab-sec,
.tab-sec *,
.subscribe_widget form button,
.who-am,
.who-am *,
#toggle-widget h2::before,
.emlthis,
.emlthis *,
.pagination,
.pagination *,
.apply-thisjob,
.apply-alternative a,
.share-bar *,
.quick-form-job form button,
.alpha-pag *,
.emply-text > ul > li *,
.emply-text > ul > li:before,
.job-single-head.style2 > a,
.job-overview ul > li *,
.emply-btns > a,
.upload-info > a,
.profile-form-edit > form button,
.contact-edit .srch-lctn,
.contact-edit > form button,
.action_job *,
.table-list-title *,
.tree_widget-sec > ul > li > ul *,
.contact-popup form > button,
.change-password form button,
.shortlists > a,
.action-inner .download-cv a,
.follow-companies > ul li .job-listing.wtabs .go-unfollow,
.border-title > a,
.border-title > a *,
.resumeadd-form button,
.coverletter-sec > form button,
.blogpost,
.blogpost *,
.tags_widget > a,
.sidebar-links a,
.sidebar-links a i,
.mb-info > h3,
.post-hist *,
.commentform-sec > form button,
.service *,
.contact-form > form button,
.error-sec form button,
.error-sec h6 a,
.menu-sec,
.top-company-sec button.slick-arrow,
.top-company-sec button.slick-arrow:before,
.how-to-sec,
.how-to-sec *,
.responsivemenu li,
.responsivemenu,
.account-popup a.term-check-link {
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
