@media (max-width: 991px) {
    .footer-title {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
}

@media (max-width: 980px) {
    div.footer-links-titled {
        margin-top: 20px;
    }
}
