$color_1: white;
$color_2: rgb(151, 161, 188);


.responsive-header {
	.responsive-menubar {
		@media(max-width: 575.98px) {
			padding: 10px 0;
		}
	}
	.responsive-opensec {
		.responseve-account-and-language {
			border-top: 1px solid #38425f;
			align-items: center;
		}
		#id_responsivemenu_account_dropdown {
			ul {
				> li {
					&:not(:last-child) {
						margin: 10px 0;
					}
				}
			}
		}

		.responsivemenu {
			&.languages {
				margin: 0;
				text-align: right;
			}
			&.menu {
				margin: 15px 0;
				ul {
					> li {
						margin-top: 0;
						margin-bottom: 15px;
						display: inline-block;
						a {
							font-size: 18px;
							width: auto;
							padding: 0;
							color: $color_2;
							transition: all 0.4s ease 0s;
							vertical-align: middle;
							@media(min-width: 992px) {
								&:hover {
									color: $color_1;
								}
							}
							@media(max-width: 991.98px) {
								&.touched {
									color: $color_1;
								}
							}
							&::before {
								content: none;
							}
							&::after {
								content: none;
							}
							&.sub {
								font-size: 16px;
							}
						}
						.cross-sign-trigger {
							width: 30px;
							height: 20px;
							padding: 5px 30px 0 50px;
							background: transparent;
							display: inline-flex;
							justify-content: center;

							&:hover {
								cursor: pointer;
							}
							.cross-line {
								position: absolute;
								background: #fff;
								align-self: center;
								&__h {
									width: 20px;
									height: 1px;
								}
								&__v {
									width: 1px;
									height: 20px;
								}
							}
						}
						> ul {
							margin: 12px 0;
							> li {
								margin-bottom: 12px;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}


header {
	.menu-sec {
		.menu-item {
			ul {
				li {
					&:first-child {
						border-top-left-radius: 6px !important;
						border-top-right-radius: 6px !important;
					}
				}
			}
		}
	}
	.language-dropdown {
		>li {
			>ul {
				margin-left: 25% !important;
				width: 120px !important;
				>li {
					&:first-child {
						border-top-left-radius: 8px !important;
						border-top-right-radius: 8px !important;
					}
				}
				&::before {
					left: 23px !important;
				}
			}
			>a {
				&::before {
					content: none !important;
				}
			}
		}
	}
}


ul.account-dropdown {
	>li {
		>ul {
			width: 190px !important;
			&::before {
				left: 23px !important;
			}
		}
		>a {
			&::before {
				content: none !important;
			}
		}
	}
}
ul.responsive-account-dropdown {
	>li {
		>ul {
			width: 190px !important;
			z-index: 1;
			left: 23px !important;
		}
		>a {
			&::before {
				content: none !important;
			}
		}
	}
}


#id_responsivemenu_account_dropdown {
	margin: 0;
	text-align: right;
	padding-right: 35px;
}
#id_bottom_border {
	border-bottom: 1px solid #38425f;
}
#id_language_drop {
	color: $color_1;
	padding-right: 15px;
}
#id_account_drop {
	color: $color_1;
	padding-right: 15px;
}
#available_translations {
	a {
		font-size: 14px;
	}
}
#responsive_account_dropdown {
	a {
		font-size: 14px;
	}
}
.lang-drop-res-anonymous.menu-sec {
	flex: 0 0 90px;
	padding: 0px;
	margin: auto 14px auto 0;
}
.contact-butt-res-anonymous {
	flex: 0 0 120px;
}

.account-btns-mobile {
	padding-top: 0 !important;
	margin-left: -15px;
}
