$color_1: #888888;

.blog-post-list-outer-wrap {
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-ms-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	&:hover {
		box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
		z-index: 1;
	}
}
p.post-date {
	color: $color_1;
}
.blog-post-list-inner-wrap {
	padding: 30px;
}
.image-container.small-image {
	max-height: 200px;
	width: 100%;
}
.image-container {
	img.small-image {
		height: 200px;
		width: 100%;
		object-fit: cover;
	}
	img.image-detail {
		max-height: 430px;
		width: 100%;
		object-fit: cover;
		object-position: top;
	}
}
h2.post-title {
	font-size: 32px;
	margin: 0 0 20px 0;
}
.image-container.image-detail {
	max-height: 100%;
	width: 100%;
}
@media (max-width: 991px) {
	.blog-post-list-inner-wrap {
		padding: 0 0 30px 0;
	}
	.image-container {
		img.image-detail {
			max-height: 350px;
		}
	}
}
@media (min-width: 576px) and (max-width: 768px) {
	.image-container.small-image {
		max-height: 300px;
		width: 100%;
	}
	.image-container {
		img.small-image {
			height: 300px;
			width: 100%;
			object-fit: cover;
		}
	}
}
@media (max-width: 750px) {
	.image-container {
		img.image-detail {
			max-height: 300px;
		}
	}
}
@media (max-width: 650px) {
	.image-container {
		img.image-detail {
			max-height: 250px;
		}
	}
}
