$color_1: #666666;
$color_2: #1155cc;

.agent-overview {
	float: left;
	width: 100%;
	margin: 0;
	h3 {
		float: left;
		width: 100%;
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 15px;
	}
	.row.agent-data {
		height: 200px;
		margin: 0;
		border: 2px solid #e8ecec;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
		border-radius: 8px;
	}
}
.agent-image {
	height: 100%;
	padding-left: 0;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		-webkit-border-radius: 6px 0 0 6px;
		-moz-border-radius: 6px 0 0 6px;
		-ms-border-radius: 6px 0 0 6px;
		-o-border-radius: 6px 0 0 6px;
		border-radius: 6px 0 0 6px;
		border-radius: 6px 0 0 6px;
	}
}
.agent-info {
	height: 100%;
	padding: 20px 0;
	.ico {
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		height: 30px;
		width: 30px;
		margin-right: 2px;
	}
}
.agent-info.no-picture {
	height: 100%;
	padding: 20px 0 20px 40px;
}
.agent-name {
	font-size: 20px;
}
.agent-contact {
	color: $color_1;
	font-size: 15px;
	margin: 0 0 5px 0;
	a {
		color: $color_2;
	}
}
@media (min-width: 992px) and (max-width:1199px) {
	.agent-contact {
		font-size: 11px;
	}
}
@media (max-width:980px) {
	.agent-info.no-picture {
		padding-left: 0;
	}
}
@media (max-width:370px) {
	.agent-contact {
		font-size: 12px;
	}
}
