.apply-thisjob-closed {
    background: rgb(127, 193, 78);
    border: 2px solid rgb(127, 193, 78);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
    color: #ffffff;
    float: left;
    width: 100%;
    text-align: center;
    padding: 20px 20px;
    font-size: 15px;
    font-family: Open Sans;
    font-weight: bold;
    pointer-events: none;
    opacity: 0.6;
}

.apply-thisjob-closed:hover {
    background: transparent;
    border-color: rgb(127, 193, 78);
    color: rgb(127, 193, 78);
}

.recent-jobs {
    .recent-jobs-title {
        width: 100%;
        font-size: 24px;
        color: #202020;
        font-weight: bold;
        padding: 40px 0 20px 0;
        border-top: 1px solid #e8ecec;
    }
}


.ad-edit-link {
    vertical-align: middle;
    i {
        font-size: 35px;
        line-height: 10px;
    }
}

// ad image resizing
.job-single-head3 {
    // min-height: 125px;

    .job-thumb {
        padding-right: 25px;

        img {
            min-width: 182px;
            min-height: 95px;
        }
    }

    .job-single-info3 {
        padding-left: 0;
    }
}

// this class is applied to '.job-overview-mobile .job-overview' 
// when there is no similar ads to show (see MediaQueryHandler in detail.html's script element). 
.stick-to-footer {
    margin-bottom: -40px !important;
    padding-bottom: 50px !important;
}

@media(max-width: 991px ) {
    .job-head-wide {
        border-bottom: none !important;
    }

    .job-overview-mobile {
        display: block !important;
        h3.job-overview-title,
        h3.agent-overview-title {
            width: 100%;
            font-size: 24px;
            color: #202020;
            font-weight: bold;
            padding: 40px 0 20px 0;
            border-top: 1px solid #e8ecec;
        }
        .job-overview ul {
            align-items: center;
            border: none;
            // padding-right: 30px !important;
            margin-bottom: 0 !important;
            li {
                width: 33.33% !important;
                // min-height: 70px;
            }
        }
    }

    .job-overview-desktop {
        display: none;
    }
}

@media(max-width: 980px) {
    .recent-jobs {
        padding-top: 40px;
        .recent-jobs-title {
            border-top: none;
            padding:0 0 20px 0;
            margin-bottom: 0;
        }
    }
    .job-overview-mobile {
        .job-overview {
            margin-top: 35px;
            background: rgb(250,250,250);
            width: 100vw;
            margin-left: -15px;
            padding: 0 30px 20px 30px;

            
            h3.job-overview-title {
                border-top: none;
                padding: 30px 0;
                margin-bottom: 0;
                margin-left: -15px;
            }

            ul {
                padding: 0 !important;
                margin-left: -38px;

                li {
                    min-height: 60px;
                    margin-top: 0;
                }
            }
        }
        .agent-overview {
            // margin-top: 35px;
            background: rgb(250,250,250);
            width: 100vw;
            margin-left: -15px;
            padding: 0 15px 20px 15px;

            .row.agent-data {
                border-color: #fafafa;
                border-radius: 0;
            }

            .agent-image img {
                border-radius: 0;
            }

            
            h3.agent-overview-title {
                border-top: none;
                padding: 0 0 30px 0;
                margin-bottom: 0;
                // margin-left: -15px;
            }

            // ul {
            //     padding: 0 !important;
            //     margin-left: -38px;

            //     li {
            //         min-height: 60px;
            //         margin-top: 0;
            //     }
            // }
        }
    }
}

@media(max-width: 700px) {
    .job-overview-mobile .job-overview li {
        min-width: 50%; // cannot override width: 33.33% !important , so use min-width
    }
}

@media(max-width: 520px) {
    .job-overview-mobile .job-overview li {
        margin: 10px 0 !important;
        // min-height: 100px !important;
    }
}
