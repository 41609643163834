#profile__section {
    [data-target*="datetimepicker"] {
        display: none;
    }
    .pf-field {
        &, & .input-group {
            margin-bottom: 0;
            i {
                z-index: 3;
            }
        }
    }
    .pf-title {
        font-size: 14px;
        color: gray;
        @media(max-width: 575.98px) {
            margin-top: 10px;
        }
    }

    .chosen-container {
        margin-bottom: 20px;
    }

    .dropdown-field {
        // @media(max-width: 575.98px) {
            margin-bottom: -5px;
        // }
    }
    select {
        color: #474747;
    }
    textarea {
        color: #474747;
        @media(min-width: 500px ) {
            height: 150px;
        }
        @media(min-width: 768px ) {
            height: 100px
        }
    }
    form {
        &:not(.password_change):not(.add_email) {
            input {
                color: #474747;
                margin-bottom: 0;
                @media(max-width: 575.98px) {
                margin-bottom: 15px;
            }
        }
    }

    }
    .chosen-single {
        span {
            color: #474747;
        }
    }
    .profile-title > h3 {
        font-size: 30px;
    }

    .profile-menu-header {
        display: flex;
        height: 100px;
        width: 100%;
        padding: 20px 0 20px 30px;
        border-bottom: 1px solid #edeff7;
        * {
            align-self: center;
            margin-right: 20px;
        }
        img {
            height: 100%;
            border-radius: 50%;
        }
        > h3 {
            font-family: 'Open Sans' !important;
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 0;
            line-height: 1;
            font-size: 20px;
        }
        .profile-menu-button {
            padding: 0;
            .animated-icon {
                width: 30px;
                height: 20px;
                position: relative;
                margin: 0px;
                transform: rotate(0deg);
                transition: .5s ease-in-out;
                cursor: pointer;
                span {
                    display: block;
                    position: absolute;
                    background: #5d5d5d;
                    height: 3px;
                    width: 100%;
                    border-radius: 9px;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: .25s ease-in-out;
                    transform-origin: left center;
                }
                span:nth-child(1) {
                    top: 0px;
                    transform-origin: left center;
                }
                span:nth-child(2) {
                    top: 10px;
                    transform-origin: left center;
                }
                span:nth-child(3) {
                    top: 20px;
                    transform-origin: left center;
                }
                &.open {
                    span:nth-child(1) {
                        transform: rotate(45deg);
                        top: 0px;
                        left: 8px;
                    }
                    span:nth-child(2) {
                        width: 0%;
                        opacity: 0;
                    }
                    span:nth-child(3) {
                        transform: rotate(-45deg);
                        top: 21px;
                        left: 8px;
                    }
                }
            }
        }
        @media(max-width: 991.98px) {
            padding-left: 0;
            &__desktop {
                display: none;
            }
        }
        @media(min-width: 992px) {
            &__mobile {
                display: none;
            }
        }
    }

    .button-disabled {
        border: 2px solid #fa5b057d !important;
        color: #2020204f !important;
        pointer-events: none;
    }
    .button-disabled:hover {
        background: #ffffff !important;
    }

    .delete-button-disabled {
        border: 2px solid #e1143d73 !important;
        color: #2020204f !important;
        pointer-events: none;
    }
    .delete-button-disabled:hover {
        background: #ffffff !important;
    }

    button.add-first-language {
        float: left;
        background: #ffffff;
        border: 2px solid #FA5B05;
        color: #202020;
        font-family: Open Sans;
        font-size: 15px;
        padding: 11px 40px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        margin-top: 10px;

        -webkit-transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
    }

    button.add-first-language:hover {
        background: #FA5B05;
        color: #ffffff;
    }

    .no-languages {
        padding-left: 30px;
        margin-top: 30px;
    }

    h3 {
        margin-top: 50px;
    }

    form.user-data-management-form {
        label {
            &:not(.radio-choice) {
                padding-left: unset;
                &::before {
                    content: unset;
                }
            }
        }

        .chosen-single div::before {
            color: #8b91dd;
        }

        // This setting assures that even when there are no errors, there is 15px high element betwen label and input.
        // And height is the same when there are erros, so if left filed has error, and right no, they will still be
        // horizontaly aligned. Without this, one with errors will be lower by error line height.
        .field-errors {
            @media(min-width:768px) {
                min-height: 15px;
                max-height: 15px;
            }
            padding: 0 15px;
        }

        .green-border {
            border: 2px solid #28a745;
        }

        .green-border:hover {
            background: #28a745;
        }

        button.delete {
            border: 2px solid crimson;
        }

        button.delete:hover {
            background: crimson;
        }


        .personal-data {
            .pf-title {
                margin-bottom: 0;
            }

            .radio-choices {
                width: 90%;
                .pf-title {
                    @media(max-width:575.98px) {
                        margin-bottom: 15px;
                    }
                }
                ul{
                    margin-bottom: 0;

                    li:nth-child(1) {
                        margin-right: 20%;
                    }
                }
                @media(max-width:575.98px) {
                    margin-bottom: 10px;
                }
            }

            button {
                float: left;
            }

            .birth-date {
                input[name="birth_date"] {
                    background: #ffffff;
                    border-radius: 8px;
                    border: 2px solid #e8ecec;
                    padding: 14px 45px 14px 15px;
                    line-height: 24px;
                }
            }

            .postal-code {
                input[name="postal_code"] {
                    background: #ffffff;
                    border-radius: 8px;
                    border: 2px solid #e8ecec;
                }
            }
        }


        .qualification {
            // .field-errors {
            //     margin-left: 15px !important;
            // }
            button {
                float: left;
            }

            .pf-title {
                margin-bottom: 0;
            }

            .simple-checkbox p label {
                padding-left: 25px;
            }

            .simple-checkbox p label::before {
                content: " ";
                border: 2px solid #e6e7ef;
                border-radius: 3px;
            }

            .simple-checkbox p input[type="checkbox"]:checked + label::before {
                    background: #2c7dfa;
                    border-color: #2c7dfa;
            }

        }

        .c-vitae {
            &:last-child {
                margin-bottom: 40px;
            }
            label.file-label {
                height: fit-content;
                padding-left: 15px;
                width: fit-content;
                background-color: #636ab9;
                border: none;
            }
            label.file-label:hover {
                background-color: #444aa0;
            }
            .file-upload {
                span {
                    @media(max-width: 575.98px) {
                        display: block;
                    }
                }
            }
            button {
                float: left;
            }

            .filelink, [class*="filelink-"] {
                color: #5b79a8;
                &:hover {
                    color: #334562;
                }
            }

            #profile-picture-current-wrapper,
            #profile-picture-preview-wrapper {
                margin: 0
            }

            .clear-selection {
                > i {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-left: 1rem;
                    border: 1px solid #80808057;
                    padding: 0 5px 2px 5px;
                    border-radius: 10px;
                    color: #404040;
                    cursor: pointer;
                }
            }
        }

        .work-experience {
            // @media(max-width: 991px ) {
            //     .to-date label {
            //         margin-top: 30px !important;
            //     }
            // }

            // reorder/resize buttons on small screen
            @media(max-width: 560px ) {
                button.previous, button.toggle-form, button.update {
                    width: 47%;
                    float: left;
                }
                button.next, button.add-experience, button.delete-work-experience {
                    width: 47%;
                    float: right ;
                }
            }
            @media(max-width: 450px ) {
                button.toggle-form, button.add-experience {
                    width: 100%;
                }
            }

            input[name="from_date"], input[name="to_date"] {
                background: #ffffff;
                border-radius: 8px;
                border: 2px solid #e8ecec;
                padding: 14px 45px 14px 15px;
                line-height: 24px;
            }
            input {
                margin-bottom: 0px;
                @media(max-width: 575.98px) {
                    margin-bottom: 15px;
                }
            }

            button{
                float: left;
            }

            // Fixes white background hiding the border
            .chosen-single{
                background-color: unset;
            }

            .pf-title {
                margin-bottom: 0;
            }

        }

        .language, .mother-tongue {
            // reorder/resize buttons on small screen
            @media(max-width: 560px ) {
                button.previous, button.toggle-form, button.update {
                    width: 47%;
                    float: left;
                }
                button.next, button.add-language, button.delete-language {
                    width: 47%;
                    float: right !important;
                }
            }

            @media(max-width: 450px ) {
                button.toggle-form, button.add-language {
                    width: 100%;
                }
            }

            p.language-name {
                margin-bottom: 0;
                padding-left: 15px;
            }

            button {
                float: left;
            }

            // Fixes white background hiding the border
            div a.chosen-single, .chosen-with-drop {
                background-color: unset !important;
            }

            span.update-form-sublabel {
                position: unset;
                font-size: 12px;
                color: #888888;
                margin-bottom: 0px;
                text-align: left;
                width: 110%;
                font-weight: 600;
            }

        }
    }

    @media (min-width: 981px) and (max-width: 1250px ) {
        .container {
            max-width: 960px;
        }
    }

    @media (max-width: 991px) {
        .column {
            margin-top: 0;
        }
        // Profile menu
        aside.column{
            display: none;
            background: #d3d3d354;
            width: 100vw;
            padding-right: 30px;
            margin: 0 -15px;
            box-shadow: 0px 10px 10px 0px #80808057;
        }
        .profile-title.profile-title-mobile {
            display:block !important;
            background: #d3d3d354;
            margin: 0 -15px;
            width: 100vw;

            h3 {
                padding-left: 0;
            }
        }
        .profile-title{
            display:none;
        }
    }
}
