@import './mixins';

$color_1: #666666;
$color_2: #5b79a8;
$color_3: #334562;
$color_4: #8b91dd;
$color_5: #ffffff;
$font_family_1: 'Quicksand', serif;
$background_color_1: #D6EDFC;
$background_color_2: #78bff0;
$background_color_3: #8b91dd;

.text--styling {
	h1 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	h2 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	h3 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	h4 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	h5 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	h6 {
		margin-bottom: 1.5rem;
		line-height: 1.3;
	}
	p {
		margin-bottom: 1.5rem;
		font-size: 16px !important;
		color: $color_1;
	}
	ul {
		margin-bottom: 1.5rem;
		font-size: 16px !important;
		color: $color_1;
		width: 100%;
		margin-bottom: 1.5rem;
		margin-left: 14px;
		li {
			max-width: 90%;
			position: relative;
			line-height: 29px;
			font-size: 16px !important;
			margin-bottom: 0;
			color: $color_1;
			padding-left: 25px;
			&::before {
				position: absolute;
				left: 0;
				top: 12px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: #14A0E3;
				content: "";
			}
		}
	}
	ol {
		margin-bottom: 1.5rem;
		font-size: 16px !important;
		color: $color_1;
		width: 100%;
		margin-bottom: 1.5rem;
		margin-left: 29px;
		li {
			max-width: 90%;
			position: relative;
			line-height: 29px;
			font-size: 16px !important;
			margin-bottom: 0;
			color: $color_1;
		}
	}
	a {
		color: $color_2;
		-webkit-transition: all 0.5s ease 0s;
		-moz-transition: all 0.5s ease 0s;
		-ms-transition: all 0.5s ease 0s;
		-o-transition: all 0.5s ease 0s;
		transition: all 0.5s ease 0s;
		&:hover {
			color: $color_3;
			-webkit-transition: all 0.5s ease 0s;
			-moz-transition: all 0.5s ease 0s;
			-ms-transition: all 0.5s ease 0s;
			-o-transition: all 0.5s ease 0s;
			transition: all 0.5s ease 0s;
		}
	}
	.f-l-big {
		&::first-letter {
			font-size: 3.6em;
			color: $color_4;
			font-weight: 800;
			float: left;
			line-height: 1;
			margin-right: 10px;
		}
	}
	span.l-big {
		font-size: 3.6em;
		font-weight: 800;
		float: left;
		line-height: 1;
		margin-right: 10px;
	}
	.pill-marker {
		background-color: $background_color_1;
		width: fit-content;
		padding: .3em .5em;
		border-radius: 1.5em;
	}
	.pill-header {
		background-color: $background_color_1;
		width: fit-content;
		padding: .3em .5em;
		border-radius: 1.5em;
	}
	.blue-box {
		@include blue-box;
	}
	.violet-box {
		font-family: $font_family_1 !important;
		color: $color_5;
		font-size: 28px !important;
		font-weight: 500;
		line-height: 1.3;
		padding: 1em 1.5em;
		border-radius: .3em;
		background-color: $background_color_3;
	}
}
