.category-list {
    @mixin touch-or-hover {
        background: #fff;
        box-shadow: 0px 0px 25px rgba(0,0,0,0.1);
        border-radius: 8px;
        width: 104%;
        margin-left: -2%;
        height: 102%;
        z-index: 10;
        a {
            border-color: #fff;
        }
        i {
            color: #FA5B05;
        }
    }

    @media(max-width: 980px ) {
        > h2 {
            margin-top: 60px;
        }
    }
    .p-category {
        @media(max-width:991.98px) {
            &.touched {
                @include touch-or-hover;
            }
        }
        @media(min-width:992px) {
            &:hover {
                @include touch-or-hover;
            }
        }
    }
}
