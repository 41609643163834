$color_1: #8b91dd;
$color_2: #FA5B05;
$color_3: grey;
$color_4: white;
$color_5: #141f72;
$color_6: #474747;
$color_7: lightseagreen;
$color_8: brown;
$color_9: #5a5a5a;
$color_10: #202020;
$color_11: #ffffff;
$color_12: #666666;
$color_13: #626262;
$color_14: inherit;
$color_15: #fff;
$color_16: #5b79a8;
$color_17: #334562;
$font_family_1: Open Sans;
$font_family_2: 'Quicksand', serif;
$background_color_1: #141f7282;
$background_color_2: #fff;
$background_color_3: #141f72;
$background_color_4: #7dc246;
$border_color_1: #FA5B05;

select {
	-webkit-appearance: none;
}
input {
	-webkit-appearance: none;
}
textarea {
	-webkit-appearance: none;
}
.dropdown-field {
	i {
		position: relative;
		right: 20px;
		bottom: 40px;
		font-size: 20px;
		color: $color_1;
		float: right;
	}
}
.wiz-color {
	color: $color_2 !important;
}
.mt-5px {
	margin-top: 5px;
}
.no-hover {
	pointer-events: none;
}
.transition-effect {
	transition: all 0.4s ease 0s;
}
.border-shadow {
	-webkit-box-shadow: 0px 1px 20px #97909938;
	-moz-box-shadow: 0px 1px 20px #97909938;
	box-shadow: 0px 1px 20px #97909938;
}

.inner-header {
	padding: 170px 0 150px 0;
	@media(max-width: 575.98px) {
		padding: 70px 0;
	}
	&.no-image {
		padding: 100px 0 0 0;
		padding-bottom: 0;
		@media(max-width: 1250px ) {
			padding-top: 0;
		}
	}
	&.wform {
		@media(max-width: 575.98px) {
			padding: 40px 0 0 0;
		}
	}
	h3 {
		@media(max-width: 575.98px) {
			font-size: 30px;
		}
		font-size: 40px;
		margin-bottom: 0;
	}
}

nav {
	.account-dropdown {
		li {
			a {
				overflow-wrap: break-word;
			}
		}
	}
}
.create-account-button {
	float: left;
	width: 100%;
	letter-spacing: 0.3px;
	margin-top: 20px;
	background-color: $background_color_1;
	border-radius: 8px;
	transition: all 0.25s ease 0s;
	&:hover {
		background: #fa5b0578;
	}
}
.signin-popup-alternate {
	cursor: pointer;
}
.account-msg {
	font-size: 10px;
	margin-top: 10px;
}
div.account-popup-area.wizard-popup-box {
	>div {
		width: 600px;
		margin-left: -300px;
	}
	.ftchek {
		label {
			&::before {
				margin-top: -1px;
			}
			font-size: 12px;
		}
	}
	input[type="number"] {
		border: medium none;
	}
}
.latest-ad-image {
	margin-bottom: 1.5rem;
	padding-top: 5px;
}
.latest-closed-image {
	margin-bottom: 50%;
	padding-top: 5px;
}
.wizardFieldWrapper {
	text-align: left;
	display: flow-root;
	>.wizard-form-label {
		font-size: 12px;
		padding-left: 0px;
		color: $color_3;
		margin-bottom: 0px;
	}
}
.account-popup {
	a.term-check-link {
		width: 100%;
		text-align: left;
		text-decoration: none;
		font-size: 12px;
		font-weight: bold;
		color: $color_5;
		&:hover {
			color: $color_2;
		}
	}
}
.account-popup-area.wizard-popup-box {
	>div {
		>form {
			>div.row {
				>div.col-4.pr-0 {
					>div {
						>div.cfield.mr-3 {
							margin-bottom: 10px;
						}
					}
				}
				>div.col-8 {
					>div {
						>div.cfield {
							margin-bottom: 10px;
						}
					}
				}
			}
			>div {
				&:nth-child(7) {
					>div.col-8 {
						>button {
							>a {
								&:hover {
									text-decoration-line: unset;
								}
							}
						}
					}
				}
			}
		}
	}
	form {
		button {
			padding: 0px;
			a {
				display: inline-block;
				width: 100%;
				height: 100%;
				padding-top: 1rem;
				color: $color_4;
				text-decoration: none;
			}
		}
	}
}
.wizard-form-label {
	&::before {
		content: none;
	}
}
button.wizard-back-button {
	background: white !important;
	border: 3px solid #141f72;
	border-radius: 8px;
	a {
		color: $color_5 !important;
		transition: all 0.4s ease 0s;
		&:hover {
			color: $color_2 !important;
		}
	}
	&:hover {
		border-color: $border_color_1;
		border-radius: 8px;
	}
}
.chosen-container-active.chosen-with-drop {
	.chosen-single {
		border: unset;
		border-bottom-left-radius: unset;
		border-bottom-right-radius: unset;
	}
}
.chosen-container-single {
	.chosen-search {
		input[type="text"] {
			border: unset;
		}
	}
}
.who-am {
	>p {
		overflow-wrap: break-word;
	}
}
section {
	&:nth-child(6) {
		>div {
			>div {
				>div {
					>div {
						&:nth-child(1) {
							>div.heading {
								>p {
									padding-top: 10px;
									margin-top: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}
#cms-top {
	>div.cms-toolbar {
		>div.cms-toolbar-left {
			>form {
				>label {
					&::before {
						content: none;
					}
					&:nth-child(2) {
						>input[type=text] {
							float: none;
						}
					}
					&:nth-child(3) {
						>input[type=password] {
							float: none;
						}
					}
				}
			}
		}
	}
	>div.cms-modal.cms-modal-markup.cms-modal-open {
		>div.cms-modal-body {
			>div.cms-modal-frame {
				>div {
					>div.cms-quicksearch {
						>label {
							&::before {
								content: none;
							}
						}
					}
				}
			}
		}
	}
}
#content-placeholder {
	>p {
		overflow-wrap: break-word;
	}
}
h2 {
	overflow-wrap: break-word;
}
#id_mother_tongue_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
}
#id_language_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
}
#id_understanding_listening_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	>div {
		>ul {
			>li.active-result.highlighted {
				padding-left: 12px;
			}
		}
	}
}
#id_understanding_reading_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	>div {
		>ul {
			>li.active-result.highlighted {
				padding-left: 12px;
			}
		}
	}
}
#id_speaking_interaction_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	>div {
		>ul {
			>li.active-result.highlighted {
				padding-left: 12px;
			}
		}
	}
}
#id_speaking_production_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	>div {
		>ul {
			>li.active-result.highlighted {
				padding-left: 12px;
			}
		}
	}
}
#id_writing_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	>div {
		>ul {
			>li.active-result.highlighted {
				padding-left: 12px;
			}
		}
	}
}
#id_qualification_chosen {
	>a {
		padding: 8px 45px 8px 15px;
	}
	span {
		line-height: 36px;
	}
}
.multicheckbox {
	p {
		width: fit-content;
		font-size: 12px;
		margin-bottom: 0;
		width: 100%;
		label {
			padding-left: 25px;
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
.sectors-column {
	padding-right: 0;
}
.countries_to_work-column {
	padding-right: 0;
}
.checkbox-disabled {
	opacity: 50%;
}
#id_description {
	background-color: $background_color_2;
	font-size: 13px;
	color: $color_6;
	padding-left: 15px;
}

@mixin profile-picture-wrapper {
	height: 150px;
	margin: 0 auto;
	img {
		width: 150px;
		height: 100%;
		object-fit: cover;
	}
}
#profile-picture-current-wrapper {
	@include profile-picture-wrapper;
}
#profile-picture-preview-wrapper {
	@include profile-picture-wrapper;
}

.c-vitae-form.file-label {
	width: 100%;
	margin-right: 0;
}
span.success-message {
	color: $color_7;
	font-size: 12px;
}
span.warning-message {
	color: $color_8;
	font-size: 12px;
}
input[type="file"] {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.file-label {
	&::before {
		content: none;
	}
}
form.email_list {
	button[name="action_primary"] {
		margin-right: 15px;
	}
	>fieldset {
		width: 100%;
		margin-bottom: 20px;
	}
	button {
		float: left;
		background: #ffffff;
		border: 2px solid #FA5B05;
		color: $color_10;
		font-family: $font_family_1;
		font-size: 15px;
		padding: 11px 40px;
		border-radius: 8px;
		margin-top: 10px;
		transition: all 0.4s ease 0s;
		&:hover {
			background: #FA5B05;
			color: $color_11;
		}
	}
	button[name="action_remove"] {
		border: 2px solid crimson;
		float: right !important;
		&:hover {
			background: crimson;
			color: $color_11;
		}
	}
}
.email-management {
	padding: 30px 0 0 30px;
}
form.add_email {
	input {
		border-radius: 8px;
	}
	button {
		margin-bottom: 20px;
		float: left;
		background: #ffffff;
		border: 2px solid #FA5B05;
		color: $color_10;
		font-family: $font_family_1;
		font-size: 15px;
		padding: 11px 40px;
		border-radius: 8px;
		margin-top: 10px;
		transition: all 0.4s ease 0s;
		&:hover {
			background: #FA5B05;
			color: $color_11;
		}
	}
	>label {
		&::before {
			content: none;
		}
		padding: 0;
		font-size: 14px;
		color: gray;
	}
}
p.subtitle {
	margin-bottom: 20px;
}
button.confirm-remove {
	float: left;
	width: 100%;
	height: 60px;
	font-size: 17px;
	font-weight: bold;
	letter-spacing: 0.3px;
	margin-top: 20px;
	border-radius: 8px;
	background-color: $background_color_3;
	transition: all 0.4s ease 0s;
	&:hover {
		background: #FA5B05;
	}
}
.radio-label {
	margin: 2px 0px 0px 0px;
}
.connections-management {
	padding: 30px 0 0 30px;
}
form.connections {
	button[name="action_remove"] {
		border: 2px solid crimson;
		float: left;
		background: #ffffff;
		border: 2px solid crimson;
		color: $color_10;
		font-family: $font_family_1;
		font-size: 15px;
		padding: 11px 40px;
		border-radius: 8px;
		margin-top: 10px;
		transition: all 0.4s ease 0s;
		&:hover {
			background: crimson;
			color: $color_11;
		}
	}
	>fieldset {
		width: 100%;
		margin-bottom: 20px;
	}
}
a.socialaccount_provider {
	color: $color_12;
	transition: all 0.4s ease 0s;
	&:hover {
		color: $color_2;
	}
}
.pasword-reset-popup-box {
	p {
		font-size: small;
		line-height: 20px;
	}
}
.pasword-reset-done-popup-box {
	p {
		font-size: small;
		line-height: 20px;
	}
}
form.password_reset {
	label {
		&::before {
			content: none;
		}
		float: left;
		padding-left: 0;
	}
	input {
		border-radius: 8px;
	}
}
form.password_reset_from_key {
	label {
		&::before {
			content: none;
		}
		float: left;
		padding-left: 0;
	}
	input {
		border-radius: 8px;
	}
}
.password-management {
	padding: 30px 0 0 30px;
	form {
		input:last-child {
			margin-bottom: 20px;
		}
		button {
			margin-top: 30px;
		}
	}
}
form.password_change {
	label {
		&::before {
			content: none;
		}
		float: left;
		padding-left: 0;
		font-size: 14px;
		color: gray;
	}
	button {
		float: left;
		background: #ffffff;
		border: 2px solid #FA5B05;
		color: $color_10;
		font-family: $font_family_1;
		font-size: 15px;
		padding: 11px 40px;
		border-radius: 8px;
		margin-top: 10px;
		transition: all 0.4s ease 0s;
		&:hover {
			background: #FA5B05;
			color: $color_11;
		}
	}
	input {
		border-radius: 8px;
	}
}
form.password_set {
	label {
		&::before {
			content: none;
		}
		float: left;
		padding-left: 0;
	}
	button {
		float: left;
		background: #ffffff;
		border: 2px solid #FA5B05;
		color: $color_10;
		font-family: $font_family_1;
		font-size: 15px;
		padding: 11px 40px;
		border-radius: 8px;
		margin-top: 10px;
		transition: all 0.4s ease 0s;
		&:hover {
			background: #FA5B05;
			color: $color_11;
		}
	}
	input {
		border-radius: 8px;
	}
}
.messages-popup-box {
	z-index: 100;
	.account-popup {
		top: 100px;
	}
}
div.p-category {
	>a {
		min-height: 235px;
	}
}
.simple-text.faq {
	p {
		color: $color_4;
	}
	padding: 28px;
}
.open-register-popup {
	color: $color_13;
	font-weight: bold;
	&:hover {
		color: $color_2;
		cursor: pointer;
	}
}
.goto-register-popup {
	color: $color_13;
	font-weight: bold;
	&:hover {
		color: $color_2;
		cursor: pointer;
	}
}
.term-check-field {
	margin: 0 0 15px 0;
	font-family: $font_family_1;
	font-size: 13px;
	color: $color_10;
	width: 100%;
	text-align: left;
	input {
		-webkit-appearance: auto;
	}
}
section.old-user-password-reset {
	span {
		text-align: left;
	}
}
p.gdpr-label {
	text-align: left;
	margin: 40px 0 10px;
}
.la.la-mail-forward {
	font-weight: bold;
}
.consent-description {
	text-align: left;
	font-size: 12px;
	line-height: 1rem;
	margin-bottom: 10px;
	padding-top: 10px;
}
label.subscribe-unsubscribe {
	font-size: 16px;
	&::before {
		top: 2px;
	}
}
.menu-sec {
	nav {
		>ul {
			>li {
				>a {
					padding: 14px 14px;
				}
			}
		}
	}
}
.grecaptcha-badge {
	z-index: 10;
}
#user_attachments {
	>p {
		text-align: left;
		font-size: 14px;
		margin-bottom: 0;
		margin-top: 15px;
	}
	#add_form {
		margin-top: 5px;
		width: fit-content;
		height: fit-content;
		padding: 0 20px 3px 20px;
		background-color: $background_color_4;
		font-size: 24px;
		display: block;
		line-height: 1;
		border-radius: 8px;
		font-weight: bold;
		color: $color_15;
		border: none;
	}
	>#add_form {
		&::before {
			content: none;
		}
	}
	label {
		height: fit-content;
		font-size: 14px;
	}
	table {
		td {
			padding: 0 0 0 0;
			&:nth-child(2) {
				width: 100%;
				text-align: left;
				padding-right: 10px;
				word-break: break-word;
			}
		}
	}
	#remove_form {
		a {
			font-size: 20px;
			height: 18px;
		}
		display: none;
	}
	#remove_form.show-icon {
		display: block;
	}
	.filelink {
		color: $color_16;
		&:hover {
			color: $color_17;
		}
	}
}
.user-data-management-form {
	#user_attachments {
		h3 {
			margin-bottom: 23px;
		}
		label.delete_check {
			&::before {
				content: " ";
				border: 2px solid #e6e7ef;
				border-radius: 3px;
				left: -30px;
			}
			&::after {
				left: -30px;
			}
		}
	}
}
@media (max-width: 767px ) {
	.latest-ad-image {
		margin-bottom: 10px;
	}
	.latest-closed-image {
		margin-bottom: 10px;
	}
}
@media (max-width:630px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 530px;
			margin-left: -265px;
		}
	}
}
@media (max-width:535px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 460px;
			margin-left: -230px;
		}
	}
}
@media (max-width:495px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 420px;
			margin-left: -210px;
		}
	}
}
@media (max-width:395px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 380px;
			margin-left: -190px;
		}
	}
}
@media (max-width:360px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 360px;
			margin-left: -180px;
		}
	}
}
@media (max-width:330px) {
	div.account-popup-area.wizard-popup-box {
		>div {
			width: 330px;
			margin-left: -165px;
		}
	}
}
@media (max-width: 991px ) {
	.email-management {
		padding-left: 0 !important;
	}
}
@media (max-width: 730px ) {
	button.primary {
		width: 48%;
		margin-right: 4% !important;
	}
	button.send {
		width: 48%;
	}
}
@media (max-width: 635px ) {
	form.email_list {
		button {
			margin-top: 10px;
			width: 100%;
		}
		.form-group {
			width: 100%;
		}
	}
	form.add_email {
		button {
			margin-top: 10px;
			width: 100%;
		}
	}
	p.email-wrap {
		margin-bottom: 10px !important;
	}
	span.email-address {
		display: block;
		margin-top: -3px;
	}
	span.verified {
		margin-left: 0 !important;
		margin-bottom: 10px !important;
	}
	span.unverified {
		margin-left: 0 !important;
		margin-bottom: 10px !important;
	}
}
@media (max-width: 991px) {
	.connections-management {
		padding: 30px 0 0 0;
	}
	.password-management {
		padding: 30px 0 0 0;
	}
}
@media (min-width: 768px) and (max-width: 981px) {
	.job-search {
		form {
			button {
				height: 61px;
			}
		}
	}
}
@media (max-width: 490px) {
	select {
		text-indent: 25px;
		padding-left: 0;
		height: 56px;
	}
	select.location-search-mobile {
		text-indent: 25px !important;
	}
}
@media (max-width: 766px) {
	section.testimonials {
		.heading {
			margin-bottom: 0;
		}
	}
}

.errorlist {
	margin-bottom: 0px;
	>li {
		position: unset;
		font-size: 10px;
		color: $color_8;
		margin-bottom: 0px;
		text-align: left;
	}
}
.popup-title {
	text-align: center;
	line-height: normal !important;
}
