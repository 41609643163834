.messages-popup-box {
    .account-popup {
        position: absolute;
        float: none;
        @media(max-width:499.98px) {
            top: 50%;
        }
        @media(min-width:500px) {
            top: 33%;
        }
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        .mgs-wrapper {
            $button-color: #141f72;
            $button-height: 60px;
            h4 {
                &.new-apply {
                    color: #000;
                }
            }
            p {
                line-height: 1.6;
                margin-top: 30px;
                a {
                    color: $button-color;
                    font-weight: 600;
                }
            }
            .msg-links {
                > .btn-popup {
                    float: none;
                    padding: 0;
                    width: 100%;
                    height: $button-height;
                    font-size: 17px;
                    font-weight: 700;
                    letter-spacing: .3px;
                    margin-top: 20px;
                    transition: all .4s ease 0s;
                    border-radius: 8px;
                    &:not(.inverted) {
                        background: $button-color;
                        color: #fff;
                    }
                    &.inverted {
                        background: #fff;
                        color: $button-color;
                        border: 2px solid $button-color;
                    }
                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        line-height: $button-height;
                    }
                }
            }
        }
    }
}

