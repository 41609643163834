@import './mixins';

$color_1: brown;
$color_3: grey;
$color_6: #474747;
$color_9: #5a5a5a;
$color_10: #202020;
$link_color: #334562;
$font_family_1: Open Sans;

#user_data_wizard {
	min-height: 50vh;
	padding: 60px 0;
	@media(max-width: 400px) {
		min-height: 80vh;
	}
	.account-popup-area {
		&.static {
			.account-popup {
				float: none;
				left: unset;
				margin: 0 auto;
				text-align: left;
				width: 100%;
				h1 {
					margin-bottom: 30px;
				}
                form {
					> .fields-wrap {
						margin-left: 0;
						margin-right: 0;
					}
					.cfield {
						margin-bottom: 0;
					}
                    .wizard-field-wrapper {
						width: 100%;
						.field-errors {
							@media(min-width:768px) {
								min-height: 15px;
							}
						}
						&.half {
							@media(min-width: 768px) {
								width: 50%;
								&:nth-child(odd) {padding-right: 10px;}
								&:nth-child(even) {padding-left: 10px;}
							}
						}
						&:not(:last-child) {
							margin-bottom: 20px;
						}
                        text-align: left;

                        .label {
                            font-size: 14px;
                            padding-left: 0;
                            color: $color_3;
                            margin-bottom: 0px;
                            &::before {
                                content: none;
                            }
                        }
						.consent-description {
							font-size: 16px;
							a {
								font-size: 16px;
							}
						}
                        .term-check-field {
                            font-family: $font_family_1;
                            font-size: 16px;
                            color: $color_10;
                            width: 100%;
                            text-align: left;
							label {
								&::after {
									color: #fff;
									background: #636ab9;
									border-radius: 3px;
									width: 24px!important;
    								height: 24px!important;
								}
								&::before {
									width: 24px!important;
    								height: 24px!important;
								}
							}
							input {
								-webkit-appearance: auto;
							}
                        }
                        .choices {
                            margin: auto;
                            padding-top: 12px;
							> li {
								margin-bottom: 0;
							}
							input {
								-webkit-appearance: auto;
							}
							.choice-radio-button {
								font-size: 20px;
								color: $color_9;
								&:before {
									width: 30px !important;
									height: 30px !important;
								}
								&:after {
									left: 4px;
									border-width: 11px;
									top: 4px;
								}
							}
                        }
						input {
							height: 50px;
						}
                        textarea {
                            background-color: #fff;
                            font-size: 13px;
                            color: $color_6;
							padding: 16px 45px 16px 15px;
                            border-radius: 8px;
							min-height: unset;
							height: 200px;
							@media(min-width: 500px ) {
								height: 150px;
							}
							@media(min-width: 768px ) {
								height: 100px
							}
							&::placeholder {
								color: #80808082;
							}
                        }
                    }
                }
				&.wizard {
					&__personal_data {
						.data-importance-info {
							line-height: 1.8;
							text-align: left;
							padding-top: 30px;
						}
						hr {
							margin: 30px 0 30px 0;
						}
						.optional-fields {
							> p {
								margin-bottom: 30px;
							}
							.c-vitae {
								h3 {
									text-align: left;
								}
								.file-upload {
									span {
										@media(max-width: 575.98px) {
											display: block;
										}
									}
								}
								.file-label {
									height: fit-content;
									padding-left: 15px;
									width: fit-content;
									background-color: #636ab9;
									border: none;
								}
								.file-label:hover {
									background-color: #444aa0;
								}

								button {
									float: left;
								}

								.filelink, [class*="filelink-"] {
									color: #5b79a8;
									&:hover {
										color: #334562;
									}
								}

								#profile-picture-current-wrapper,
								#profile-picture-preview-wrapper {
									margin: 0
								}

								.clear-selection {
									> i {
										font-size: 1.5rem;
										font-weight: bold;
										margin-left: 1rem;
										border: 1px solid #80808057;
										padding: 0 5px 2px 5px;
										border-radius: 10px;
										color: #404040;
										cursor: pointer;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
