@media (min-width: 981px) and (max-width: 1250px ) {
    div.container {
        max-width: 960px;
    }
}

@media(max-width:991px) {
    .sidebar {
        background: rgb(250,250,250);
        margin-bottom: -40px;
        padding-bottom: 40px;
        padding-top: 40px;
        margin-top: 30px;
    }

    div.block {
        padding-bottom: 40px;
    }
}
