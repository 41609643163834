$color_1: #141f72;
$color_2: #888888;
$font_family_1: 'Quicksand', serif;
$font_family_2: Open Sans;
$background_color_1: rgba(255, 255, 255, 0.9);

.reviews {
	height: 307px;
	cursor: pointer;
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-ms-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
	&:hover {
		background-color: $background_color_1;
	}
	.tmonial-text {
		height: 96px;
		overflow-y: hidden;
	}
	.tmonial-read-more {
		color: $color_1;
		font-family: $font_family_1 !important;
		font-size: 15px;
		font-weight: bold;
	}
}
.testimonial-popup {
	.reviews {
		height: fit-content;
		margin-top: 0;
		cursor: unset;
		.tmonial-text {
			height: fit-content;
		}
	}
}
h3.tmonial-name {
	margin-bottom: 5px;
}
p.tmonial-job {
	font-family: $font_family_2;
	font-size: 15px;
	color: $color_2;
	font-weight: bold;
}
.account-popup.testimonial-popup {
	padding: 10px;
}
