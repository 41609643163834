$color_1: #888888;
$color_2: #101010;
$color_3: #fff;
$font_family_1: Open Sans;
$background_color_1: #3b5998;
$background_color_2: #0e76a8;
$background_color_3: #00acee;
$background_color_4: #c4302b;
$background_color_5: #027ba5;
$border_color_1: #3b5998;
$border_color_2: #0e76a8;
$border_color_3: #00acee;
$border_color_4: #c4302b;
$border_color_5: #027ba5;

.contact-textinfo {
	>ul {
		>li {
			>span {
				p {
					display: table-cell;
					vertical-align: middle;
					font-family: $font_family_1;
					font-size: 13px;
					color: $color_1;
					line-height: 24px;
				}
			}
		}
	}
}
.inner-title2 {
	p {
		display: table-cell;
		vertical-align: middle;
		font-family: $font_family_1;
		font-size: 13px;
		color: $color_1;
		line-height: 24px;
	}
	padding: 40px 0;
}
.contact-form {
	.errorlist {
		li {
			width: 100%;
		}
	}
}
.pf-title {
	margin-bottom: 5px;
}
.pf-field {
	textarea {
		color: $color_2;
		padding: 14px 45px 14px 15px;
	}
}
.sc_facebook {
	background-color: $background_color_1 !important;
	border-color: $border_color_1 !important;
}
.sc_linkedin {
	background-color: $background_color_2 !important;
	border-color: $border_color_2 !important;
}
.sc_twitter {
	background-color: $background_color_3 !important;
	border-color: $border_color_3 !important;
}
.sc_youtube {
	background-color: $background_color_4 !important;
	border-color: $border_color_4 !important;
}
.sc_instagram {
	background-color: $background_color_5 !important;
	border-color: $border_color_5 !important;
}
#social_icons {
	list-style: none;
	font-size: 0;
	li {
		width: fit-content;
		display: inline-block;
		padding: 5px 5px 5px 0;
		margin-right: 10px;
		a {
			font-size: 16px;
			height: 35px;
			width: 35px;
			line-height: 36px;
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			border-radius: 5px;
			-webkit-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
		}
		&:last-child {
			a {
				margin-right: 0px;
			}
		}
	}
	[class*="sc_"] {
		color: $color_3 !important;
		&:hover {
			opacity: 0.8;
		}
	}
}
@media (max-width: 520px) {
	.inner-title2 {
		p {
			display: block;
			margin-bottom: 0;
			text-align: center;
		}
	}
}
